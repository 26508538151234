import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import {
  FieldProcessor,
  Sensor,
  SensorValueEntry,
} from 'src/app/models/api/sensor';
import { SensorService } from 'src/app/services/api/sensor.service';
import { AxUtils } from 'src/app/shared/utils/axUtils';
import { MatSelectionList } from '@angular/material/list';
import { SensorValueService } from 'src/app/services/api/sensor-value.service';
import { saveAs } from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { UploadFileDialogComponent } from 'src/app/shared/components/upload-file-dialog/upload-file-dialog.component';
import { NewDeviceComponent } from './new-device/new-device.component';
import { MatTabGroup } from '@angular/material/tabs';
import { Subscription } from 'rxjs';
import { SettingsService } from 'src/app/services/themes/settings.service';
import { LocalStorageService } from 'src/app/services/core/authentication/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceStateEnum } from 'src/app/models/api/enum-types/device-state-enum';
import { LastUpdateService } from 'src/app/services/api/last-update.service';
import { SensorValue } from 'src/app/models/api/sensor-value';
import moment from 'moment';
@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent implements OnInit {
  public sensors: Sensor[];
  public sensorClicked;
  public sensorObj: Sensor;
  private subscribe: Subscription;
  public lastSensorData = [];
  public jsonData: string;
  public sensorId: number;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  value: string;
  @ViewChild('sensorSelect') sensorSelect: MatSelectionList;
  filteredSensors = [];
  selectedSensors = [];
  sensorstoFilter: Sensor[] = [];
  tabs = [
    {
      label: 'Details',
      content: ``,
    },
    {
      label: 'Edit',
      content: ``,
    },
    {
      label: 'Script',
      content: ``,
    },
    {
      label: 'Last Message',
      content: ``,
    },
    {
      label: 'Last Message (Json)',
      content: ``,
    },
  ];
  public columnsToDisplay = ['field', 'fValue'];
  public fieldProcessors: FieldProcessor[] = [
    {
      fieldKey: 'categories',
      processor: this.processCategories,
    },
    {
      fieldKey: 'deviceState',
      processor: this.processDeviceState,
    },
    {
      fieldKey: 'location',
      processor: this.processLocation,
    },
    {
      fieldKey: 'sensorProperties',
      processor: this.processSensorProperties,
    },
    {
      fieldKey: 'sensorValueEntries',
      processor: this.processSensorValueEntries,
    },
  ];

  constructor(
    private sensorService: SensorService,
    public dialog: MatDialog,
    private sensorValueService: SensorValueService,
    private setting: SettingsService,
    private store: LocalStorageService,
    private route: ActivatedRoute,
    private lastUpdateService: LastUpdateService,
    private router: Router
  ) {
    this.setting.notify.subscribe((newValue) => {
      this.getSensors();
    });
  }

  ngOnInit(): void {
    if (this.store.get('searchValue')) {
      this.value = this.store.get('searchValue');
      this.store.set('searchValue', '');
    }
    this.getSensors();
    this.getAllSensorsData();
    // this.value = this.route.snapshot.paramMap.get('value');
  }

  ngOnDestroy(): void {
    if (this.subscribe) {
      this.subscribe.unsubscribe();
    }
  }

  findSensorById(sensorId: Number): any {
    const storedSensorsJSON = localStorage.getItem('sensors');
    if (storedSensorsJSON) {
      const sensorsArray: any[] = JSON.parse(storedSensorsJSON);
      const foundSensor = sensorsArray.find((sensor) => sensor.id === sensorId);
      return foundSensor;
    } else {
      return null;
    }
  }

  newDashboard() {
    this.router.navigate(['/devices/manage-dashboard']);
  }

  onKey(value: string) {
    if (value == 'unassigned') {
      this.selectedSensors = this.sensors.filter(
        (s) => s.tenantId === null || s.tenantId === ''
      );
    } else {
      this.selectedSensors = this.sensors.filter(
        (str) =>
          str.name.toLowerCase().includes(value.toLowerCase()) ||
          str.deviceState['state']
            .toLowerCase()
            .startsWith(value.toLowerCase()) ||
          str.id.toString().includes(value.toLowerCase()) ||
          str.clazz.toLowerCase().includes(value.toLowerCase()) ||
          str.tenantId.toLowerCase().includes(value.toLowerCase())
      );
    }
  }

  getSensorsNotNull(sensors: Sensor[]) {
    this.sensorstoFilter = [];
    for (let sensor of sensors) {
      if (sensor.tenantId == null || sensor.tenantId == undefined) {
        sensor.tenantId = '';
      }
      if (
        sensor.deviceState['state'] == null ||
        sensor.deviceState['state'] == undefined
      ) {
        sensor.deviceState['state'] = DeviceStateEnum.unregistered;
      }
      this.sensorstoFilter.push(sensor);
    }
  }

  getSensors() {
    this.subscribe = this.sensorService.getSensors().subscribe(
      (response) => {
        this.sensors = response['sensors'];
        this.selectedSensors = this.sensors;
        this.getSensorsNotNull(this.sensors);
        if (this.store.get('tenant') && this.store.get('tenant') != 'all') {
          this.sensors = this.sensorstoFilter.filter((sensor) =>
            sensor.tenantId
              .toLowerCase()
              .startsWith(this.store.get('tenant').toLowerCase())
          );
        }

        // Further filter out sensors with 'DELETED' status
        this.sensors = this.sensors.filter((sensor) => {
          const categories = sensor.categories;
          return !('Status' in categories && categories.Status === 'DELETED');
        });
        this.sensorObj = null;
        this.sensorId = null;
        this.sensorClicked = null;
        this.lastSensorData = null;
        this.jsonData = null;
        this.selectedSensors = this.sensors;
        // this.getSensorsNotNull(this.sensors);
        if (this.value != undefined) {
          this.onKey(this.value);
        }
      },
      (err: HttpErrorResponse) => AxUtils.handleServiceError(err)
    );
  }

  clickSelectSensor(sensor: Sensor) {
    delete sensor['lastValue'];
    this.sensorObj = sensor;
    this.sensorId = sensor.id;
    this.sensorClicked = this.buildSensorDataTable(sensor);
    if (this.tabGroup.selectedIndex == 3 || this.tabGroup.selectedIndex == 4) {
      this.getLastValue(this.sensorId);
    }
  }

  onSelectChange(event) {
    if (event.index == 3 || event.index == 4) {
      if (this.sensorId) {
        this.getLastValue(this.sensorId);
      }
    }
  }

  getLastValue(id: number) {
    let sensorDetails = this.findSensorById(id);
    if (sensorDetails && sensorDetails['lastValue']) {
      this.lastSensorData = this.buildSensorDataTable(
        sensorDetails['lastValue']
      );
      this.jsonData = JSON.stringify(sensorDetails['lastValue'], null, 2);
    } else {
      this.lastSensorData = [];
      this.jsonData = '';
    }
  } // end of getLastValue

  processSensorProperties(key: string, value: any): string {
    let retVal = '';
    const properties = value as SensorValueEntry[];
    properties.forEach((prop) => {
      retVal += `${prop.propName}=${prop.propValue}\n`;
    });
    return retVal;
  }

  processSensorValueEntries(key: string, value: any): string {
    let retVal = '';
    const properties = value as SensorValueEntry[];
    properties.forEach((prop) => {
      retVal += `${prop.propName}=${prop.propValue}\n`;
    });
    return retVal;
  }

  processCategories(key: string, value: any): string {
    let retVal = '';
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        retVal += `${key}=${value[key]}\n`;
      }
    }
    return retVal;
  }

  processDeviceState(key: string, value: any): string {
    let retVal = '';
    for (let key in value) {
      if (value.hasOwnProperty(key)) {
        retVal += `${key}=${value[key]}\n`;
      }
    }
    return retVal;
  }

  processLocation(key: string, value: any): string {
    if (value) {
      value = JSON.parse(value);
    }
    let retVal = '';
    for (let key in value) {
      if (value.hasOwnProperty(key)) {
        retVal += `${key}=${value[key]}\n`;
      }
    }
    return retVal;
  }

  processField(key: string, value: any): string {
    const processor = this.fieldProcessors.find((s) => s.fieldKey == key);
    if (processor) {
      return processor.processor(key, value);
    } else {
      return value;
    }
  } // end of processField

  private buildSensorDataTable(response: any): any[] {
    const data = [];
    for (var key in response) {
      if (response.hasOwnProperty(key)) {
        const dataForDispolay = this.processField(key, response[key]);
        const rec = { field: key, fValue: dataForDispolay };
        data.push(rec);
      }
    }
    return data;
  }

  bulkImport() {
    const dialogUpload = this.dialog.open(UploadFileDialogComponent, {
      autoFocus: true,
      width: '700px',
    });
    dialogUpload.afterClosed().subscribe((data) => this.getSensors());
  }

  newDevice() {
    const dialogRef = this.dialog.open(NewDeviceComponent, {
      autoFocus: true,
      width: '100%',
    });

    dialogRef.afterClosed().subscribe((data) => this.getSensors());
  }

  exportDevices() {
    const data = this.sensors;
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(blob, 'talegur-device-export.csv');
  }

  getAllSensorsData() {
    if (this.sensors && this.sensors.length > 0) {
      this.lastUpdateService.getLastUpdate().subscribe(
        (result) => {
          this.processLastUpdate(result, this.sensors);
        },
        (err: HttpErrorResponse) => console.log('Error:', err)
      );
    }
  }

  private processLastUpdate(
    lastUpdateValues: SensorValue[],
    sensorList: Sensor[]
  ) {
    sensorList.forEach((sensor, idx) => {
      const lastUpdate = lastUpdateValues.filter((updVal) => {
        return updVal.sensorId === sensor.id;
      });
      if (lastUpdate.length === 1) {
        sensor['lastValue'] = lastUpdate[0]['lastSensorValue'];
        const lag = moment().diff(
          moment.utc(lastUpdate[0].savedAt).local(),
          'minutes'
        );
      }
    });
    this.sensorService.persist(sensorList);
  }
}
