import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { element } from 'screenfull';
import { AssetItem } from 'src/app/models/api/iox-admin/asset-item';
import { Sensor, SensorValueEntry } from 'src/app/models/api/sensor';
import { IoxAdminService } from 'src/app/services/api/iox-admin.service';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { IoxTranslatePipe } from 'src/app/shared/pipes/iox-translate.pipe';
import { AxUtils } from 'src/app/shared/utils/axUtils';
@Component({
  selector: 'app-machinery-details',
  templateUrl: './machinery-details.component.html',
  styleUrls: ['./machinery-details.component.scss'],
})
export class MachineryDetailsComponent implements OnInit {
  id: Number;
  item: AssetItem;
  zoom: number = 12;
  lat: number;
  lng: number;
  public scrollwheel = false;
  machineryId: number;
  displayedColumns: string[] = ['from_time', 'to_time', 'status'];
  dataSource: any;
  constructor(
    private route: ActivatedRoute,
    private service: IoxAdminService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private routePage: Router,
    private translatePipe: IoxTranslatePipe
  ) {}

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.getAssetItemDetails();
    this.getJobs();
  }

  getAssetItemDetails() {
    this.service.getAssetItemById(this.id).subscribe(
      (response) => {
        this.item = response;
        if (this.item.sensors) {
          this.item.sensors.forEach((sensor: any) => {
            const sensorsDataString = localStorage.getItem('sensors');
            if (sensorsDataString) {
              const sensorsData: Sensor[] = JSON.parse(sensorsDataString);
              const sensorData = sensorsData.find(
                (entry) => entry.id === sensor.sensor_id
              );
              if (
                sensorData &&
                sensorData['lastValue'] &&
                sensorData['lastValue']['sensorValueEntries']
              ) {
                const sensorValueEntries: SensorValueEntry[] =
                  sensorData['lastValue']['sensorValueEntries'];
                const latitudeEntry = sensorValueEntries.find(
                  (entry) => entry.propName === 'lattitude'
                );
                const longitudeEntry = sensorValueEntries.find(
                  (entry) => entry.propName === 'longtitude'
                );
                if (latitudeEntry.propValue && longitudeEntry.propValue) {
                  this.lat = Number(latitudeEntry.propValue);
                  this.lng = Number(longitudeEntry.propValue);
                }
              }
            }
          });
        }
        if (!this.lat) {
          this.lat = Number(this.item.lat);
          this.lng = Number(this.item.lng);
        }
      },
      (err: HttpErrorResponse) => AxUtils.handleServiceError(err)
    );
  }

  getJobs() {
    this.service.getJobPlansOfAssetItem(this.id).subscribe(
      (response) => {
        this.dataSource = response;
      },
      (err: HttpErrorResponse) => AxUtils.handleServiceError(err)
    );
  }

  confirmDeleteDialog() {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        name: this.item.name,
        title: this.translatePipe.transform('Delete Machine'),
        message: this.translatePipe.transform('Confirm delete Machine'),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.delete();
      }
    });
  }

  delete() {
    this.service.deleteMachine(this.id).subscribe(
      (response) => {
        this.showSnackbar(
          this.translatePipe.transform('Delete Machine Successfully')
        );
        this.routePage.navigate(['/master-data/machinery']);
      },
      (err: HttpErrorResponse) => AxUtils.handleServiceError(err)
    );
  }

  private showSnackbar(message: string) {
    this.snackBar.open(message, 'Dismiss');
  }

  edit() {
    this.routePage.navigate([`/master-data/machinery/edit/${this.id}`]);
  }
}
