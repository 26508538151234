<breadcrumb></breadcrumb>
<mat-card>
  <mat-card-actions>
    <button mat-raised-button (click)="newDevice()" color="primary">
      <mat-icon>add</mat-icon> {{ "Add New Device" | ioxTranslate }}
    </button>

    <button mat-raised-button (click)="newDashboard()" color="accent">
      <mat-icon>add</mat-icon> {{ "Manage Device Dashboard" | ioxTranslate }}
    </button>
    <button mat-button (click)="bulkImport()">
      <mat-icon> arrow_downward </mat-icon> {{ "Bulk Import" | ioxTranslate }}
    </button>
    <button mat-button (click)="exportDevices()">
      <mat-icon> arrow_upward </mat-icon>{{ "Export Devices" | ioxTranslate }}
    </button>
  </mat-card-actions>
</mat-card>
<mat-card>
  <mat-card-content>
    <div [fxFlex.gt-sm]="20" [fxFlex.gt-xs]="20" fxFlex="20" class="dt-mt-col">
      <div class="dt-mt-row">
        <mat-form-field>
          <mat-icon matPrefix>search</mat-icon>
          <input
            matInput
            type="search"
            [(ngModel)]="value"
            (keyup.enter)="onKey($event.target.value)"
            (keyup)="onKey($event.target.value)"
            placeholder="{{ 'Search Device' | ioxTranslate }}"
          />
        </mat-form-field>
      </div>
      <div class="dt-mt-row">
        <div class="sensor-list">
          <mat-card-content>
            <div id="container">
              <mat-selection-list role="list" #sensorSelect [multiple]="false">
                <mat-list-option
                  role="listitem"
                  *ngFor="let sensor of selectedSensors"
                  [value]="sensor"
                  (click)="clickSelectSensor(sensor)"
                >
                  <!-- <img mat-list-avatar [src]="message.image" [alt]="message.from"> -->
                  <h3 mat-line>
                    <strong> {{ sensor.name }} </strong>
                  </h3>
                  <p mat-line>{{ sensor.tenantId }} - {{ sensor.id }}</p>
                  <p mat-line class="demo-secondary-text">{{ sensor.clazz }}</p>
                </mat-list-option>
              </mat-selection-list>
            </div>
          </mat-card-content>
        </div>
      </div>
    </div>

    <div [fxFlex.gt-sm]="80" [fxFlex.gt-xs]="80" fxFlex="80" class="dt-mt-col">
      <mat-tab-group
        mat-stretch-tabs
        dynamicHeight
        (selectedTabChange)="onSelectChange($event)"
        #tabGroup
      >
        <mat-tab *ngFor="let tab of tabs" [disabled]="tab.disabled">
          <mat-card-content>
            <ng-template mat-tab-label>{{
              tab.label | ioxTranslate
            }}</ng-template>

            <app-details-device
              *ngIf="tab.label == 'Details'"
              [dataSource]="sensorClicked"
              [columnsToDisplay]="columnsToDisplay"
              [view]="'table'"
            >
            </app-details-device>

            <app-edit-device *ngIf="tab.label == 'Edit'" [sensor]="sensorObj">
            </app-edit-device>

            <app-details-device
              *ngIf="tab.label == 'Last Message'"
              [dataSource]="lastSensorData"
              [columnsToDisplay]="columnsToDisplay"
              [view]="'table'"
            >
            </app-details-device>

            <app-script-device
              *ngIf="tab.label == 'Script'"
              [sensor]="sensorObj"
            >
            </app-script-device>

            <app-details-device
              *ngIf="tab.label == 'Last Message (Json)'"
              [dataSource]="lastSensorData"
              [jsonData]="jsonData"
              [columnsToDisplay]="columnsToDisplay"
              [view]="'json'"
            >
            </app-details-device>
          </mat-card-content>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-card-content>
</mat-card>
