import { Injectable } from '@angular/core';
import { menuItems } from './../../nav';
import { Menu, MenuChildrenItem, MenuService } from './menu.service';
import tinycolor from 'tinycolor2';
import { Color } from 'src/app/models/themes/color';
import { LocalStorageService } from './authentication/storage.service';
import { GrafanaService } from '../api/grafana.service';
import { EnvService } from './env.service';
import { IoxAdminService } from '../api/iox-admin.service';

@Injectable({
  providedIn: 'root',
})
export class StartupService {
  public menuItems: Menu[] = menuItems;
  primaryColorPalette: Color[] = [];
  accentColorPalette: Color[] = [];
  sub_menus = [];
  menu_types = ['REPORT', 'OPERATION', 'STATUS'];
  constructor(
    private menu: MenuService,
    private grafana: GrafanaService,
    private env: EnvService,
    private store: LocalStorageService,
    private ioxAdmin: IoxAdminService
  ) {
    const mPos = menuItems.find((item) => item.route === 'grafana');
    if (mPos.children) {
      // get childrens from api
      this.ioxAdmin.getCustomerSites().subscribe((res) => {
        mPos.children = res;
        this.sub_menus = res.map((obj) => obj.route);
      });
      this.grafana.getDashboard().subscribe((res) => {
        res.forEach((dashboard: any) => {
          if (dashboard.tags.includes(this.env.app)) {
            const gfMenuItem: MenuChildrenItem = {
              route: dashboard.title,
              name: dashboard.title,
              type: 'link',
            };
            mPos.children.push(gfMenuItem);
          }

          for (const sub_menu of this.sub_menus) {
            for (const menu_type of this.menu_types) {
              const tagToFind =
                this.env.app + '-' + sub_menu.toUpperCase() + '-' + menu_type;

              if (dashboard.tags.includes(tagToFind)) {
                const gfMenuItem: MenuChildrenItem = {
                  route: dashboard.title,
                  name: dashboard.title,
                  type: 'link',
                };

                let parent = mPos.children.find(
                  (child) => child.route === sub_menu
                );

                if (parent) {
                  let childMenu = parent.children.find(
                    (child) => child.route === menu_type.toLowerCase()
                  );

                  if (childMenu) {
                    childMenu.children.push(gfMenuItem);
                  }
                }
              }
            }
          }
          //           for (const sub_menu of this.sub_menus) {
          //             const sub_menu_uppercase = sub_menu.toUpperCase();
          //             if (
          //               dashboard.tags.includes(
          //                 this.env.app + '-' + sub_menu_uppercase + '-REPORT'
          //               )
          //             ) {
          //               const gfMenuItem: MenuChildrenItem = {
          //                 route: dashboard.title,
          //                 name: dashboard.title,
          //                 type: 'link',
          //               };
          //
          //               let parent = mPos.children.find(
          //                 (child) => child.route === sub_menu
          //               );
          //
          //               if (parent) {
          //                 parent.children
          //                   .find((child) => child.route === 'report')
          //                   .children.push(gfMenuItem);
          //                 // parent.children.push(gfMenuItem);
          //               }
          //             }
          //           }
        });
      }); // end of go through the grafana dashbaord add sub menu
    }
  }

  load() {
    this.menu.recursMenuForTranslation(menuItems, 'menu');
    this.menu.set(menuItems);

    // Load Colors of Tenant
    this.primaryColorPalette = this.computeColors(
      this.store.get('primary_color')
    );
    this.updateTheme(this.primaryColorPalette, 'primary');
    this.accentColorPalette = this.computeColors(
      this.store.get('accent_color')
    );
    this.updateTheme(this.accentColorPalette, 'accent');
  }

  public updateTheme(colors: Color[], theme: string) {
    colors.forEach((color) => {
      document.documentElement.style.setProperty(
        `--theme-${theme}-${color.name}`,
        color.hex
      );
      document.documentElement.style.setProperty(
        `--theme-${theme}-contrast-${color.name}`,
        color.darkContrast ? 'rgba(black, 0.87)' : 'white'
      );
    });
  }

  public computeColors(hex: string): Color[] {
    return [
      this.getColorObject(tinycolor(hex).lighten(52), '50'),
      this.getColorObject(tinycolor(hex).lighten(37), '100'),
      this.getColorObject(tinycolor(hex).lighten(26), '200'),
      this.getColorObject(tinycolor(hex).lighten(12), '300'),
      this.getColorObject(tinycolor(hex).lighten(6), '400'),
      this.getColorObject(tinycolor(hex), '500'),
      this.getColorObject(tinycolor(hex).darken(6), '600'),
      this.getColorObject(tinycolor(hex).darken(12), '700'),
      this.getColorObject(tinycolor(hex).darken(18), '800'),
      this.getColorObject(tinycolor(hex).darken(24), '900'),
      this.getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
      this.getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
      this.getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
      this.getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700'),
    ];
  }

  private getColorObject(value, name): Color {
    const c = tinycolor(value);
    return {
      name: name,
      hex: c.toHexString(),
      darkContrast: c.isLight(),
    };
  }
}
