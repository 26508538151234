<h5 mat-dialog-title>
  <strong>{{ data.title | titlecase | ioxTranslate }}</strong>
</h5>
<p class="m-b-16 p-b-16 m-t-0 p-t-0">
  {{ data.message | ioxTranslate }}
  <strong>{{ data.name | ioxTranslate }}</strong> ?
</p>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Remarks</mat-label>
    <input matInput [(ngModel)]="remarks" required />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">
    {{ "Cancel" | ioxTranslate }}
  </button>
  <button mat-raised-button color="warn" (click)="onConfirmClick()">
    {{ "Confirm" | ioxTranslate }}
  </button>
</div>
