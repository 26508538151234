import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from '../core/env.service';

@Injectable({
  providedIn: 'root',
})
export class SensorValueService {
  private url = this.env.coreApi + '/SensorValue';
  constructor(private http: HttpClient, private env: EnvService) {}

  // public getSensorValueLast(sensorId: string): Observable<any> {
  //   return this.http.get<any>(this.url + '/Newest/' + sensorId);
  // }
}
