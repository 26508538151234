import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AxUtils } from 'src/app/shared/utils/axUtils';
import { User } from 'src/app/models/api/user';
import { UserService } from 'src/app/services/api/user.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { NewUserComponent } from './new-user/new-user.component';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EditUserComponent } from './edit-user/edit-user.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  public showTable = false;
  public users: User[];
  public selectedUser: User;
  public searchTextField = '';
  public newUser: User;
  public action: string;
  public rows = [];
  public deleteUserMessage = 'User Deleted Successfully';
  public temp = [];
  public displayedColumns = [
    'firstName',
    'lastName',
    'userName',
    'emailAddress',
    'Actions',
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  selection = new SelectionModel<any>(false, []);
  public length = 0;
  public dataSource = new MatTableDataSource<User>();
  @ViewChild('table') table: MatTable<any>;

  @ViewChild('newUserEditor', { static: true }) public newUserEditor;

  constructor(
    private userService: UserService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.initialize();
  }

  initialize() {
    this.userService.get().subscribe(
      (response) => {
        this.users = response.filter((user) => user.firstName != 'DELETED');
        this.temp = this.users;
        this.dataSource = new MatTableDataSource(this.users);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showTable = true;
      },
      (err: HttpErrorResponse) => AxUtils.handleServiceError(err)
    );
  }

  filterData(event) {
    const val = event.target.value.toLowerCase();
    const colsAmt = this.displayedColumns.length;
    const keys = Object.keys(this.temp[0]);
    // assign filtered matches to the active datatable
    this.dataSource.data = this.temp.filter((item) => {
      // iterate through each row's column data
      for (let i = 0; i < colsAmt; i++) {
        // check for a match
        if (
          item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 ||
          !val
        ) {
          // found match, return true to add to result set
          return true;
        }
      }
    });
    this.paginator.pageIndex = 0;
  }

  onSelect(row) {
    this.selection.select(row);
  }

  confirmDeleteDialog(event, user: User) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        name: user.firstName,
        title: 'Delete User',
        message: 'Confirm delete User:',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.delete(user);
      }
    });
  }

  delete(user: User) {
    user.password = AxUtils.makeRandom(16);
    user.firstName = 'DELETED';
    user.lastName = 'DELETED';
    user.emailAddress = 'DELETED';
    this.userService.update(user).subscribe((resp) => {
      this.snackBar.open(this.deleteUserMessage, 'Dismiss');
      this.initialize();
    });
  }

  new() {
    const dialogRef = this.dialog.open(NewUserComponent, {
      autoFocus: true,
      width: '100%',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((data) => this.initialize());
  }

  edit(event, row) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(EditUserComponent, {
      autoFocus: true,
      width: '100%',
      disableClose: true,
      data: {
        user: row,
      },
    });
    dialogRef.afterClosed().subscribe((data) => this.initialize());
  }
}
