import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../services/core/authentication/auth.guard';
import { AdminLayoutComponent } from '../theme/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from '../theme/auth-layout/auth-layout.component';
import { AlarmsComponent } from './alarms/alarms.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AddGfDashboardComponent } from './devices/add-gf-dashboard/add-gf-dashboard.component';
import { DevicesComponent } from './devices/devices.component';
import { EventsComponent } from './events/events.component';
import { ExplorerComponent } from './explorer/explorer.component';
import { GrafanaComponent } from './grafana-dashboards/grafana/grafana.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { FloatingBargeComponent } from './master-data/floating-barge/floating-barge.component';
import { MachineryDetailsComponent } from './master-data/machinery/machinery-details/machinery-details.component';
import { MachineryComponent } from './master-data/machinery/machinery.component';
import { ProductsComponent } from './master-data/products/products.component';
import { UsersComponent } from './users/users.component';
import { MachineryAddComponent } from './master-data/machinery/machinery-add/machinery-add.component';
import { FloatingBargeAddComponent } from './master-data/floating-barge/floating-barge-add/floating-barge-add.component';
import { FloatingBargeDetailsComponent } from './master-data/floating-barge/floating-barge-details/floating-barge-details.component';
import { MachineryAddJobPlansComponent } from './master-data/machinery/machinery-add-job-plans/machinery-add-job-plans.component';
import { MachineryEditComponent } from './master-data/machinery/machinery-edit/machinery-edit.component';
import { FloatingBargeEditComponent } from './master-data/floating-barge/floating-barge-edit/floating-barge-edit.component';
import { ProductsAddComponent } from './master-data/products/products-add/products-add.component';
import { ProductsDetailsComponent } from './master-data/products/products-details/products-details.component';
import { ProductsEditComponent } from './master-data/products/products-edit/products-edit.component';
import { DailyJobsComponent } from './master-data/daily-jobs/daily-jobs.component';
import { DailyJobsEditComponent } from './master-data/daily-jobs/daily-jobs-edit/daily-jobs-edit.component';
import { DailyJobsDetailsComponent } from './master-data/daily-jobs/daily-jobs-details/daily-jobs-details.component';
import { DailyJobsAddComponent } from './master-data/daily-jobs/daily-jobs-add/daily-jobs-add.component';
import { AuditLogsComponent } from './audit-logs/audit-logs.component';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: { title: 'Dashboard', titleI18n: 'dashboard' },
      },
      {
        path: 'home',
        component: HomeComponent,
        data: { title: 'Home', titleI18n: 'home' },
      },
      {
        path: 'devices',
        component: DevicesComponent,
        data: { title: 'Devices', titleI18n: 'devices' },
      },
      {
        path: 'devices/manage-dashboard',
        component: AddGfDashboardComponent,
        data: {
          title: 'Manage Device Dashboard',
          titleI18n: 'manage-dashbaord',
        },
      },
      {
        path: 'master-data/floating-barge',
        component: FloatingBargeComponent,
        data: {
          title: 'Floating Barge',
          titleI18n: 'floating-barge',
        },
      },
      {
        path: 'master-data/floating-barge/add',
        component: FloatingBargeAddComponent,
        data: {
          title: 'Add Floating Barge',
          titleI18n: 'add-floating-barge',
        },
      },
      {
        path: 'master-data/floating-barge/details/:id',
        component: FloatingBargeDetailsComponent,
        data: {
          title: 'Floating Barge Details',
          titleI18n: 'floating-barge-details',
        },
      },
      {
        path: 'master-data/floating-barge/edit/:id',
        component: FloatingBargeEditComponent,
        data: {
          title: 'Edit Floating Barge',
          titleI18n: 'edit-floating-barge',
        },
      },

      {
        path: 'master-data/daily-jobs',
        component: DailyJobsComponent,
        data: {
          title: 'Daily Jobs',
          titleI18n: 'daily-jobs',
        },
      },
      {
        path: 'master-data/daily-jobs/add',
        component: DailyJobsAddComponent,
        data: {
          title: 'Add Daily Jobs',
          titleI18n: 'add-daily-jobs',
        },
      },
      {
        path: 'master-data/daily-jobs/details/:id',
        component: DailyJobsDetailsComponent,
        data: {
          title: 'Daily Jobs Details',
          titleI18n: 'daily-jobs-details',
        },
      },
      {
        path: 'master-data/daily-jobs/edit/:id',
        component: DailyJobsEditComponent,
        data: {
          title: 'Edit Daily Jobs',
          titleI18n: 'edit-daily-jobs',
        },
      },

      {
        path: 'master-data/machinery',
        component: MachineryComponent,
        data: {
          title: 'Machinery',
          titleI18n: 'machinery',
        },
      },
      {
        path: 'master-data/machinery/add',
        component: MachineryAddComponent,
        data: {
          title: 'Add Machinery',
          titleI18n: 'add-machinery',
        },
      },
      {
        path: 'master-data/machinery/edit/:id',
        component: MachineryEditComponent,
        data: {
          title: 'Edit Machinery',
          titleI18n: 'edit-machinery',
        },
      },
      {
        path: 'master-data/machinery/add-job-plans',
        component: MachineryAddJobPlansComponent,
        data: {
          title: 'Add Job Plans',
          titleI18n: 'add-job-plans',
        },
      },
      {
        path: 'master-data/machinery/details/:id',
        component: MachineryDetailsComponent,
        data: {
          title: 'Machinery Details',
          titleI18n: 'machinery-details',
        },
      },
      {
        path: 'master-data/products',
        component: ProductsComponent,
        data: {
          title: 'Products',
          titleI18n: 'products',
        },
      },
      {
        path: 'master-data/products/add',
        component: ProductsAddComponent,
        data: {
          title: 'Add Products',
          titleI18n: 'add-products',
        },
      },
      {
        path: 'master-data/products/details/:id',
        component: ProductsDetailsComponent,
        data: {
          title: 'Products Details',
          titleI18n: 'products-details',
        },
      },
      {
        path: 'master-data/products/edit/:id',
        component: ProductsEditComponent,
        data: {
          title: 'Edit Products',
          titleI18n: 'edit-products',
        },
      },

      {
        path: 'alarms',
        component: AlarmsComponent,
        data: { title: 'Alarms', titleI18n: 'alarms' },
      },
      {
        path: 'events',
        component: EventsComponent,
        data: { title: 'Events', titleI18n: 'events' },
      },
      {
        path: 'data-explorer/sensor-data',
        component: ExplorerComponent,
        data: { title: 'Sensors Data', titleI18n: 'sensors-data' },
      },
      {
        path: 'data-explorer/audit-logs',
        component: AuditLogsComponent,
        data: { title: 'Audit Logs', titleI18n: 'audit-logs' },
      },
      {
        path: 'grafana/:dashboard',
        component: GrafanaComponent,
        data: { title: 'AIT Dashbaords', titleI18n: 'PM Dashabords I18n' },
      },
      {
        path: 'grafana/:submenu/:type/:dashboard',
        component: GrafanaComponent,
        data: { title: 'AIT Dashbaords', titleI18n: 'PM Dashabords I18n' },
      },
      {
        path: 'users',
        component: UsersComponent,
        data: { title: 'Users', titleI18n: 'users' },
      },
      {
        path: 'error',
        loadChildren: () =>
          import('./sessions/sessions.module').then((m) => m.SessionsModule),
        data: { title: 'Sessions', titleI18n: 'sessions' },
      },
    ],
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
        data: { title: 'Login', titleI18n: 'login' },
      },
      // {
      //   path: 'register',
      //   component: RegisterComponent,
      //   data: { title: 'Register', titleI18n: 'register' },
      // },
    ],
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RoutesRoutingModule {}
