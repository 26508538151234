export class GfDashboardDetails {
  id: number;
  uid: string;
  title: string;
  tags: Array<string>;
  timezone: string;
  schemaVersion: number;
  version: number;
  refresh: string;

  constructor() {
    this.id = null;
    this.uid = null;
    this.title = '';
    this.tags = [localStorage.getItem('app_name')];
    this.timezone = 'browser';
    this.schemaVersion = 16;
    this.version = 0;
    this.refresh = '25s';
  }
}
