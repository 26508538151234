import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { MatTable } from '@angular/material/table';
import { Category } from 'src/app/models/api/category';
import { DeviceState } from 'src/app/models/api/device-state';
import { DeviceEventEnum } from 'src/app/models/api/enum-types/device-event-enum';
import { DeviceStateEnum } from 'src/app/models/api/enum-types/device-state-enum';
import { Location } from 'src/app/models/api/location';
import { Sensor } from 'src/app/models/api/sensor';
import { SensorService } from 'src/app/services/api/sensor.service';
import { CruditorFormConfiguration } from 'src/app/shared/components/cruditor-form/cruditor-form.configuration';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import * as CryptoJS from 'crypto-js';
import { IoxTranslatePipe } from 'src/app/shared/pipes/iox-translate.pipe';
import { IoxLanguageService } from 'src/app/services/api/iox-language.service';
@Component({
  selector: 'app-new-device',
  templateUrl: './new-device.component.html',
  styleUrls: ['./new-device.component.scss'],
})
export class NewDeviceComponent implements OnInit {
  latitude: number;
  longitude: number;
  locationName: string;
  zoom: number;
  public scrollwheel = false;
  address: string;
  private geoCoder;
  @ViewChild('search')
  public searchElementRef: ElementRef;

  public isNewDevice = true;
  public location = this.createLocationEmptyModel();
  public sensor = this.createSensorEmptyModel();

  isLinear = true;
  isRequired = false;
  public newSensor: Sensor;
  @ViewChild('stepper') stepper: MatStepper;
  public basicForm = new FormGroup({});
  public categoryForm = new FormGroup({});
  public locationForm = new FormGroup({});
  public categories: Category;
  public newCategory: boolean = false;
  @ViewChild('table') table: MatTable<any>;
  public rows = [];
  public columns = [
    { prop: 'categoryName', name: 'Category' },
    { prop: 'categoryValue', name: 'Value' },
  ];
  public categoryName = '';
  public categoryValue = '';
  public config: CruditorFormConfiguration = {
    detailsTitle: 'Sensor Details',
    insertMessage: this.translatePipe.transform('Sensor Added Successfully'),
    updateMessage: this.translatePipe.transform('Sensor Successfully Updated'),
    deleteMessage: this.translatePipe.transform('Sensor Successfully Deleted'),
    fields: [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-4',
            type: 'input',
            key: 'id',
            templateOptions: {
              label: this.translatePipe.transform('Sensor Id'),
            },
          },
          {
            className: 'col-sm-4',
            type: 'input',
            key: 'name',
            templateOptions: {
              label: this.translatePipe.transform('Sensor Name'),
              required: true,
            },
          },
          {
            className: 'col-sm-4',
            type: 'select',
            key: 'deviceState.state',
            templateOptions: {
              label: this.translatePipe.transform('State'),
              required: true,
              options: [
                { id: 'registered', name: 'Registered' },
                { id: 'unregistered', name: 'Unregistered' },
              ],
              labelProp: 'name',
              valueProp: 'id',
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'tenantId',
            templateOptions: {
              label: this.translatePipe.transform('Tenant Id'),
            },
          },
          {
            className: 'col-sm-6',
            type: 'select',
            key: 'clazz',
            templateOptions: {
              label: this.translatePipe.transform('Type'),
              options: [
                { id: 'Sensor', name: 'Sensor' },
                { id: 'PowerSensor', name: 'PowerSensor' },
                { id: 'WaterSensor', name: 'WaterSensor' },
                { id: 'AirQualitySensor', name: 'AirQualitySensor' },
                {
                  id: 'ChillerTemperatureSensor',
                  name: 'ChillerTemperatureSensor',
                },
              ],
              labelProp: 'name',
              valueProp: 'id',
              required: true,
              // description: 'This is a custom field type.',
            },
            // wrappers: ['form-field'],
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-3',
            type: 'input',
            key: 'oemId',
            templateOptions: {
              type: 'number',
              label: this.translatePipe.transform('Oem Id'),
              // max: 99999,
              // min: 0,
              // pattern: '\\d{5}',
            },
          },
          {
            className: 'col-sm-3',
            type: 'input',
            key: 'valueUnit',
            templateOptions: {
              label: this.translatePipe.transform('Value Unit'),
            },
          },
          {
            className: 'col-sm-3',
            type: 'input',
            key: 'maxValue',
            templateOptions: {
              type: 'number',
              label: this.translatePipe.transform('Max Value'),
            },
          },
          {
            className: 'col-sm-3',
            type: 'input',
            key: 'minValue',
            templateOptions: {
              type: 'number',
              label: this.translatePipe.transform('Min Value'),
            },
          },
          {
            className: 'col-sm-4',
            type: 'input',
            key: 'resolution',
            templateOptions: {
              type: 'number',
              label: this.translatePipe.transform('Resolution'),
            },
          },
          {
            className: 'col-sm-4',
            type: 'input',
            key: 'samplingRate',
            templateOptions: {
              type: 'number',
              label: this.translatePipe.transform('Sampling Rate (Minutes)'),
            },
          },
          {
            className: 'col-sm-4',
            type: 'input',
            key: 'timeZone',
            templateOptions: {
              label: this.translatePipe.transform('Time Zone'),
            },
          },
        ],
      },
    ],
  };
  constructor(
    public sensorService: SensorService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NewDeviceComponent>,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private translatePipe: IoxTranslatePipe
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.basicForm = this.formBuilder.group({});
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.location.latitude = place.geometry.location.lat();
          this.location.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }

  onSubmit(entity) {
    this.sensor = entity;
    this.generateSensorSecret();
  }

  generateSensorSecret() {
    const hash = CryptoJS.SHA256(
      this.sensor.id + this.sensor.tenantId + this.sensor.name
    );
    this.sensor.secret = hash.toString(CryptoJS.enc.Hex);
  }

  createSensorEmptyModel(): Sensor {
    const data = new Sensor();
    data.secret = null;
    data.id = null;
    data.oemId = 0;
    data.name = null;
    data.clazz = 'Sensor';
    data.tenantId = null;
    data.location = null;
    data.deviceState = new DeviceState(
      DeviceStateEnum.registered,
      DeviceEventEnum.registrationRequest,
      'Hello from the GUI'
    );
    data.minValue = 0;
    data.maxValue = 100;
    data.valueUnit = 'ly';
    data.samplingRate = 60;
    return data;
  }

  createLocationEmptyModel(): Location {
    const data = new Location();
    data.details = null;
    data.latitude = 0.0;
    data.longitude = 0.0;
    data.address = null;
    return data;
  }

  insertRecord(): void {
    this.sensor.location = JSON.stringify(this.location);
    this.sensorService.insert(this.sensor).subscribe(
      (newEntity) => {
        this.snackBar.open(this.config.insertMessage, 'Dismiss');
      },
      (error) => {
        this.snackBar.open(
          'Add Sensor Failed due to Error Code: ' +
            error.error.code +
            ' ' +
            error.error.message,
          'Dismiss'
        );
      }
    );
  } // end of insertRecord

  addNewCategory() {
    this.newCategory = true;
  }

  handleUnsert() {
    if (this.categoryName.trim() != '') {
      this.rows.push({
        categoryName: this.categoryName,
        categoryValue: this.categoryValue,
      });
      if (this.table) {
        this.table.renderRows();
      }
      this.new();
    }
  }

  new() {
    this.categoryName = '';
    this.categoryValue = '';
  }

  delete(id) {
    this.rows.splice(id, 1);
    this.table.renderRows();
  }

  close() {
    this.newCategory = false;
  }

  saveCategories() {
    const temp: Category = {};
    this.rows.map((category) => {
      temp[category.categoryName] = category.categoryValue;
    });
    this.categories = { ...temp };
    this.sensor.categories = this.categories;
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.location.latitude = position.coords.latitude;
        this.location.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.location.latitude, this.location.longitude);
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    this.location.latitude = $event.coords.lat;
    this.location.longitude = $event.coords.lng;
    this.getAddress(this.location.latitude, this.location.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 12;
            this.location.address = results[0].formatted_address;
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }
}
