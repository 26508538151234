import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { AssetItem } from 'src/app/models/api/iox-admin/asset-item';
import { IoxAdminService } from 'src/app/services/api/iox-admin.service';
import { CruditorFormConfiguration } from 'src/app/shared/components/cruditor-form/cruditor-form.configuration';
import { AxUtils } from 'src/app/shared/utils/axUtils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { IoxTranslatePipe } from 'src/app/shared/pipes/iox-translate.pipe';
@Component({
  selector: 'app-machinery-add',
  templateUrl: './machinery-add.component.html',
  styleUrls: ['./machinery-add.component.scss'],
})
export class MachineryAddComponent implements OnInit, OnDestroy {
  form = new FormGroup({});
  assetItem: AssetItem;
  assetTypes = [];
  assets = [];
  public basicForm = new FormGroup({});
  public saveInForm = true;
  public isNewEntity = true;
  public newAssetItem = new AssetItem();
  public config: CruditorFormConfiguration = {
    detailsTitle: 'AssetItem',
    insertMessage: this.translatePipe.transform('Machine Successfully Added'),
    updateMessage: this.translatePipe.transform('Machine Successfully Updated'),
    deleteMessage: this.translatePipe.transform('Machine Successfully Deleted'),
    fields: [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'name',
            templateOptions: {
              label: this.translatePipe.transform('Name'),
              required: true,
            },
          },
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'model',
            templateOptions: {
              label: this.translatePipe.transform('Model'),
            },
          },
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'lat',
            templateOptions: {
              label: this.translatePipe.transform('Lat'),
            },
          },
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'lng',
            templateOptions: {
              label: this.translatePipe.transform('Lng'),
            },
          },
          {
            className: 'col-sm-6',
            type: 'select',
            key: 'asset_type_id',
            templateOptions: {
              label: this.translatePipe.transform('Type'),
              required: true,
              labelProp: 'name',
              valueProp: 'id',
            },
            expressionProperties: {
              'templateOptions.options': () => this.assetTypes,
            },
          },
          {
            className: 'col-sm-6',
            type: 'select',
            key: 'assets_id',
            templateOptions: {
              label: this.translatePipe.transform('Floating Barge'),
              required: true,
              labelProp: 'name',
              valueProp: 'id',
            },
            expressionProperties: {
              'templateOptions.options': () => this.assets,
            },
          },
        ],
      },
    ],
  };
  private destroy$ = new Subject<void>();
  constructor(
    private service: IoxAdminService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private route: Router,
    private translatePipe: IoxTranslatePipe
  ) {}

  ngOnInit(): void {
    this.basicForm = this.formBuilder.group({});
    forkJoin({
      assetTypes: this.service.getAssetTypes(),
      floatingBarges: this.service.getAssets(),
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.assetTypes = response.assetTypes;
          this.assets = response.floatingBarges;
        },
        (err: HttpErrorResponse) => AxUtils.handleServiceError(err)
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async insert() {
    if (this.basicForm.valid) {
      try {
        const newEntity = await this.service
          .insertAssetItem(this.newAssetItem)
          .toPromise();
        this.navigateToMachinery();
        this.showSnackbar(
          this.translatePipe.transform(this.config.insertMessage)
        );
      } catch (error) {
        const { code, message } = error.error;
        this.showSnackbar(
          `Add Machine Failed due to Error Code: ${code} ${message}`
        );
      }
    }
  }
  private navigateToMachinery() {
    this.route.navigate(['/master-data/machinery']);
  }

  private showSnackbar(message: string) {
    this.snackBar.open(message, 'Dismiss');
  }
}
