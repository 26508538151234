import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Product } from 'src/app/models/api/iox-admin/products';
import { IoxAdminService } from 'src/app/services/api/iox-admin.service';
import { AxUtils } from 'src/app/shared/utils/axUtils';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  public dataSource: MatTableDataSource<Product>;
  public searchTextField = '';
  @ViewChild('settingsIcon', { static: false })
  public settingsIconRef: ElementRef;
  displayedColumnsObj = [
    { value: 'name', show: true },
    { value: 'name_th', show: true },
    { value: 'description', show: true },
    { value: 'q22', show: true },
    { value: 'q24', show: true },
    { value: 'q27', show: true },
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(private adminService: IoxAdminService, private route: Router) {}

  ngOnInit(): void {
    this.getAllDevices();
  }

  get displayedColumns(): string[] {
    return this.displayedColumnsObj
      .filter((element) => {
        return element.show == true;
      })
      .map((element) => {
        return element.value;
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  getAllDevices() {
    this.adminService.getProducts().subscribe(
      (response) => {
        this.dataSource = new MatTableDataSource(response);
        this.dataSource.paginator = this.paginator;
      },
      (err: HttpErrorResponse) => AxUtils.handleServiceError(err)
    );
  }

  add() {
    this.route.navigate(['/master-data/products/add']);
  }

  viewDetails(row) {
    this.route.navigate(['/master-data/products/details/' + row['id']]);
  }
}
