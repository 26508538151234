<!-- <breadcrumb></breadcrumb> -->
<div class="dt-mt-row" fxLayout="row wrap">
  <div fxFlex.gt-sm="49" fxFlex.gt-xs="100" fxFlex="100" *ngIf="item">
    <!-- <h3 class="m-l-16">Machine {{ item.name }} Details</h3> -->
    <div class="container">
      <h3 class="m-l-16 title">
        {{ "Machine" | ioxTranslate }} {{ item.name }}
        {{ "Details" | ioxTranslate }}
      </h3>
      <div class="buttons">
        <button
          mat-raised-button
          (click)="edit()"
          class="material-icons color_orange"
        >
          <mat-icon>edit</mat-icon> {{ "Edit" | ioxTranslate }}
        </button>
        <button mat-raised-button (click)="confirmDeleteDialog()" color="warn">
          <mat-icon>delete</mat-icon> {{ "Delete" | ioxTranslate }}
        </button>
      </div>
    </div>
    <mat-divider></mat-divider>
    <table class="mat-table">
      <tr class="mat-row mat-header-row">
        <th class="mat-header-cell">
          <b>{{ "Id" | ioxTranslate }}:</b>
        </th>
        <td class="mat-cell">
          {{ item.id }}
        </td>
      </tr>
      <tr class="mat-row">
        <th class="mat-header-cell">
          <b>{{ "Name" | ioxTranslate }}:</b>
        </th>
        <td class="mat-cell">
          {{ item.name }}
        </td>
      </tr>
      <tr class="mat-row">
        <th class="mat-header-cell">
          <b>{{ "Model" | ioxTranslate }}:</b>
        </th>
        <td colspan="3" class="mat-cell">
          {{ item.model }}
        </td>
      </tr>
      <tr class="mat-row">
        <th class="mat-header-cell">
          <b>{{ "Floating Barge" | ioxTranslate }}:</b>
        </th>
        <td colspan="3" class="mat-cell">
          {{ item.assets.name }}
        </td>
      </tr>
      <tr class="mat-row">
        <th class="mat-header-cell">
          <b>{{ "Type" | ioxTranslate }}:</b>
        </th>
        <td colspan="3" class="mat-cell">
          {{ item.asset_type.name }}
        </td>
      </tr>
      <tr class="mat-row">
        <th class="mat-header-cell">
          <b>{{ "Sensors" | ioxTranslate }}:</b>
        </th>
        <td colspan="3" class="mat-cell">
          <mat-chip-list matPrefix #chipList class="m-t-24 m-l-16">
            <mat-chip
              *ngFor="let sensor of item.sensors"
              class="bg-cyan-500 text-white m-b-16"
            >
              {{ sensor.sensor_id }}
            </mat-chip>
          </mat-chip-list>
        </td>
      </tr>
    </table>
  </div>
  <div
    fxFlex.gt-sm="49"
    fxFlex.gt-xs="100"
    fxFlex="100"
    *ngIf="item"
    class="m-l-8 m-t-8"
  >
    <agm-map
      [latitude]="lat"
      [longitude]="lng"
      [zoom]="zoom"
      [scrollwheel]="scrollwheel"
    >
      <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="false">
      </agm-marker>
    </agm-map>
  </div>
</div>

<div class="dt-mt-row m-t-8" fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex="100">
    <mat-card>
      <h3 class="m-l-16">{{ "Machine's Job Plans" | ioxTranslate }}</h3>
      <mat-divider></mat-divider>
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="from_time">
          <th mat-header-cell *matHeaderCellDef>
            {{ "From Time" | ioxTranslate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.from_time | date : "dd/MM/yy HH:mm" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="to_time">
          <th mat-header-cell *matHeaderCellDef>
            {{ "To Time" | ioxTranslate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.to_time | date : "dd/MM/yy HH:mm" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            {{ "Status" | ioxTranslate }}
          </th>
          <td mat-cell *matCellDef="let row">
            <strong> {{ row.status | uppercase }}</strong>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </mat-card>
  </div>
</div>
