<!-- <breadcrumb></breadcrumb> -->
<div class="dt-mt-row" fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" *ngIf="item">
    <div class="container">
      <h3 class="m-l-16 title">
        {{ "Product" | ioxTranslate }} {{ item.name }}
        {{ "Details" | ioxTranslate }}
      </h3>
      <div class="buttons">
        <button
          mat-raised-button
          (click)="edit()"
          class="material-icons color_orange"
        >
          <mat-icon>edit</mat-icon> {{ "Edit" | ioxTranslate }}
        </button>
        <!-- <button mat-raised-button (click)="confirmDeleteDialog()" color="warn">
          <mat-icon>delete</mat-icon> {{ "Delete" | ioxTranslate }}
        </button> -->
      </div>
    </div>

    <mat-divider></mat-divider>
    <table class="mat-table">
      <tr class="mat-row mat-header-row">
        <th class="mat-header-cell">
          <b>{{ "Id" | ioxTranslate }}</b>
        </th>
        <td class="mat-cell">
          {{ item.id }}
        </td>
      </tr>
      <tr class="mat-row">
        <th class="mat-header-cell">
          <b>{{ "Product Name" | ioxTranslate }}</b>
        </th>
        <td class="mat-cell">
          {{ item.name }}
        </td>
      </tr>
      <tr class="mat-row">
        <th class="mat-header-cell">
          <b>{{ "Product Name (Thai)" | ioxTranslate }}</b>
        </th>
        <td class="mat-cell">
          {{ item.name_th }}
        </td>
      </tr>
      <tr class="mat-row">
        <th class="mat-header-cell">
          <b>{{ "Description" | ioxTranslate }}</b>
        </th>
        <td class="mat-cell">
          {{ item.description }}
        </td>
      </tr>
      <tr class="mat-row">
        <th class="mat-header-cell">
          <b>{{ "22Q" | ioxTranslate }}</b>
        </th>
        <td class="mat-cell">
          {{ item.q22 }}
        </td>
      </tr>
      <tr class="mat-row">
        <th class="mat-header-cell">
          <b>{{ "24Q" | ioxTranslate }}</b>
        </th>
        <td class="mat-cell">
          {{ item.q24 }}
        </td>
      </tr>
      <tr class="mat-row">
        <th class="mat-header-cell">
          <b>{{ "27Q" | ioxTranslate }}</b>
        </th>
        <td class="mat-cell">
          {{ item.q27 }}
        </td>
      </tr>
    </table>
  </div>
</div>
<!-- <table mat-table [dataSource]="dataSource"></table> -->
