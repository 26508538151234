<breadcrumb></breadcrumb>
<mat-card>
    <button mat-raised-button (click)="new()" color="primary">
        <mat-icon>add</mat-icon> Add New User
    </button>
</mat-card>
<mat-card class="mat-elevation-z8">
    <mat-form-field>
        <mat-icon matPrefix mat-raised-button>search</mat-icon>
        <input matInput type="search" (keyup)="filterData($event)" [(ngModel)]="searchTextField" type="text" ngModel
            name="searchParam" placeholder="Search User" aria-label="Search..." />
    </mat-form-field>
    <!-- <h4>User Management</h4> -->
    <!-- <mat-divider inset class="m-t-0"></mat-divider> -->
    <mat-card-content>
        <div fxLayout="row wrap">
            <div [fxFlex.gt-sm]="100" [fxFlex.gt-xs]="100" fxFlex="100">
                <table #table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="firstName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>FIRSTNAME</th>
                        <td mat-cell *matCellDef="let element">
                            <strong> {{element.firstName}} </strong>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="lastName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>LASTNAME</th>
                        <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="userName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>USERNAME</th>
                        <td mat-cell *matCellDef="let element"> {{element.userName }} </td>
                    </ng-container>

                    <ng-container matColumnDef="emailAddress">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>E-MAIL</th>
                        <td mat-cell *matCellDef="let element"> {{element.emailAddress}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Actions">
                        <th mat-header-cell *matHeaderCellDef>ACTIONS</th>
                        <td mat-cell *matCellDef="let element;index as i;">
                            <span>
                                <button mat-icon-button aria-label="Edit" matTooltip="Edit"
                                    (click)="edit($event,element)">
                                    <mat-icon class="text-orange-500">edit</mat-icon>
                                </button>
                                <button mat-icon-button aria-label="Delete" matTooltip="Delete" (click)="
                                confirmDeleteDialog($event,element)">
                                    <mat-icon color="warn">delete</mat-icon>
                                </button>
                            </span>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                        [ngClass]="{ selected: selection.isSelected(row) }" (click)="onSelect(row)">
                        <!-- [class.make-red]="row.aType=='ERROR'" -->
                    </tr>
                </table>
            </div>
            <div [fxFlex.gt-sm]="100" [fxFlex.gt-xs]="100" fxFlex="100">
                <mat-paginator #paginator [pageSizeOptions]="[5,20, 30, 50]" showFirstLastButtons></mat-paginator>
            </div>
        </div>

    </mat-card-content>
</mat-card>