import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '../env.service';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../authentication/storage.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { JwtService } from '../jwt.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenService {
  private authUrl = this.env.coreApi + '/User/Login';
  jwtHelper: JwtHelperService = new JwtHelperService();
  constructor(
    private http: HttpClient,
    private env: EnvService,
    private store: LocalStorageService,
    private token: JwtService
  ) {}

  login(username: string, password: string): Observable<any> {
    const body = { username, password };
    return this.http.post<any>(this.authUrl, body);
  }

  logout() {
    this.store.remove('access_token');
  }

  isTokenExpired() {
    return this.jwtHelper.isTokenExpired(localStorage.getItem('access_token'));
  }

  isLoggedIn() {
    return (
      this.store.get('access_token') !== null && !this.token.isTokenExpired()
    );
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }
}
