import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IoxAdminService } from 'src/app/services/api/iox-admin.service';
import { CruditorFormConfiguration } from 'src/app/shared/components/cruditor-form/cruditor-form.configuration';
import { AxUtils } from 'src/app/shared/utils/axUtils';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from 'src/app/models/api/iox-admin/products';
import { IoxTranslatePipe } from 'src/app/shared/pipes/iox-translate.pipe';

@Component({
  selector: 'app-products-edit',
  templateUrl: './products-edit.component.html',
  styleUrls: ['./products-edit.component.scss'],
})
export class ProductsEditComponent implements OnInit, OnDestroy {
  validFloatValidator() {
    return {
      expression: (c) => {
        if (c.value === null || c.value === undefined) {
          return true; // Allow empty values
        }
        return !isNaN(parseFloat(c.value)) && isFinite(c.value);
      },
      message: this.translatePipe.transform('Invalid Number value'),
    };
  }
  form = new FormGroup({});
  public basicForm = new FormGroup({});
  public saveInForm = true;
  public isNewEntity = true;
  public item: Product;
  public config: CruditorFormConfiguration = {
    detailsTitle: 'Products',
    insertMessage: this.translatePipe.transform('Product Successfully Added'),
    updateMessage: this.translatePipe.transform('Product Successfully Updated'),
    deleteMessage: this.translatePipe.transform('Product Successfully Deleted'),
    fields: [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-12',
            type: 'input',
            key: 'name',
            templateOptions: {
              label: this.translatePipe.transform('Name'),
              required: true,
            },
          },

          {
            className: 'col-sm-6',
            type: 'input',
            key: 'name_th',
            templateOptions: {
              label: this.translatePipe.transform('Name (Thai)'),
              required: true,
            },
          },
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'description',
            templateOptions: {
              label: this.translatePipe.transform('Description'),
            },
          },
          {
            className: 'col-sm-4',
            type: 'input',
            key: 'q22',
            templateOptions: {
              label: this.translatePipe.transform('22Q'),
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-sm-4',
            type: 'input',
            key: 'q24',
            templateOptions: {
              label: this.translatePipe.transform('24Q'),
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-sm-4',
            type: 'input',
            key: 'q27',
            templateOptions: {
              label: this.translatePipe.transform('27Q'),
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
        ],
      },
    ],
  };
  id: Number;
  private destroy$ = new Subject<void>();
  constructor(
    private service: IoxAdminService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private route_path: Router,
    private translatePipe: IoxTranslatePipe
  ) {}

  ngOnInit(): void {
    this.basicForm = this.formBuilder.group({});
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.getItemDetails();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getItemDetails() {
    this.service.getProductsById(this.id).subscribe(
      (response) => {
        this.item = response;
      },
      (err: HttpErrorResponse) => AxUtils.handleServiceError(err)
    );
  }

  async save() {
    if (this.basicForm.valid) {
      try {
        const newEntity = await this.service.editProduct(this.item).toPromise();
        this.navigateToFloating();
        this.showSnackbar(
          this.translatePipe.transform(this.config.updateMessage)
        );
      } catch (error) {
        const { code, message } = error.error;
        this.showSnackbar(
          `Add Product Failed due to Error Code: ${code} ${message}`
        );
      }
    }
  }
  private navigateToFloating() {
    this.route_path.navigate([`/master-data/products/details/${this.id}`]);
  }

  private showSnackbar(message: string) {
    this.snackBar.open(message, 'Dismiss');
  }
}
