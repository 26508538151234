<mat-card>
  <mat-card-subtitle>
    <strong> {{ "Expression Editor" | ioxTranslate }} </strong>
  </mat-card-subtitle>
  <mat-divider class="m-y-0" inset></mat-divider>

  <mat-card-content *ngIf="sensor">
    <mat-form-field class="full-width">
      <mat-label>{{ "Layers" | ioxTranslate }}</mat-label>

      <mat-select [(ngModel)]="sensor.smartExpressionLayer" #expressionLayer>
        <mat-option *ngFor="let layer of layers" [value]="layer.value">
          {{ layer.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <ngx-codemirror
      class="big f-w-500"
      #expressionEditor
      [autoFocus]="true"
      [options]="options"
      [(ngModel)]="sensor.smartExpression"
      style="height: 50px"
    >
      {{ sensor.smartExpression }}</ngx-codemirror
    >
    <!-- <mat-form-field class="full-width">
            <textarea matInput #expressionEditor [(ngModel)]="sensor.smartExpression"
                class="mat-elevation-z5 big f-w-500"> {{sensor.smartExpression}}</textarea>
        </mat-form-field> -->
    <mat-card-subtitle class="m-t-8">
      <strong> {{ "Script Editor" | ioxTranslate }}</strong>
    </mat-card-subtitle>
    <mat-divider class="m-y-0" inset></mat-divider>
    <mat-form-field class="full-width">
      <mat-label>{{ "Layers" | ioxTranslate }}</mat-label>
      <mat-select [(ngModel)]="sensor.smartScriptLayer" #scriptLayer>
        <mat-option *ngFor="let layer of layers" [value]="layer.value">
          {{ layer.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- <mat-form-field class="full-width"> -->
    <ngx-codemirror
      #scriptEditor
      [options]="options"
      [(ngModel)]="sensor.smartScript"
      style="height: auto"
      [autoFocus]="true"
      (ngModelChange)="handleChange($event)"
    >
      {{ sensor.smartScript }}</ngx-codemirror
    >
    <!-- <textarea matInput [(ngModel)]="sensor.smartScript" #scriptEditor
                class="mat-elevation-z5 big f-w-500">{{sensor.smartScript}}</textarea> -->
    <!-- </mat-form-field> -->
    <button
      mat-raised-button
      class="m-t-8"
      color="primary"
      (click)="
        update(
          expressionLayer.value,
          expressionEditor.value,
          scriptLayer.value,
          scriptEditor.value
        )
      "
    >
      {{ "Update" | ioxTranslate }}
    </button>
  </mat-card-content>
</mat-card>
