export class StatPanel {
  datasource: string;
  fieldConfig: object;
  gridPos: object;
  id: number;
  options: object;
  pluginVersion: string;
  targets: Array<object>;
  timeFrom: string;
  timeShift: string;
  title: string;
  type: string;

  constructor() {
    this.datasource = null;
    this.fieldConfig = {
      defaults: {
        color: {
          mode: 'thresholds',
        },
        mappings: [],
        thresholds: {
          mode: 'absolute',
          steps: [
            {
              color: 'green',
              value: null,
            },
            {
              color: 'red',
              value: 80,
            },
          ],
        },
      },
      overrides: [],
    };
    this.gridPos = {
      h: 11,
      w: 12,
      x: 0,
      y: 11,
    };
    this.id = 1;
    this.options = {
      colorMode: 'value',
      graphMode: 'area',
      justifyMode: 'auto',
      orientation: 'auto',
      reduceOptions: {
        calcs: ['lastNotNull'],
        fields: '',
        values: false,
      },
      text: {},
      textMode: 'auto',
    };
    this.pluginVersion = '7.5.4';
    this.targets = [
      {
        groupBy: [
          {
            params: ['5m'],
            type: 'time',
          },
          {
            params: ['null'],
            type: 'fill',
          },
        ],
        measurement: 'ACER_PM',
        orderByTime: 'ASC',
        policy: 'default',
        refId: 'A',
        resultFormat: 'time_series',
        select: [
          [
            {
              params: [''],
              type: 'field',
            },
            {
              params: [],
              type: 'mean',
            },
          ],
        ],
        tags: [
          {
            key: 'sensorId',
            operator: '=',
            value: '',
          },
        ],
      },
    ];

    this.timeFrom = null;
    this.timeShift = null;
    this.title = '';
    this.type = 'stat';
  }
}
