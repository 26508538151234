import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import * as CodeMirror from 'codemirror';

export interface DataSensor {
  field: string;
  fValue: string;
}
export interface DialogData {
  sensorDetails: DataSensor[];
  data: DataSensor[];
  jsonData: string;
  dataSensorValue: DataSensor[];
}

@Component({
  selector: 'app-generic-detail-viewer',
  templateUrl: './generic-detail-viewer.component.html',
  styleUrls: ['./generic-detail-viewer.component.scss'],
})
export class GenericDetailViewerComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('jsonData1') private codeEditor;
  public dataSource: any;
  dataSourceSensor: any;
  dataSourceSensorValue: any;
  public showJson = false;
  public columnsToDisplay = ['field', 'fValue'];
  public options = {
    // lineNumbers: true,
    lineWrapping: true,
    theme: 'material',
    mode: 'javascript',
    readOnly: true,
    autoRefresh: true,
    noHScroll: true,
  };
  constructor(
    @Inject(MAT_DIALOG_DATA) public dataDialog: DialogData,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {
    // Filter Icon
    this.matIconRegistry.addSvgIcon(
      `filterIcon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `https://image.flaticon.com/icons/svg/1159/1159641.svg`
      )
    );
    // Sort Icon
    this.matIconRegistry.addSvgIcon(
      `sortIcon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `https://image.flaticon.com/icons/svg/25/25756.svg`
      )
    );
    // this.dataSource.data = this.data;
    this.dataSource = new MatTableDataSource(this.dataDialog.data);
    this.dataSource.sort = this.sort;
    if (this.dataDialog.sensorDetails) {
      this.dataDialog.sensorDetails.sort((a, b) =>
        a.field.localeCompare(b.field)
      );
    }
    this.dataSourceSensor = new MatTableDataSource(
      this.dataDialog.sensorDetails
    );
    this.dataSourceSensorValue = new MatTableDataSource(
      this.dataDialog.dataSensorValue
    );
  }
  ngAfterViewInit(): void {
    this.showJson = true;
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
  }

  getActiveTab(tabIdx) {
    if (tabIdx == 2 || tabIdx == 1) {
      const editor = this.codeEditor.codeMirror;
      // editor.setSize(null, 550);
      setTimeout(function () {
        editor.refresh();
      }, 100);
    }
  }
}
