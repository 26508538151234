import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AssetItem } from 'src/app/models/api/iox-admin/asset-item';
import { IoxAdminService } from 'src/app/services/api/iox-admin.service';
import { AxUtils } from 'src/app/shared/utils/axUtils';

@Component({
  selector: 'app-machinery',
  templateUrl: './machinery.component.html',
  styleUrls: ['./machinery.component.scss'],
})
export class MachineryComponent implements OnInit {
  public dataSource: MatTableDataSource<AssetItem>;
  public searchTextField = '';
  @ViewChild('settingsIcon', { static: false })
  public settingsIconRef: ElementRef;
  displayedColumnsObj = [
    { value: 'name', show: true },
    { value: 'asset_type', show: true },
    { value: 'model', show: true },
    { value: 'lat', show: true },
    { value: 'lng', show: true },
    { value: 'asset_id', show: true },
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    private adminService: IoxAdminService,
    private dialog: MatDialog,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.getAllDevices();
  }

  get displayedColumns(): string[] {
    return this.displayedColumnsObj
      .filter((element) => {
        return element.show == true;
      })
      .map((element) => {
        return element.value;
      });
  }

  add() {
    this.route.navigate(['/master-data/machinery/add']);
  }

  addJobPlans() {
    this.route.navigate(['/master-data/machinery/add-job-plans']);
  }

  viewDetails(row) {
    this.route.navigate(['/master-data/machinery/details/' + row['id']]);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  getAllDevices() {
    this.adminService.getAssetItems().subscribe(
      (response) => {
        this.dataSource = new MatTableDataSource(response);
        this.dataSource.paginator = this.paginator;
      },
      (err: HttpErrorResponse) => AxUtils.handleServiceError(err)
    );
  }

  showSettingsModal() {}
}
