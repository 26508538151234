import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IoxAdminService } from 'src/app/services/api/iox-admin.service';
import { CruditorFormConfiguration } from 'src/app/shared/components/cruditor-form/cruditor-form.configuration';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Product } from 'src/app/models/api/iox-admin/products';
import { IoxTranslatePipe } from 'src/app/shared/pipes/iox-translate.pipe';

@Component({
  selector: 'app-products-add',
  templateUrl: './products-add.component.html',
  styleUrls: ['./products-add.component.scss'],
})
export class ProductsAddComponent implements OnInit, OnDestroy {
  validFloatValidator() {
    return {
      expression: (c) => {
        if (c.value === null || c.value === undefined) {
          return true; // Allow empty values
        }
        return !isNaN(parseFloat(c.value)) && isFinite(c.value);
      },
      message: this.translatePipe.transform('Invalid Number value'),
    };
  }
  form = new FormGroup({});
  assetTypes = [];
  assets = [];
  public basicForm = new FormGroup({});
  public saveInForm = true;
  public isNewEntity = true;
  public item = new Product();
  public config: CruditorFormConfiguration = {
    detailsTitle: 'Products',
    insertMessage: this.translatePipe.transform('Product Successfully Added'),
    updateMessage: this.translatePipe.transform('Product Successfully Updated'),
    deleteMessage: this.translatePipe.transform('Product Successfully Deleted'),
    fields: [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-12',
            type: 'input',
            key: 'name',
            templateOptions: {
              label: this.translatePipe.transform('Name'),
              required: true,
            },
          },

          {
            className: 'col-sm-6',
            type: 'input',
            key: 'name_th',
            templateOptions: {
              label: this.translatePipe.transform('Name (Thai)'),
              required: true,
            },
          },
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'description',
            templateOptions: {
              label: this.translatePipe.transform('Description'),
            },
          },
          {
            className: 'col-sm-4',
            type: 'input',
            key: 'q22',
            templateOptions: {
              label: this.translatePipe.transform('22Q'),
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-sm-4',
            type: 'input',
            key: 'q24',
            templateOptions: {
              label: this.translatePipe.transform('24Q'),
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-sm-4',
            type: 'input',
            key: 'q27',
            templateOptions: {
              label: this.translatePipe.transform('27Q'),
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
        ],
      },
    ],
  };
  private destroy$ = new Subject<void>();
  constructor(
    private service: IoxAdminService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private route: Router,
    private translatePipe: IoxTranslatePipe
  ) {}

  ngOnInit(): void {
    this.basicForm = this.formBuilder.group({});
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async insert() {
    if (this.basicForm.valid) {
      try {
        const newEntity = await this.service
          .insertProduct(this.item)
          .toPromise();
        this.navigateToProducts();
        this.showSnackbar(
          this.translatePipe.transform(this.config.insertMessage)
        );
      } catch (error) {
        const { code, message } = error;
        this.showSnackbar(
          `Add Product Failed due to Error Code: ${code} ${message}`
        );
      }
    }
  }
  private navigateToProducts() {
    this.route.navigate(['/master-data/products']);
  }

  private showSnackbar(message: string) {
    this.snackBar.open(message, 'Dismiss');
  }
}
