import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { User } from 'src/app/models/api/user';
import { UserService } from 'src/app/services/api/user.service';
import { CruditorFormConfiguration } from 'src/app/shared/components/cruditor-form/cruditor-form.configuration';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss'],
})
export class NewUserComponent implements OnInit {
  form = new FormGroup({});
  user: User;
  // public isNewUser = true;
  public saveInForm = true;
  public isNewEntity = true;
  public newUser = new User();
  public config: CruditorFormConfiguration = {
    detailsTitle: 'User',
    insertMessage: 'User Successfully Added',
    updateMessage: 'User Successfully Updated',
    deleteMessage: 'User Successfully Deleted',
    fields: [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'firstName',
            templateOptions: {
              label: 'Firstname',
              required: true,
              // disabled: true,
            },
          },
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'lastName',
            templateOptions: {
              label: 'Lastname',
              // required: true,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'userName',
            templateOptions: {
              label: 'Username',
              required: true,
            },
          },
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'emailAddress',
            templateOptions: {
              label: 'E-mail',
            },
          },
        ],
      },
      {
        key: 'newPassword',
        validators: {
          fieldMatch: {
            expression: (control) => {
              const value = control.value;
              return (
                value.confirmedPassword === value.newPassword ||
                // avoid displaying the message error when values are empty
                !value.confirmedPassword ||
                !value.newPassword
              );
            },
            message: 'Password Not Matching',
            errorPath: 'confirmedPassword',
          },
        },
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'newPassword',
            templateOptions: {
              label: 'Password',
              required: true,
              type: 'password',
            },
          },
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'confirmedPassword',
            templateOptions: {
              label: 'Confirm Password',
              required: true,
              type: 'password',
            },
          },
        ],
      },
    ],
  };
  constructor(public userService: UserService) {}

  ngOnInit(): void {}
}
