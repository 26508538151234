import { Menu } from './services/core/menu.service';

export const menuItems: Menu[] = [
  {
    route: 'home',
    name: 'Home',
    type: 'link',
    icon: 'dashboard',
  },
  {
    route: 'grafana',
    name: 'Dashboards',
    type: 'sub',
    icon: 'settings_system_daydream',
    children: [],
  },
  {
    route: 'dashboard',
    name: 'Sensor Status',
    type: 'link',
    icon: 'waves',
  },
  {
    route: 'devices',
    name: 'Devices',
    type: 'link',
    icon: 'devices',
    children: [
      {
        route: 'manage-dashboard',
        name: 'Manage Dashboards',
        type: 'link',
      },
    ],
  },
  {
    route: 'master-data',
    name: 'Master Data',
    type: 'sub',
    icon: 'devices',
    children: [
      {
        route: 'floating-barge',
        name: 'Floating Barges',
        type: 'link',
        children: [
          {
            route: 'add',
            name: 'Add Floating Barges',
            type: 'link',
          },
          {
            route: 'edit',
            name: 'Edit Floating Barges',
            type: 'link',
          },
          {
            route: 'details',
            name: 'Floating Barges Details',
            type: 'link',
          },
          {
            route: 'add-daily-job',
            name: 'Add Daily Job',
            type: 'link',
          },
        ],
      },
      {
        route: 'daily-jobs',
        name: 'Daily Jobs',
        type: 'link',
        children: [
          {
            route: 'add',
            name: 'Add Daily Jobs',
            type: 'link',
          },
          {
            route: 'edit',
            name: 'Edit Daily Jobs',
            type: 'link',
          },
          {
            route: 'details',
            name: 'Daily Jobs Details',
            type: 'link',
          },
        ],
      },
      {
        route: 'machinery',
        name: 'Machinery',
        type: 'link',
        children: [
          {
            route: 'add',
            name: 'Add Machinery',
            type: 'link',
          },
          {
            route: 'edit',
            name: 'Edit Machinery',
            type: 'link',
          },
          {
            route: 'details',
            name: 'Machinery Details',
            type: 'link',
          },
          {
            route: 'add-job-plans',
            name: 'Add Job Plans',
            type: 'link',
          },
        ],
      },
      {
        route: 'products',
        name: 'Products',
        type: 'link',
        children: [
          {
            route: 'add',
            name: 'Add Products',
            type: 'link',
          },
          {
            route: 'edit',
            name: 'Edit Products',
            type: 'link',
          },
          {
            route: 'details',
            name: 'Product Details',
            type: 'link',
          },
        ],
      },
    ],
  },
  {
    route: 'alarms',
    name: 'Alarms',
    type: 'link',
    icon: 'alarm',
  },
  {
    route: 'events',
    name: 'Events',
    type: 'link',
    icon: 'event',
  },
  {
    route: 'data-explorer',
    name: 'Data Explorer',
    type: 'sub',
    icon: 'explore',
    children: [
      {
        route: 'sensor-data',
        name: 'Sensors Data',
        type: 'link',
      },
      {
        route: 'audit-logs',
        name: 'Audit Logs',
        type: 'link',
      },
    ],
  },

  // {
  //   route: 'users',
  //   name: 'Users',
  //   type: 'link',
  //   icon: 'people',
  // },
];
