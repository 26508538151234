export class DailyJobs {
  id: number;
  ship_name: string;
  barge_name: string;
  product_type: string;
  job_start: Date;
  job_end: Date;
  weight_ton: number;
  rate_product_ton_hour: number;
  rate_ltr_ton: number;
  fuel_used: number;
  fuel_actual_use: number;
  fuel_stop_time: number;
  fuel_heave_machine: number;
  lift_up_down_hour: number;
  lift_up_down_minutes: number;
  lift_up_down_fuel: number;
  machine_fail_hour: number;
  machine_fail_minutes: number;
  fuel_machine_fail: number;
  refuel_hour: number;
  refuel_minutes: number;
  refuel_ltr: number;
  move_barge_hour: number;
  move_barge_minutes: number;
  move_barge_fuel: number;
  wait_pontoon_hour: number;
  wait_pontoon_minutes: number;
  wait_pontoon_ltr: number;
  stop_rain_hour: number;
  stop_rain_minutes: number;
  stop_rain_fuel: number;
  other_hour: number;
  other_minutes: number;
  other_ltr: number;
  sum_working_hours: number;
  sum_hours_stop_work: number;
  createdAt: Date;
  asset: Object;
  product: Object;

  constructor() {
    this.ship_name = null;
    this.barge_name = null;
    this.product_type = null;
    this.job_start = null;
    this.job_end = null;
    this.weight_ton = null;
    this.rate_product_ton_hour = 0;
    this.rate_ltr_ton = null;
    this.fuel_used = null;
    this.fuel_actual_use = null;
    this.fuel_stop_time = null;
    this.fuel_heave_machine = null;
    this.lift_up_down_hour = 0;
    this.lift_up_down_minutes = 0;
    this.lift_up_down_fuel = 0;
    this.machine_fail_hour = 0;
    this.machine_fail_minutes = 0;
    this.fuel_machine_fail = 0;
    this.refuel_hour = 0;
    this.refuel_minutes = 0;
    this.refuel_ltr = 0;
    this.move_barge_hour = 0;
    this.move_barge_minutes = 0;
    this.move_barge_fuel = 0;
    this.wait_pontoon_hour = 0;
    this.wait_pontoon_minutes = 0;
    this.wait_pontoon_ltr = 0;
    this.stop_rain_hour = 0;
    this.stop_rain_minutes = 0;
    this.stop_rain_fuel = 0;
    this.other_hour = 0;
    this.other_minutes = 0;
    this.other_ltr = 0;
    this.sum_working_hours = 0;
    this.sum_hours_stop_work = 0;
  }
}
