<breadcrumb></breadcrumb>
<mat-card lass="mat-elevation-z8">
  <div [fxFlex.gt-sm]="100" [fxFlex.gt-xs]="100" fxFlex="100" class="dt-mt-col">
    <mat-form-field>
      <mat-icon matPrefix mat-raised-button>search</mat-icon>
      <input
        matInput
        type="search"
        (keyup)="search($event.target.value)"
        [(ngModel)]="searchTextField"
        type="text"
        ngModel
        name="searchParam"
        placeholder="{{ 'Search' | ioxTranslate }}"
        aria-label="Search..."
      />
    </mat-form-field>
  </div>
</mat-card>

<mat-card class="mat-elevation-z8">
  <mat-card-content>
    <div fxLayout="row wrap">
      <div [fxFlex.gt-sm]="100" [fxFlex.gt-xs]="100" fxFlex="100">
        <table #table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="aType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "ATYPE" | ioxTranslate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <strong> {{ element.aType }} </strong>
            </td>
          </ng-container>
          <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "MESSAGE" | ioxTranslate }}
            </th>
            <td mat-cell *matCellDef="let element" class="p-t-16 p-b-16">
              {{ element.message }}
            </td>
          </ng-container>

          <ng-container matColumnDef="savedAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "TIME" | ioxTranslate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.savedAt | date: "short" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="sensorId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "SENSOR ID" | ioxTranslate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.sensorId }}</td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            class="mat-row-alarm"
            *matRowDef="let row; columns: displayedColumns"
            [ngClass]="{ selected: selection.isSelected(row) }"
            (click)="handleSelect(row)"
          >
            <!-- [class.make-red]="row.aType=='ERROR'" -->
          </tr>
        </table>
      </div>
      <div
        [fxFlex.gt-sm]="100"
        [fxFlex.gt-xs]="100"
        fxFlex="100"
        class="dt-mt-col"
      >
        <mat-paginator
          #paginator
          [pageSizeOptions]="[5, 20, 30, 50]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </div>
  </mat-card-content>
</mat-card>
