export class GfNewPanel {
  title: string;
  panelTitle: string;
  graphType: string;
  measurement: string;
  params: string;
  sensorId: string;
  uid: string;
  version: number;

  constructor() {
    this.title = null;
    this.panelTitle = null;
    this.graphType = '';
    this.measurement = '';
    this.params = '';
    this.sensorId = null;
    this.uid = '';
    this.version = 0;
  }
}
