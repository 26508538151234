import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-live-clock',
  templateUrl: './live-clock.component.html',
  styleUrls: ['./live-clock.component.scss'],
})
export class LiveClockComponent implements OnInit, OnDestroy {
  time = new Date();
  rxTime = new Date();
  intervalId;
  subscription: Subscription;
  constructor(private changeDetector: ChangeDetectorRef) {}
  ngOnInit() {
    // Using Basic Interval
    this.intervalId = setInterval(() => {
      this.changeDetector.detectChanges();
      this.time = new Date();
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
