import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Product } from 'src/app/models/api/iox-admin/products';
import { IoxAdminService } from 'src/app/services/api/iox-admin.service';
import { AxUtils } from 'src/app/shared/utils/axUtils';

@Component({
  selector: 'app-daily-jobs',
  templateUrl: './daily-jobs.component.html',
  styleUrls: ['./daily-jobs.component.scss'],
})
export class DailyJobsComponent implements OnInit {
  public dataSource: MatTableDataSource<Product>;
  public searchTextField = '';
  @ViewChild('settingsIcon', { static: false })
  public settingsIconRef: ElementRef;
  displayedColumnsObj = [
    { value: 'ship_name', label: 'Ship Name', show: true },
    { value: 'product.name_th', label: 'Product Name', show: true },
    { value: 'asset.name', label: 'Asset Name', show: true },
    { value: 'job_start', label: 'Job Start', show: true },
    { value: 'job_end', label: 'Job End', show: true },
    { value: 'weight_ton', label: 'Weight (Ton)', show: true },
    { value: 'fuel_used', label: 'Fuel Used', show: true },
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(private adminService: IoxAdminService, private route: Router) {}

  ngOnInit(): void {
    this.getAllDevices();
  }

  get displayedColumns(): string[] {
    return this.displayedColumnsObj
      .filter((element) => {
        return element.show == true;
      })
      .map((element) => {
        return element.value;
      });
  }

  getValue(element: any, col: string): any {
    const properties = col.split('.');
    let value = element;
    for (const property of properties) {
      if (value === null || value === undefined) {
        return null;
      }
      value = value[property];
    }
    return value;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      return this.filterNestedObject(data, filter);
    };
    this.dataSource.filter = filterValue;
  }

  filterNestedObject(data: any, filter: string): boolean {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        if (typeof value === 'object' && value !== null) {
          // Recursively search in nested object properties
          if (this.filterNestedObject(value, filter)) {
            return true;
          }
        } else {
          // Perform the filter check on non-nested properties
          if (value && value.toString().toLowerCase().includes(filter)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  getAllDevices() {
    this.adminService.getDailyJobs().subscribe(
      (response) => {
        this.dataSource = new MatTableDataSource(response);
        this.dataSource.paginator = this.paginator;
      },
      (err: HttpErrorResponse) => AxUtils.handleServiceError(err)
    );
  }

  add() {
    this.route.navigate(['/master-data/daily-jobs/add']);
  }

  viewDetails(row) {
    this.route.navigate(['/master-data/daily-jobs/details/' + row['id']]);
  }
}
