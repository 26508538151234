import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FileUploadService } from 'src/app/services/api/file-upload.service';

@Component({
  selector: 'app-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.scss'],
})
export class UploadFileDialogComponent implements OnInit {
  public progress: any;
  public canBeClosed = true;
  public primaryButtonText = 'Upload';
  public showCancelButton = true;
  public uploading = false;
  public uploadSuccessful = false;
  public max = 100;
  public showWarning: boolean;
  public dynamic: number;
  public type: string;
  public showUploadButton = false;
  error = false;
  message = false;
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  files: File;
  constructor(private uploadService: FileUploadService) {}

  ngOnInit(): void {}

  incomingfile(event) {
    this.error = false;
    this.message = false;
    this.files = event.target.files[0];
  }

  upload() {
    this.uploading = true;
    const formData = new FormData();
    formData.append('file', this.files);
    this.uploadService.upload(formData).subscribe(
      (event: any) => {
        if (typeof event === 'object') {
        }
        this.message = true;
      },
      (error) => {
        this.error = true;
      }
    );
    this.uploading = false;
  }
}
