import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/api/user';
import { EnvService } from '../core/env.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private url = this.env.coreApi;
  private userUrl = this.url + '/User/AuthUser/';
  private createUserUrl = this.url + '/User/AuthUser/Admin';
  private deleteUserUrl = this.url + '/User/';

  constructor(private http: HttpClient, private env: EnvService) {}

  get() {
    return this.http.get<User[]>(this.userUrl);
  }

  delete(id: number) {
    return this.http.delete<boolean>(this.deleteUserUrl + '/' + id.toString());
  }

  update(user: User): Observable<User> {
    return this.http.put<User>(this.userUrl, user);
  }

  insert(user: User): Observable<User> {
    return this.http.post<User>(this.createUserUrl, user);
  }
}
