export class LinePanel {
  aliasColors: object;
  bars: boolean;
  dashLength: number;
  dashes: boolean;
  datasource: string;
  fieldConfig: object;
  fill: number;
  fillGradient: number;
  gridPos: object;
  hiddenSeries: boolean;
  id: number;
  legend: object;
  lines: boolean;
  linewidth: number;
  nullPointMode: string;
  options: object;
  percentage: boolean;
  pluginVersion: string;
  pointradius: number;
  points: boolean;
  renderer: string;
  seriesOverrides: Array<string>;
  spaceLength: number;
  stack: boolean;
  steppedLine: boolean;
  targets: Array<object>;
  thresholds: Array<string>;
  timeFrom: string;
  timeRegions: Array<string>;
  timeShift: string;
  title: string;
  tooltip: object;
  type: string;
  xaxis: object;
  yaxes: Array<object>;
  yaxis: object;

  constructor() {
    this.aliasColors = {};
    this.bars = false;
    this.dashLength = 10;
    this.dashes = false;
    this.datasource = null;
    this.fieldConfig = {
      defaults: {},
      overrides: [],
    };
    this.fill = 1;
    this.fillGradient = 0;
    this.gridPos = {
      h: 11,
      w: 12,
      x: 0,
      y: 0,
    };
    this.hiddenSeries = false;
    this.id = 1;
    this.legend = {
      avg: false,
      current: false,
      max: false,
      min: false,
      show: true,
      total: false,
      values: false,
    };
    this.lines = true;
    this.linewidth = 1;
    this.nullPointMode = 'null';
    this.options = {
      alertThreshold: true,
    };
    this.percentage = false;
    this.pluginVersion = '7.5.4';
    this.pointradius = 2;
    this.points = false;
    this.renderer = 'flot';
    this.seriesOverrides = [];
    this.spaceLength = 10;
    this.stack = false;
    this.steppedLine = false;
    this.targets = [
      {
        groupBy: [
          {
            params: ['5m'],
            type: 'time',
          },
          {
            params: ['null'],
            type: 'fill',
          },
        ],
        measurement: 'ACER_PM',
        orderByTime: 'ASC',
        policy: 'default',
        refId: 'A',
        resultFormat: 'time_series',
        select: [
          [
            {
              params: ['Temperature'],
              type: 'field',
            },
            {
              params: [],
              type: 'mean',
            },
          ],
        ],
        tags: [
          {
            key: 'sensorId',
            operator: '=',
            value: '',
          },
        ],
      },
    ];

    this.thresholds = [];
    this.timeFrom = null;
    this.timeRegions = [];
    this.timeShift = null;
    this.title = '';
    this.tooltip = {
      shared: true,
      sort: 0,
      value_type: 'individual',
    };
    this.type = 'graph';
    this.xaxis = {
      buckets: null,
      mode: 'time',
      name: null,
      show: true,
      values: [],
    };
    this.yaxes = [
      {
        format: 'short',
        label: null,
        logBase: 1,
        max: null,
        min: null,
        show: true,
      },
      {
        format: 'short',
        label: null,
        logBase: 1,
        max: null,
        min: null,
        show: true,
      },
    ];
    this.yaxis = {
      align: false,
      alignLevel: null,
    };
  }
}
