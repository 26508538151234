import { Component, Input } from '@angular/core';
import { LocalStorageService } from 'src/app/services/core/authentication/storage.service';
import { SettingsService } from 'src/app/services/themes/settings.service';

@Component({
  selector: 'app-branding',
  template: `
    <a class="dt-mt-branding" href="/">
      <img
        *ngIf="!isDark"
        src="{{ logoUrl }}"
        width="50"
        height="50"
        class="dt-mt-branding-logo-expanded"
        alt="logo"
      />
      <img
        *ngIf="isDark"
        src="{{ darkLogoUrl }}"
        width="100"
        height="100"
        class="dt-mt-branding-logo-expanded"
        alt="logo"
      />
    </a>
  `,
})
export class BrandingComponent {
  @Input() isDark: boolean;
  logoUrl: string;
  darkLogoUrl: string;
  constructor(private store: LocalStorageService) {
    this.logoUrl = this.store.get('logo_url');
    this.darkLogoUrl = this.store.get('dark_logo_url');
  }
}
