import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { NgProgressModule } from 'ngx-progressbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslatePipe } from './pipes/translate.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { TgLocalTimePipe } from './pipes/tg-local-time.pipe';
import { TgGenericTimePipe } from './pipes/tg-generic-time.pipe';
import { SensorClazzPipe } from './pipes/sensor-class.pipe';
import { GenericDetailViewerComponent } from './components/generic-detail-viewer/generic-detail-viewer.component';
import { CruditorComponent } from './components/cruditor/cruditor.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
// EDIT: add new component
import { CruditorFormComponent } from './components/cruditor-form/cruditor-form.component';
import { IoxTranslatePipe } from './pipes/iox-translate.pipe';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { UploadFileDialogComponent } from './components/upload-file-dialog/upload-file-dialog.component';
import { GoogleMapComponent } from './components/google-map/google-map.component';
import { AgmCoreModule } from '@agm/core';
import { ErrorCodeComponent } from './components/error-code/error-code.component';
import { ToastrModule } from 'ngx-toastr';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { AutocompleteTypeComponent } from './components/autocomplete-type/autocomplete-type.component';
import { FormlyWrapperAddons } from './components/cruditor-form/addons.wrapper';
import { addonsExtension } from './components/cruditor-form/addons.extension';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FormlyDatetimepickerComponent } from './components/datetimepicker-type/datetimepicker-type.component';
import {
  MatDatetimepickerModule,
  MatNativeDatetimeModule,
} from '@mat-datetimepicker/core';
import { ConfirmationInputComponent } from './components/confirmation-input/confirmation-input.component';
const MODULES = [MaterialModule, FlexLayoutModule, NgProgressModule];

export function getApiKey(): string {
  const apiKeyFromStore = localStorage.getItem('GoogleMapsApiKey');
  const browserWindow = window || {};
  const browserWindowEnv = browserWindow['__env'] || {};
  let apiKey = 'unconfigured';
  if (apiKeyFromStore != null) {
    apiKey = apiKeyFromStore;
  } else {
    if (browserWindowEnv.hasOwnProperty('GoogleMapsApiKey')) {
      apiKey = window['__env']['GoogleMapsApiKey'];
    }
  }
  return apiKey;
}

@NgModule({
  declarations: [
    ConfirmationInputComponent,
    BreadcrumbComponent,
    TranslatePipe,
    SensorClazzPipe,
    SafePipe,
    TgLocalTimePipe,
    TgGenericTimePipe,
    GenericDetailViewerComponent,
    CruditorComponent,
    ConfirmationComponent,
    CruditorFormComponent,
    UploadFileDialogComponent,
    GoogleMapComponent,
    ErrorCodeComponent,
    FormlyWrapperAddons,
    AutocompleteTypeComponent,
    IoxTranslatePipe,
    FormlyDatetimepickerComponent,
  ],

  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ToastrModule,
    ReactiveFormsModule,
    FormlyMatDatepickerModule,
    MatNativeDateModule,
    MatDatetimepickerModule,
    MatNativeDateModule,
    MatNativeDatetimeModule,
    FormlyModule.forRoot({
      types: [
        {
          name: 'autocomplete',
          component: AutocompleteTypeComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'datetimepicker',
          component: FormlyDatetimepickerComponent,
          wrappers: ['form-field'],
        },
      ],
      wrappers: [{ name: 'addons', component: FormlyWrapperAddons }],
      extensions: [
        { name: 'addons', extension: { onPopulate: addonsExtension } },
      ],
      extras: { lazyRender: true },
    }),
    FormlyMaterialModule,

    CodemirrorModule,
    AgmCoreModule.forRoot({
      apiKey: getApiKey(),
      libraries: ['places'],
    }),
    ...MODULES,
  ],
  exports: [
    BreadcrumbComponent,
    ErrorCodeComponent,
    CommonModule,
    FormsModule,
    RouterModule,
    TranslatePipe,
    SafePipe,
    SensorClazzPipe,
    ReactiveFormsModule,
    FormlyModule,
    FormlyMaterialModule,
    CruditorComponent,
    CruditorFormComponent,
    GoogleMapComponent,
    AgmCoreModule,
    CodemirrorModule,
    IoxTranslatePipe,
    MatNativeDateModule,
    MatNativeDatetimeModule,
    ConfirmationInputComponent,
    ...MODULES,
  ],
})
export class SharedModule {}
