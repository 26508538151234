<!-- <div [fxFlex.gt-sm]="20" [fxFlex.gt-xs]="20" fxFlex="20" class="dt-mt-col"> -->
<mat-form-field class="full">
  <mat-icon matPrefix>search</mat-icon>
  <input
    matInput
    #search
    type="search"
    (keydown.enter)="$event.preventDefault()"
    placeholder="{{ 'Enter address' | ioxTranslate }}"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="off"
    type="text"
  />
</mat-form-field>
<agm-map
  [latitude]="location.latitude"
  [longitude]="location.longitude"
  [zoom]="zoom"
  [scrollwheel]="scrollwheel"
>
  <agm-marker
    [latitude]="location.latitude"
    [longitude]="location.longitude"
    [markerDraggable]="true"
    (dragEnd)="markerDragEnd($event)"
  ></agm-marker>
</agm-map>

<div fxLayout="row" fxLayout="row wrap" class="dt-mt-row m-t-8">
  <mat-form-field fxFlex="50" class="dt-mt-col m-r-8">
    <mat-label>{{ "Name/Other Details" | ioxTranslate }}</mat-label>
    <input matInput [(ngModel)]="location.details" />
  </mat-form-field>
  <mat-form-field fxFlex="20" class="dt-mt-col m-r-8">
    <mat-label>{{ "Latitude" | ioxTranslate }}</mat-label>
    <input matInput [(ngModel)]="location.latitude" />
  </mat-form-field>
  <mat-form-field fxFlex="20" class="dt-mt-col m-r-8">
    <mat-label>{{ "Longitude" | ioxTranslate }}</mat-label>
    <input matInput [(ngModel)]="location.longitude" />
  </mat-form-field>
  <mat-form-field class="dt-mt-col m-r-8" fxFlex="100">
    <mat-label>{{ "Address" | ioxTranslate }}</mat-label>
    <input matInput [(ngModel)]="location.address" />
  </mat-form-field>
</div>

<button
  *ngIf="!isNewEntity"
  mat-raised-button
  color="primary"
  (click)="update()"
>
  {{ "Update Location" | ioxTranslate }}
</button>
