import {
  Component,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Category } from 'src/app/models/api/category';
import { Sensor } from 'src/app/models/api/sensor';
import { SensorService } from 'src/app/services/api/sensor.service';
@Component({
  selector: 'app-categories-device',
  templateUrl: './categories-device.component.html',
  styleUrls: ['./categories-device.component.scss'],
})
export class CategoriesDeviceComponent implements OnInit, OnChanges {
  @Input() sensor: Sensor;
  @Input() isNewEntity: boolean;
  @Output() newSensorCategoriesDetails = new EventEmitter<Sensor>();
  @Output() formValid = new EventEmitter<boolean>();
  displayedColumns: string[] = ['categoryName', 'categoryValue', 'action'];
  catForm: FormGroup;
  isEditableNew: boolean = true;
  public categories: Category;
  public newCategory: boolean = false;
  @ViewChild('table') table: MatTable<any>;
  public rows = [];
  dataSource = new MatTableDataSource<any>();
  public columns = [
    { prop: 'categoryName', name: 'Category' },
    { prop: 'categoryValue', name: 'Value' },
  ];
  public categoryName = '';
  public categoryValue = '';
  constructor(
    public sensorService: SensorService,
    private fb: FormBuilder,
    private _formBuilder: FormBuilder,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.sensor) {
      const temp = [];
      Object.keys(this.sensor.categories).forEach((category) => {
        const categoryValue = this.sensor.categories[category];
        temp.push({
          categoryName: category,
          categoryValue: categoryValue,
        });
      });
      this.rows = [...temp];
    }
    this.catForm = this._formBuilder.group({
      catRows: this._formBuilder.array([]),
    });
    this.dataToTable();
  }

  dataToTable() {
    this.catForm = this.fb.group({
      catRows: this.fb.array(
        this.rows.map((val) =>
          this.fb.group({
            categoryName: new FormControl(val.categoryName),
            categoryValue: new FormControl(val.categoryValue),
            action: new FormControl('existingRecord'),
            isEditable: new FormControl(true),
            isNewRow: new FormControl(false),
          })
        )
      ), //end of fb array
    }); // end of form group cretation
    this.dataSource = new MatTableDataSource(
      (this.catForm.get('catRows') as FormArray).controls
    );
  }

  addNewCategory() {
    this.newCategory = true;
  }

  handleUnsert() {
    if (this.categoryName.trim() != '') {
      this.rows.push({
        categoryName: this.categoryName,
        categoryValue: this.categoryValue,
      });
      this.dataToTable();
      if (this.table) {
        this.table.renderRows();
      }
      this.new();
    }
  }

  new() {
    this.categoryName = '';
    this.categoryValue = '';
  }

  delete(id) {
    this.rows.splice(id, 1);
    this.dataSource.data.splice(id, 1);
    this.table.renderRows();
  }

  close() {
    this.newCategory = false;
  }

  insert() {
    const temp: Category = {};
    this.rows.map((category) => {
      temp[category.categoryName] = category.categoryValue;
    });
    this.categories = { ...temp };
    this.sensor.categories = this.categories;
    this.newSensorCategoriesDetails.emit(this.sensor);
    // this.formValid.emit(this.form.valid);
  }

  edit(catFormElement, i) {
    catFormElement.get('catRows').at(i).get('isEditable').patchValue(false);
  }

  save(catFormElement, i) {
    catFormElement.get('catRows').at(i).get('isEditable').patchValue(true);
    this.rows[i]['categoryName'] = this.dataSource.data[i].value.categoryName;
    this.rows[i]['categoryValue'] = this.dataSource.data[i].value.categoryValue;
  }

  cancel(catFormElement, i) {
    catFormElement.get('catRows').at(i).get('isEditable').patchValue(true);
  }

  update(): void {
    const temp: Category = {};
    this.rows.map((category) => {
      temp[category.categoryName] = category.categoryValue;
    });
    this.categories = { ...temp };
    this.sensor.categories = this.categories;
    this.sensorService.update(this.sensor).subscribe(
      (newEntity) => {
        this.snackBar.open(
          "Updated Sensor's Categories Successfully",
          'Dismiss'
        );
      },
      (error) => {
        this.snackBar.open(
          "Updated Sensor's Categories Failed due to Error Code: " +
            error.error.code +
            ' ' +
            error.error.message,
          'Dismiss'
        );
      }
    );
  } // end of updateRecord
}
