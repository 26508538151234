import { Device } from './device';
import { Category } from './category';
import { Location } from './location';

/**
 * Generic Class for a  sensor
 *
 * @author AMDIS Media and IT Services, AIT
 *
 * @date 19.1.2018, 23.1.2021
 */
interface ProcessorFunc {
  (key: string, value: any): any;
}

export interface FieldProcessor {
  fieldKey: string;
  processor: ProcessorFunc;
}

export interface SensorValueEntry {
  propName: string;
  propValue: string | number;
}

export class Sensor extends Device {
  public maxValue: number;
  public minValue: number;
  public valueUnit: string;
  public resolution: number;
  public samplingRate: number;
  public smartScript: string;
  public smartScriptLayer: string;
  public smartExpression: string;
  public smartExpressionLayer: string;
  public lag: number;
  public timeZone: string;
  public resolutionUnit: string;
  public samplingRateUnit: string;
  public lagUnit: string;
  public inCompoundSensor: boolean;
  public categories: Category;
  public sensorProperties: SensorValueEntry[];

  constructor() {
    super();
    this.clazz = 'Sensor';
    this.name = '';
    this.location = '';
    this.maxValue = 0;
    this.minValue = 0;
    this.valueUnit = '';
    this.resolution = 0;
    this.samplingRate = 0;
    this.smartScript = '';
    this.smartExpression = '';
    this.lag = 0;
    this.resolutionUnit = '';
    this.samplingRateUnit = 'minutes';
    this.lagUnit = '';
    this.timeZone = 'Z';
    this.inCompoundSensor = false;
    this.categories = {};
    this.sensorProperties = [];
  }

  public getMaxValue(): number {
    return this.maxValue;
  }

  public setMaxValue(maxValue: number): void {
    this.maxValue = maxValue;
  }

  public getMinValue(): number {
    return this.minValue;
  }

  public setMinValue(minValue: number): void {
    this.minValue = minValue;
  }

  public getValueUnit(): string {
    return this.valueUnit;
  }

  public setValueUnit(valueUnit: string): void {
    this.valueUnit = valueUnit;
  }

  public getResolution(): number {
    return this.resolution;
  }

  public setResolution(resolution: number): void {
    this.resolution = resolution;
  }

  public getSamplingRate(): number {
    return this.samplingRate;
  }

  public setSamplingRate(samplingRate: number): void {
    this.samplingRate = samplingRate;
  }

  public getLag(): number {
    return this.lag;
  }

  public setLag(lag: number): void {
    this.lag = lag;
  }

  public getResolutionUnit(): string {
    return this.resolutionUnit;
  }

  public setResolutionUnit(resolutionUnit: string): void {
    this.resolutionUnit = resolutionUnit;
  }

  public getSamplingRateUnit(): string {
    return this.samplingRateUnit;
  }

  public setSamplingRateUnit(samplingRateUnit: string): void {
    this.samplingRateUnit = samplingRateUnit;
  }

  public getLagUnit(): string {
    return this.lagUnit;
  }

  public setLagUnit(lagUnit: string): void {
    this.lagUnit = lagUnit;
  }

  public getTimeZone(): string {
    return this.timeZone;
  }

  public getInCompoundSensor(): boolean {
    return this.inCompoundSensor;
  }

  public setInCompoundSensor(inCompoundSensor: boolean) {
    this.inCompoundSensor = inCompoundSensor;
  }

  public setTimeZone(timeZone: string): void {
    this.timeZone = timeZone;
  }
}
