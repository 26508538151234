<button class="mat-icon-button close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h4 mat-dialog-title><strong>Add New User</strong></h4>
<mat-dialog-content>
  <!-- <lib-cruditor-form *ngIf="newUser" [dataService]="userService" [config]="config" [data]="newUser"
        [isNewEntity]="isNewEntity" [saveInForm]="saveInForm">
    </lib-cruditor-form> -->
  <app-cruditor-form
    *ngIf="newUser"
    [dataService]="userService"
    [config]="config"
    [data]="newUser"
    [isNewEntity]="isNewEntity"
    [saveInForm]="saveInForm"
  >
  </app-cruditor-form>
</mat-dialog-content>
