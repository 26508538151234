import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TalegurDataService } from './talegur-data.service';
import { CruditorConfiguration } from './cruditor.configuration';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, MatSortable } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';

@Component({
  selector: 'app-cruditor',
  templateUrl: './cruditor.component.html',
  styleUrls: ['./cruditor.component.scss'],
})
export class CruditorComponent implements OnInit {
  @Input() dataService: TalegurDataService;
  @Input() config: CruditorConfiguration;
  private dataRecords: any[] = null;
  public form = new FormGroup({});
  public isLoading = true;
  public dataSource: any;
  public newRecord: any;
  public columnsToDisplay = [];
  public activeEntity;
  public isNewEntity = true;

  selection = new SelectionModel<any>(false, null);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.newRecord = { ...this.config.newRecord };
    this.activeEntity = this.newRecord;
    if (this.dataService != null) {
      this.getRecords();
    }
  }

  buildColumnsArray(record: any) {
    this.columnsToDisplay = [];

    for (var key in record) {
      if (record.hasOwnProperty(key) && this.shouldShow(key)) {
        this.columnsToDisplay.push(key);
      }
    }
    this.columnsToDisplay.push('actions');
  }

  shouldShow(field: string): Boolean {
    const noshowFields = this.config.noShowFields;
    const isBadField = noshowFields.includes(field);
    return !isBadField;
  }

  getRecords() {
    this.dataService.get().subscribe((records) => {
      this.isLoading = false;
      if (records.length > 0) {
        this.buildColumnsArray(records[0]);
      }
      this.dataRecords = records;
      this.dataSource = new MatTableDataSource(records);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        if (this.sort) {
          this.sort.sort(this.config.sortField);
        }
      }, 10);
    });
  }

  new() {
    this.activeEntity = { ...this.newRecord };
    this.isNewEntity = true;
  }

  edit(row) {
    this.activeEntity = { ...this.newRecord };
    this.isNewEntity = false;
  }

  delete(row) {
    const confirmDialog = this.dialog.open(ConfirmationComponent, {
      data: {
        title: 'Confirm Deletion',
        message: 'Are you sure, you want to delete: ' + row.name,
      },
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.dataService.delete(row).subscribe((response) => {
          if (response == null) {
            this.dataRecords = this.dataRecords.filter((x) => x.id != row.id);
            this.dataSource.data = this.dataRecords;
            this.activeEntity = { ...this.newRecord };
            this.isNewEntity = true;
            this.snackBar.open(this.config.deleteMessage, 'Dismiss');
          }
        });
      }
    });
  }

  handleSelect(row) {
    this.selection.select(row);
    this.isNewEntity = false;
    this.activeEntity = { ...row };
  }

  onSubmit(entity) {
    if (!this.activeEntity) {
      const confirmDialog = this.dialog.open(ConfirmationComponent, {
        data: {
          title: 'Exception Alert',
          message:
            'Unforseen Error. Please report this error. Code: E-SPIAPP2-001',
        },
      });
      return;
    }
    if (this.isNewEntity) {
      this.insertRecord(entity);
    } else {
      this.updateRecord(entity);
    }
    ``;
  }

  insertRecord(entity: any): void {
    this.dataService.insert(entity).subscribe((newEntity) => {
      this.dataRecords.push(newEntity);
      this.dataSource.data = this.dataRecords;
      this.snackBar.open(this.config.insertMessage, 'Dismiss');
    });
  } // end of insertRecord

  updateRecord(entity: any): void {
    this.dataService.update(entity).subscribe((newEntity) => {
      const foundIndex = this.dataRecords.findIndex(
        (x) => x.id == newEntity['id']
      );
      this.dataRecords[foundIndex] = newEntity;
      this.dataSource.data = this.dataRecords;
      this.snackBar.open(this.config.updateMessage, 'Dismiss');
    });
  } // end of updateRecord
}
