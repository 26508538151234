import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventEmitter } from 'events';
import { Sensor } from 'src/app/models/api/sensor';
import { SensorService } from 'src/app/services/api/sensor.service';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import * as CodeMirror from 'codemirror';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-script-device',
  templateUrl: './script-device.component.html',
  styleUrls: ['./script-device.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScriptDeviceComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('scriptEditor') private scriptEditor;
  @ViewChild('expressionEditor') private expressionEditor;
  public options = {
    // lineNumbers: true,
    lineWrapping: true,
    theme: 'material',
    mode: 'javascript',
    autoRefresh: true,
  };

  @Input() sensor: Sensor;
  @Output() unsertDevice = new EventEmitter();
  public layers = [
    { label: 'NONE', value: null },
    { label: 'CLOUD', value: 'CLOUD' },
    { label: 'EDGE', value: 'EDGE' },
    { label: 'WEB', value: 'WEB' },
    { label: 'DEVICE', value: 'DEVICE' },
  ];
  constructor(
    public sensorService: SensorService,
    private snackBar: MatSnackBar,
    private domSanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.sensor) {
      this.getEditor();
    }
  }

  ngOnChanges(simplechange: SimpleChanges) {
    this.cdr.detectChanges();
    if (this.sensor) {
      this.getEditor();
    }
  }

  handleChange($event: Event): void {}

  ngAfterViewInit() {
    if (this.sensor) {
      this.getEditor();
    }
  }

  getEditor() {
    const scriptEditor = this.scriptEditor.codeMirror;
    const expressionEditor = this.expressionEditor.codeMirror;
    setTimeout(function () {
      scriptEditor.refresh();
      expressionEditor.refresh();
    }, 1);
  }

  update(expressionLayer, expressionEditor, scriptLayer, scriptEditor) {
    this.sensor.smartExpression = expressionEditor;
    this.sensor.smartExpressionLayer = expressionLayer;
    this.sensor.smartScript = scriptEditor;
    this.sensor.smartScriptLayer = scriptLayer;
    this.updateRecord(this.sensor);
  }

  updateRecord(sensor: Sensor): void {
    this.sensorService.update(sensor).subscribe(
      (newEntity) => {
        this.snackBar.open("Updated Sensor's Script Successfully", 'Dismiss');
      },
      (error) => {
        this.snackBar.open(
          "Updated Sensor's Script Failed due to Error Code: " +
            error.error.code +
            ' ' +
            error.error.message,
          'Dismiss'
        );
      }
    );
  } // end of updateRecord
}
