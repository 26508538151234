import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { AssetItem } from 'src/app/models/api/iox-admin/asset-item';
import { IoxAdminService } from 'src/app/services/api/iox-admin.service';
import { CruditorFormConfiguration } from 'src/app/shared/components/cruditor-form/cruditor-form.configuration';
import { AxUtils } from 'src/app/shared/utils/axUtils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Sensor } from 'src/app/models/api/sensor';
import { LocalStorageService } from 'src/app/services/core/authentication/storage.service';
import { IoxTranslatePipe } from 'src/app/shared/pipes/iox-translate.pipe';

@Component({
  selector: 'app-machinery-edit',
  templateUrl: './machinery-edit.component.html',
  styleUrls: ['./machinery-edit.component.scss'],
})
export class MachineryEditComponent implements OnInit, OnDestroy {
  form = new FormGroup({});
  assetTypes = [];
  assets = [];
  public basicForm = new FormGroup({});
  public saveInForm = true;
  public isNewEntity = true;
  public item: AssetItem;
  id: Number;
  public config: CruditorFormConfiguration = {
    detailsTitle: 'AssetItem',
    insertMessage: this.translatePipe.transform('Machine Successfully Added'),
    updateMessage: this.translatePipe.transform('Machine Successfully Updated'),
    deleteMessage: this.translatePipe.transform('Machine Successfully Deleted'),
    fields: [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'name',
            templateOptions: {
              label: this.translatePipe.transform('Name'),
              required: true,
            },
          },
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'model',
            templateOptions: {
              label: this.translatePipe.transform('Model'),
            },
          },
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'lat',
            templateOptions: {
              label: this.translatePipe.transform('Lat'),
            },
          },
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'lng',
            templateOptions: {
              label: this.translatePipe.transform('Lng'),
            },
          },
          {
            className: 'col-sm-6',
            type: 'select',
            key: 'asset_type_id',
            templateOptions: {
              label: this.translatePipe.transform('Type'),
              required: true,
              labelProp: 'name',
              valueProp: 'id',
            },
            expressionProperties: {
              'templateOptions.options': () => this.assetTypes,
            },
          },
          {
            className: 'col-sm-6',
            type: 'select',
            key: 'assets_id',
            templateOptions: {
              label: this.translatePipe.transform('Floating Barge'),
              required: true,
              labelProp: 'name',
              valueProp: 'id',
            },
            expressionProperties: {
              'templateOptions.options': () => this.assets,
            },
          },
        ],
      },
    ],
  };
  private destroy$ = new Subject<void>();
  newSensor: Number;
  filteredSensors: string[];
  sensors = [];
  allSensors: Array<string> = [];
  unAssignedSensors: Array<string> = [];
  assignedSensors: Array<String> = [];
  constructor(
    private service: IoxAdminService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private routePath: Router,
    private route: ActivatedRoute,
    private store: LocalStorageService,
    private translatePipe: IoxTranslatePipe
  ) {}

  ngOnInit(): void {
    this.basicForm = this.formBuilder.group({});
    forkJoin({
      assetTypes: this.service.getAssetTypes(),
      floatingBarges: this.service.getAssets(),
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.assetTypes = response.assetTypes;
          this.assets = response.floatingBarges;
        },
        (err: HttpErrorResponse) => AxUtils.handleServiceError(err)
      );
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.getAssetItemDetails();
    this.getListOfSensors();
    // this.getPatientSensors();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getAssetItemDetails() {
    this.service.getAssetItemById(this.id).subscribe(
      (response) => {
        this.item = response;
        // this.lat = Number(this.item.lat);
        // this.lng = Number(this.item.lng);
      },
      (err: HttpErrorResponse) => AxUtils.handleServiceError(err)
    );
  }

  async save() {
    if (this.basicForm.valid) {
      try {
        delete this.item.asset_type;
        delete this.item.assets;
        this.item.sensors = this.sensors;
        const newEntity = await this.service
          .editAssetItem(this.item)
          .toPromise();
        this.navigateToMachinery();
        this.showSnackbar(
          this.translatePipe.transform(this.config.updateMessage)
        );
      } catch (error) {
        const { code, message } = error;
        this.showSnackbar(
          `Edit Machine Failed due to Error Code: ${code} ${message}`
        );
      }
    }
  }

  removeAddedSensor(a_id, s_id) {
    // have to send api to remove this sensor
    this.service.removeAddedSensorFromAssetItem(a_id, s_id).subscribe(
      (response) => {
        this.showSnackbar('Removed Sensor: ' + s_id + ' Successfully');
        const index = this.item.sensors.findIndex(
          (sensor) => sensor.sensor_id === s_id
        );
        if (index !== -1) {
          this.item.sensors.splice(index, 1);
        }
      },
      (err: HttpErrorResponse) => AxUtils.handleServiceError(err)
    );
  }

  navigateToMachinery() {
    this.routePath.navigate([`/master-data/machinery/details/${this.id}`]);
  }

  private showSnackbar(message: string) {
    this.snackBar.open(message, 'Dismiss');
  }

  findSensor() {
    this.filteredSensors = this._filterSensor(this.newSensor);
  }

  private _filterSensor(name: Number): string[] {
    return this.unAssignedSensors.filter((option) =>
      String(option).includes(String(name))
    );
  }

  remove(sensor): void {
    const index = this.sensors.indexOf(sensor);
    if (index >= 0) {
      this.sensors.splice(index, 1);
      this.snackBar.open(`${sensor} Deleted`, '', { duration: 2000 });
    }
  }

  getListOfSensors() {
    JSON.parse(this.store.get('sensors')).forEach((obj) => {
      this.allSensors.push(obj.id);
    });
    this.service.getAssetItemSensors().subscribe((res) => {
      res.forEach((obj) => {
        this.assignedSensors.push(obj.sensor_id);
      });
      this.getUnAssignedSensors();
    });
    this.getUnAssignedSensors();
  }

  getUnAssignedSensors() {
    this.unAssignedSensors = this.allSensors.filter(
      (number) => !this.assignedSensors.includes(number)
    );
  }

  addSensor(value) {
    this.sensors.push(value);
    this.snackBar.open(`${value} Added`, '', { duration: 2000 });
    this.newSensor = null;
  }
}
