import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GfDashboard } from 'src/app/models/api/grafana-dashboards/gf-dashboard';
import { EnvService } from '../core/env.service';

@Injectable({
  providedIn: 'root',
})
export class GrafanaService {
  private gfUrl = this.env.grafanaUrl;
  private headers: HttpHeaders;

  constructor(private env: EnvService, private http: HttpClient) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${this.env.GrafanaApiKey}`,
    });
  }

  getDashboard() {
    const url = this.gfUrl + '/api/search';
    return this.http.get<any>(url, { headers: this.headers });
  }

  createDasboard(dashboard: GfDashboard) {
    const url = this.gfUrl + '/api/dashboards/db';
    return this.http.post(url, dashboard, { headers: this.headers });
  }

  getDashboardByName(name: string) {
    const url = this.gfUrl + '/api/dashboards/uid/' + name;
    return this.http.get<any>(url, { headers: this.headers });
  }

  addPanelInDashboard(details: any) {
    const url = this.gfUrl + '/api/dashboards/db';
    return this.http.post(url, details, { headers: this.headers });
  }
}
