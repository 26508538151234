import { Injectable } from '@angular/core';
import { LocalStorageService } from '../core/authentication/storage.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppSettings, defaults } from '../../models/themes/settings';
import { EnvService } from '../core/env.service';

export const USER_KEY = 'usr';

export interface User {
  id: number;
  name?: string;
  email?: string;
  avatar?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  themeKey = 'unset';
  constructor(private store: LocalStorageService, private env: EnvService) {
    this.themeKey = env.tenant + '_theme';
  }

  private options = defaults;

  get notify(): Observable<any> {
    return this.notify$.asObservable();
  }
  private notify$ = new BehaviorSubject<any>({});

  setLayout(options?: AppSettings): AppSettings {
    this.options = Object.assign(defaults, options);
    return this.options;
  }

  setNavState(type: string, value: boolean) {
    this.notify$.next({ type, value } as any);
  }

  getOptions(): AppSettings {
    const theme = this.store.get(this.themeKey);
    this.options.theme =
      theme == 'light' || theme === 'dark' ? theme : this.options.theme;
    return this.options;
  }

  /** User information */

  // get user() {
  //   return this.store.get(USER_KEY);
  // }

  // setUser(value: User) {
  //   this.store.set(USER_KEY, value);
  // }

  // removeUser() {
  //   this.store.remove(USER_KEY);
  // }

  /** System language */

  get language() {
    return this.options.language;
  }

  setLanguage(lang: string) {
    this.options.language = lang;
    this.notify$.next({ lang });
  }

  changeTheme(theme: 'light' | 'dark') {
    this.store.set(this.themeKey, theme);
    this.options.theme = theme;
    this.notify$.next({ theme });
  }

  changeTenant(tenant: string) {
    this.store.set('tenant', tenant);
    this.notify$.next({ tenant });
  }

  changeMenuBar(navPos: 'top' | 'side', headerPos: 'above' | 'fixed') {
    this.store.set('navPos', navPos);
    this.store.set('headerPos', headerPos);
    // this.notify$.next({ navPos });
    // this.notify$.next({ headerPos });
  }
}
