<div class="dt-mt-container-wrap" [ngClass]="{
    'dt-mt-sidenav-collapsed':
      options.sidenavCollapsed && options.navPos !== 'top',
    'dt-mt-navbar-side': options.navPos === 'side',
    'dt-mt-navbar-top': options.navPos === 'top',
    'dt-mt-header-above': options.headerPos === 'above',
    'dt-mt-header-fixed': options.headerPos === 'fixed',
    'dt-mt-header-white': options.theme === 'light'
  }" [dir]="options.dir">
    <!-- <ng-progress></ng-progress> -->

    <app-loader></app-loader>
    <app-header *ngIf="options.showHeader && options.headerPos === 'above'" (toggleSidenav)="sidenav.toggle()"
        (toggleSidenavNotice)="sidenavNotice.toggle()" [isTop]="options.navPos === 'top'" [showBranding]="true"
        [isDark]="options.theme === 'dark'">
    </app-header>



    <mat-sidenav-container class="dt-mt-container" autosize autoFocus>
        <mat-sidenav #sidenav class="dt-mt-sidenav" [mode]="isOver ? 'over' : 'side'"
            [opened]="options.navPos === 'side' && options.sidenavOpened && !isOver"
            (openedChange)="sidenavOpenedChange($event)" (closedStart)="sidenavCloseStart()">
            <app-sidebar [showToggle]="!isOver" [showUser]="options.showUserPanel"
                [showHeader]="options.headerPos !== 'above'" (toggleCollapsed)="toggleCollapsed()"
                [toggleChecked]="options.sidenavCollapsed" [isDark]="options.theme === 'dark'">
            </app-sidebar>
        </mat-sidenav>

        <mat-sidenav #sidenavNotice position="end" mode="over">
            <app-sidebar-notice></app-sidebar-notice>
        </mat-sidenav>

        <mat-sidenav-content #content class="dt-mt-content-wrap">
            <app-header *ngIf="options.showHeader && options.headerPos !== 'above'"
                [showToggle]="!options.sidenavCollapsed && options.navPos !== 'top'" [isTop]="options.navPos === 'top'"
                [showBranding]="options.navPos === 'top'" [isDark]="options.theme === 'dark'"
                (toggleSidenav)="sidenav.toggle()" (toggleSidenavNotice)="sidenavNotice.toggle()">
            </app-header>


            <app-topmenu *ngIf="options.navPos === 'top'"></app-topmenu>

            <div class="dt-mt-content">

                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>

    </mat-sidenav-container>


</div>