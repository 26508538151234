<breadcrumb></breadcrumb>
<h4 mat-dialog-title>
  <strong>{{ "Add Job Plans" | ioxTranslate }}</strong>
</h4>
<div class="dt-mt-row" fxLayout="row wrap">
  <mat-card
    class="mat-elevation-z8"
    [fxFlex.gt-sm]="100"
    [fxFlex.gt-xs]="100"
    fxFlex="100"
  >
    <form [formGroup]="form">
      <div class="dt-mt-row">
        <mat-form-field>
          <mat-label>{{ "Machine Status" | ioxTranslate }}</mat-label>
          <mat-select formControlName="machineStatus">
            <mat-option *ngFor="let plan of plans" [value]="plan">{{
              plan | ioxTranslate
            }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls['machineStatus'].invalid">
            This field is required.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="dt-mt-row">
        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
          <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="25">
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>{{ "Machine Name" | ioxTranslate }}</mat-label>
              <input
                type="text"
                matInput
                formControlName="machineName"
                [matAutocomplete]="auto"
              />
              <mat-error *ngIf="form.controls['machineName'].invalid">
                This field is required.
              </mat-error>
              <mat-autocomplete
                #auto="matAutocomplete"
                [displayWith]="displayFn"
              >
                <mat-option
                  *ngFor="let option of filteredOptions | async"
                  [value]="option"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <mat-form-field appearance="fill">
            <mat-label>{{ "Data Timeframe" | ioxTranslate }}</mat-label>
            <input
              type="text"
              autocomplete="off"
              ngxDaterangepickerMd
              matInput
              [locale]="{ applyLabel: 'ok', format: 'DD-MM-YYYY' }"
              startKey="start"
              endKey="end"
              placeholder="Select a timeframe"
              formControlName="dateTimeframe"
              [timePicker]="true"
              [timePickerSeconds]="false"
              [timePickerIncrement]="15"
              [timePicker24Hour]="true"
              name="daterange"
              (change)="setDate($event)"
            />
            <mat-error *ngIf="form.controls['dateTimeframe'].invalid">
              This field is required.
            </mat-error>
          </mat-form-field>
          <br />
        </div>
      </div>
      <formly-form
        *ngIf="form.get('machineStatus').value === 'Work Stoppage'"
        [form]="stopForm"
        [fields]="config.fields"
        [model]="stopPlanDetails"
      >
      </formly-form>
      <mat-dialog-actions>
        <button mat-button routerLink="/master-data/machinery">
          {{ "Cancel" | ioxTranslate }}
        </button>
        <button
          mat-raised-button
          color="primary"
          type="submit"
          (click)="insert()"
        >
          {{ "Add Plan" | ioxTranslate }}
        </button>
      </mat-dialog-actions>
    </form>
  </mat-card>
</div>
