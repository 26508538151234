import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { TalegurDataService } from './talegur-data.service';
import { CruditorFormConfiguration } from './cruditor-form.configuration';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { Output, EventEmitter } from '@angular/core';
import { Sensor } from 'src/app/models/api/sensor';

@Component({
  selector: 'app-cruditor-form',
  templateUrl: './cruditor-form.component.html',
  styleUrls: ['./cruditor-form.component.scss'],
})
export class CruditorFormComponent implements OnInit, SimpleChange {
  @Input() dataService: TalegurDataService;
  @Input() config: CruditorFormConfiguration;
  @Input() isNewEntity: boolean;
  @Input() saveInForm: boolean;
  @Input() data: any;
  @Output() newEntityDetails = new EventEmitter<Sensor>();
  @Output() formValid = new EventEmitter<boolean>();
  previousValue: any;
  currentValue: any;
  firstChange: boolean;

  public form = new FormGroup({});
  public activeEntity = null;

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar) {}

  isFirstChange(): boolean {
    return true;
  }

  ngOnInit(): void {
    this.activeEntity = this.data;
  }

  ngOnChanges(...args: any[]) {
    this.activeEntity = this.data;
  }

  onSubmit(entity) {
    if (!this.activeEntity) {
      const confirmDialog = this.dialog.open(ConfirmationComponent, {
        data: {
          title: 'Exception Alert',
          message:
            'Unforseen Error. Please report this error. Code: E-TGAPP2-001',
        },
      });
      return;
    }
    if (this.isNewEntity) {
      this.insertRecord(entity);
    } else {
      this.updateRecord(entity);
    }
  }

  processDataBeforeSave() {
    if (this.config.detailsTitle == 'User') {
      this.activeEntity['password'] =
        this.activeEntity['newPassword']['newPassword'];
      delete this.activeEntity['newPassword'];
    }
  }

  insertRecord(entity: any): void {
    if (this.saveInForm) {
      if (this.form.valid) {
        this.activeEntity = entity;
        this.processDataBeforeSave();
        this.dataService.insert(entity).subscribe(
          (newEntity) => {
            this.snackBar.open(this.config.insertMessage, 'Dismiss');
          },
          (error) => {
            this.snackBar.open(
              'Add Failed due to Error Code: ' +
                error.error.code +
                ' ' +
                error.error.message,
              'Dismiss'
            );
          }
        );
        this.dialog.closeAll();
      }
    } else {
      this.newEntityDetails.emit(entity);
      this.formValid.emit(this.form.valid);
    }
  } // end of insertRecord

  updateRecord(entity: any): void {
    if (this.saveInForm) {
      if (this.form.valid) {
        this.activeEntity = entity;
        this.processDataBeforeSave();
        this.dataService.update(entity).subscribe(
          (newEntity) => {
            this.snackBar.open(this.config.updateMessage, 'Dismiss');
          },
          (error) => {
            // (error: HttpErrorResponse) => AxUtils.handleServiceError(error);
            this.snackBar.open(
              'Failed due to Error Code: ' +
                error.error.code +
                ' ' +
                error.error.message,
              'Dismiss'
            );
          }
        );
        this.dialog.closeAll();
      }
    } else {
      this.newEntityDetails.emit(entity);
      this.formValid.emit(this.form.valid);
    }
  } // end of updateRecord
}
