import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { i18nMetaToJSDoc } from '@angular/compiler/src/render3/view/i18n/meta';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
import * as screenfull from 'screenfull';
import { Company } from 'src/app/models/api/company';
import { TenantService } from 'src/app/services/api/tenant.service';
import { LocalStorageService } from 'src/app/services/core/authentication/storage.service';
import { EnvService } from 'src/app/services/core/env.service';
import { JwtService } from 'src/app/services/core/jwt.service';
import { SettingsService } from 'src/app/services/themes/settings.service';
import { AxUtils } from 'src/app/shared/utils/axUtils';
import { AdminLayoutComponent } from '../admin-layout/admin-layout.component';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from 'src/app/routes/users/change-password/change-password.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  public options = this.settings.getOptions();
  public server = '';
  public username = '';

  public tenants: Array<Company> = [];
  public selectedTenant = 'all';
  loadedAllData = false;
  @Input() showToggle = true;
  @Input() showBranding = false;
  @Input() isDark: boolean;
  @Input() isTop: boolean;
  @Output() toggleSidenav = new EventEmitter<void>();
  @Output() toggleSidenavNotice = new EventEmitter<void>();
  @Output() optionsChange = new EventEmitter<object>();

  private get screenfull(): screenfull.Screenfull {
    return screenfull as screenfull.Screenfull;
  }

  constructor(
    public env: EnvService,
    private settings: SettingsService,
    private router: Router,
    private layout: AdminLayoutComponent,
    private token: JwtService,
    private store: LocalStorageService,
    private tenantService: TenantService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.server = this.env.coreApi;
    this.username = this.store.get('username');
    this.getTenants();
    if (this.store.get('tenant')) {
      this.selectedTenant = this.store.get('tenant');
    } else {
      this.selectedTenant = 'all';
    }
  }

  toggleFullscreen() {
    if (this.screenfull.isEnabled) {
      this.screenfull.toggle();
    }
  }

  changePassword() {
    this.dialog.open(ChangePasswordComponent, {
      width: '400px',
      data: {
        username: this.username,
      },
    }); // end of dialog
  }
  toggleTheme() {
    this.isDark = !this.isDark;
    if (this.isDark) {
      this.options.theme = 'dark';
      this.layout.receiveOptions(this.options);
    } else {
      this.options.theme = 'light';
      this.layout.receiveOptions(this.options);
    }
    this.settings.changeTheme(this.options.theme);
  }

  toggleSideBar() {
    this.isTop = !this.isTop;
    if (this.isTop) {
      this.options.navPos = 'top';
      this.options.headerPos = 'above';
      this.layout.updateOptions(this.options);
    } else {
      this.options.navPos = 'side';
      this.options.headerPos = 'fixed';
      this.options.sidenavOpened = true;
      this.options.sidenavCollapsed = false;
      this.layout.updateOptions(this.options);
    }
    this.settings.changeMenuBar(this.options.navPos, this.options.headerPos);
  }

  logout() {
    this.token.logout();
    this.router.navigateByUrl('/auth/login');
  }

  getTenants() {
    this.tenantService.get().subscribe(
      (response) => {
        this.tenants = response.tenants;
        this.loadedAllData = true;
        this.cdr.detectChanges();
      },
      (err: HttpErrorResponse) => AxUtils.handleServiceError(err)
    );
  }

  selectedTenantChange() {
    this.settings.changeTenant(this.selectedTenant);
  }
}
