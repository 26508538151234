<!-- Sensor's Monitor Section -->
<div class="dt-mt-row" fxLayout="row wrap">
  <div fxFlex.gt-sm="77" fxFlex.gt-xs="45" fxFlex="50">
    <form class="form-field-full m-l-16">
      <mat-form-field>
        <mat-label>{{ "Data Timeframe" | ioxTranslate }}</mat-label>
        <input
          type="text"
          autocomplete="off"
          ngxDaterangepickerMd
          matInput
          [locale]="{ applyLabel: 'ok', format: 'DD-MM-YYYY' }"
          startKey="start"
          endKey="end"
          placeholder="Select a timeframe"
          [(ngModel)]="selected"
          [timePicker]="true"
          [timePickerSeconds]="false"
          [timePickerIncrement]="15"
          [timePicker24Hour]="true"
          name="daterange"
          (change)="getData($event)"
        />
      </mat-form-field>
    </form>
  </div>
  <div fxFlex.gt-sm="10" fxFlex.gt-xs="50" fxFlex="49">
    <mat-button-toggle-group
      class="m-t-8 m-l-8"
      name="timeRange"
      aria-label="Time Range"
      [(ngModel)]="timeRange"
      (change)="getTime($event)"
    >
      <mat-button-toggle value="lastMonth">{{
        "Last Month" | ioxTranslate
      }}</mat-button-toggle>
      <mat-button-toggle value="thisMonth">{{
        "This Month" | ioxTranslate
      }}</mat-button-toggle>
      <mat-button-toggle value="default">{{
        "Default" | ioxTranslate
      }}</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
<div class="dt-mt-row" fxLayout="row wrap">
  <div
    class="mat-elevation-z5"
    fxFlex.gt-sm="100"
    fxFlex.gt-xs="100"
    fxFlex="100"
  >
    <mat-card>
      <div style="width: 100%; margin: 0; overflow: hidden" *ngIf="url">
        <iframe
          width="100%"
          height="950"
          scrolling="no"
          [src]="url | safe"
        ></iframe>
      </div>
    </mat-card>
  </div>
</div>
