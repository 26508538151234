import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Subject, Subscription } from 'rxjs';
import { Alarm } from 'src/app/models/api/alarm';
import { AlarmService } from 'src/app/services/api/alarm.service';
import { SensorService } from 'src/app/services/api/sensor.service';
import { WebsocketService } from 'src/app/services/api/websocket.service';
import { EnvService } from 'src/app/services/core/env.service';
import { AxUtils } from 'src/app/shared/utils/axUtils';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-alarms',
  templateUrl: './alarms.component.html',
  styleUrls: ['./alarms.component.scss'],
})
export class AlarmsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  selection = new SelectionModel<any>(false, []);
  public rows: Alarm[] = []; // will come from valueService
  public devices = [];
  private subscribe: Subscription;
  public savedRows: Alarm[] = []; // for searching
  public allDone = false;
  public detailsTableConfig: Array<any>;
  public searchTextField = '';
  private subject: Subject<MessageEvent>;
  public displayedColumns = ['aType', 'savedAt', 'sensorId', 'message'];
  public length = 0;
  public dataSource = new MatTableDataSource<Alarm>();
  @ViewChild('table') table: MatTable<any>;
  constructor(
    private service: SensorService,
    private alarmService: AlarmService,
    private websocketService: WebsocketService,
    private env: EnvService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (this.subscribe) {
      this.subscribe.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.subscribe = this.alarmService.getMessages().subscribe((response) => {
      this.dataSource = new MatTableDataSource(response);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.savedRows = [...this.dataSource.data];

      this.service.getSensors().subscribe(
        (response) => {
          this.devices = response.sensors.filter((x) => x.name !== 'Unused');
          this.reconnect();
        },
        (err: HttpErrorResponse) => AxUtils.handleServiceError(err)
      );
      this.allDone = true;
      this.reconnect();
    });
  }

  reconnect() {
    if (!this.subject || this.subject.isStopped) {
      this.subject = this.websocketService.connect(this.env.alarmsocketUrl);
      this.subject.subscribe(
        (message) => this.handleMessage(message),
        (err) => console.error(err),
        () => this.reconnect()
      );
    }
  }

  getSensorName(id: string): string {
    let sensorName = '';
    const sensor = this.devices.filter((x) => {
      if (x.id == id) {
        sensorName = x.name;
      }
    });
    return sensorName;
  }

  handleMessage(event: MessageEvent) {
    const alarmValue = JSON.parse(event.data) as Alarm;
    const isTalegurAlarm = alarmValue && alarmValue.hasOwnProperty('aType');
    if (isTalegurAlarm) {
      const rows = [...this.dataSource.data];
      rows.splice(0, 0, alarmValue);
      this.dataSource.data = [...rows];
      this.savedRows = [...rows];
    }
  }

  handleSelect(row) {
    this.selection.select(row);
  }

  search(value) {
    const val = value.toLowerCase();
    const colsAmt = this.displayedColumns.length;
    const keys = Object.keys(this.savedRows[0]);
    // assign filtered matches to the active datatable
    this.dataSource.data = this.savedRows.filter((item) => {
      // iterate through each row's column data
      for (let i = 0; i < colsAmt; i++) {
        // check for a match
        if (
          item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 ||
          !val
        ) {
          // found match, return true to add to result set
          return true;
        }
      }
    });
    // whenever the filter changes, always go back to the first page
    this.paginator.pageIndex = 0;
  }

  onSelect($event) {}
}
