<ng-container
  [ngTemplateOutlet]="menuListTpl"
  [ngTemplateOutletContext]="{
    menuList: menu$ | async,
    parentRoute: [],
    level: 0
  }"
>
</ng-container>

<ng-template
  #menuListTpl
  let-menuList="menuList"
  let-parentRoute="parentRoute"
  let-level="level"
>
  <ul
    navAccordion
    class="dt-mt-sidemenu {{ level > 0 ? 'dt-mt-sidemenu-sub' : '' }} level-{{
      level
    }}"
  >
    <li
      navAccordionItem
      class="dt-mt-sidemenu-item"
      routerLinkActive="active"
      *ngFor="let menuItem of menuList"
      [group]="menuItem.route"
      [type]="menuItem.type"
    >
      <a
        class="dt-mt-sidemenu-link"
        [routerLink]="buildRoute(parentRoute.concat([menuItem.route]))"
        *ngIf="menuItem.type === 'link'"
        matRipple
        [matRippleDisabled]="!ripple"
      >
        <ng-container
          [ngTemplateOutlet]="linkTypeTpl"
          [ngTemplateOutletContext]="{ item: menuItem, level: level }"
        >
        </ng-container>
      </a>

      <a
        class="dt-mt-sidemenu-link"
        [href]="menuItem.route"
        *ngIf="menuItem.type === 'extLink'"
        matRipple
        [matRippleDisabled]="!ripple"
      >
        <ng-container
          [ngTemplateOutlet]="linkTypeTpl"
          [ngTemplateOutletContext]="{ item: menuItem, level: level }"
        >
        </ng-container>
      </a>

      <a
        class="dt-mt-sidemenu-link"
        [href]="menuItem.route"
        target="_blank"
        *ngIf="menuItem.type === 'extTabLink'"
        matRipple
        [matRippleDisabled]="!ripple"
      >
        <ng-container
          [ngTemplateOutlet]="linkTypeTpl"
          [ngTemplateOutletContext]="{ item: menuItem, level: level }"
        >
        </ng-container>
      </a>

      <a
        navAccordionToggle
        class="dt-mt-sidemenu-toggle"
        *ngIf="menuItem.type === 'sub'"
        matRipple
        [matRippleDisabled]="!ripple"
      >
        <ng-container
          [ngTemplateOutlet]="linkTypeTpl"
          [ngTemplateOutletContext]="{ item: menuItem, level: level }"
        >
        </ng-container>
      </a>

      <ng-container
        [ngTemplateOutlet]="menuListTpl | ioxTranslate"
        [ngTemplateOutletContext]="{
          menuList: menuItem.children,
          parentRoute: parentRoute.concat([menuItem.route]),
          level: level + 1
        }"
      >
      </ng-container>
    </li>
  </ul>
</ng-template>

<ng-template #linkTypeTpl let-item="item" let-level="level">
  <mat-icon class="menu-icon" *ngIf="level === 0">{{ item.icon }}</mat-icon>
  <span class="menu-name f-w-500">{{
    item.name | translate | ioxTranslate
  }}</span>
  <span class="menu-label bg-{{ item.label.color }}" *ngIf="item.label">
    {{ item.label.value }}
  </span>
  <span fxFlex></span>
  <span class="menu-badge bg-{{ item.badge.color }}" *ngIf="item.badge">
    {{ item.badge.value }}
  </span>
  <mat-icon class="menu-caret" *ngIf="item.type !== 'link'">
    {{ item.type === "sub" ? "arrow_drop_down" : "launch" }}
  </mat-icon>
</ng-template>
