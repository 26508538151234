import { Component, OnInit } from '@angular/core';
import { forkJoin as observableForkJoin, Subscription } from 'rxjs';
import { EnvService } from 'src/app/services/core/env.service';
import { GrafanaService } from 'src/app/services/api/grafana.service';
import { IoxAdminService } from 'src/app/services/api/iox-admin.service';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  allDataReceived = false;
  url = null;
  dashboardUri = null;
  notif: Subscription;
  sites: Array<any>;
  gfDashboards: Array<any>;
  constructor(
    private env: EnvService,
    private gf: GrafanaService,
    private ioxAdmin: IoxAdminService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.gf
      .getDashboard()
      .pipe(
        switchMap((gfDashboards) => {
          this.gfDashboards = gfDashboards;
          return this.ioxAdmin.getCustomerSitesDetails();
        })
      )
      .subscribe((sites) => {
        this.sites = sites;
        for (const site of this.sites) {
          const tagToFind = `${this.env.app}-${site.route.toUpperCase()}-HOME`;
          const gfDashboard = this.gfDashboards.find((db: any) =>
            db.tags.includes(tagToFind)
          );

          if (gfDashboard) {
            site.dashboardUri = gfDashboard.url;
            site.title = gfDashboard.title;

            if (this.env.grafanaUrl.includes('grafana')) {
              site.dashboardUri = site.dashboardUri.replace('/grafana', '');
            }
          }
        }
      });
  }

  buttonClick(title: string) {
    this.route.navigate(['/grafana/' + title]);
  }

  ngOnDestroy() {
    // this.notif.unsubscribe();
  }
}
