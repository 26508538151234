<breadcrumb></breadcrumb>
<div class="dt-mt-row" fxLayout="row wrap">
  <div
    class="mat-elevation-z5"
    fxFlex.gt-sm="100"
    fxFlex.gt-xs="100"
    fxFlex="100"
  >
    <div class="dt-mt-row" fxLayout="row wrap">
      <div class="dt-mt-col" fxFlex="100" fxFlex.gt-sm="100" fxFlex.gt-xs="100">
        <mat-form-field class="m-r-16 m-l-16">
          <mat-icon matPrefix mat-raised-button>search</mat-icon>
          <input
            class="m-r-16 m-l-16"
            matInput
            type="search"
            (keyup)="applyFilter($event)"
            [(ngModel)]="searchTextField"
            type="text"
            ngModel
            name="searchParam"
            placeholder="{{ 'Search Floating Barge' | ioxTranslate }} "
            aria-label="Search..."
          />
          <strong> </strong>
          <mat-icon
            #settingsIcon
            matSuffix
            mat-raised-button
            color="primary"
            (click)="add()"
            class="pointer"
            matTooltip="{{ 'Add New Floating Barge' | ioxTranslate }} "
            >add_circle</mat-icon
          >
        </mat-form-field>
      </div>
    </div>
    <mat-card>
      <mat-card-content>
        <div class="scroll example-container">
          <table mat-table scroll matSort [dataSource]="dataSource">
            <ng-container
              matColumnDef="{{ col }}"
              *ngFor="let col of displayedColumns"
            >
              <th mat-header-cell *matHeaderCellDef>
                {{ col | uppercase | ioxTranslate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element[col] }}
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              class="pointer"
              (click)="viewDetails(request)"
              *matRowDef="let request; columns: displayedColumns"
            ></tr>
          </table>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-paginator
      [pageSizeOptions]="[100, 1000, 2000]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
