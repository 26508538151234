import { Component, OnDestroy, OnInit } from '@angular/core';
import { GrafanaService } from 'src/app/services/api/grafana.service';
import { EnvService } from 'src/app/services/core/env.service';
import { SettingsService } from 'src/app/services/themes/settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
@Component({
  selector: 'app-audit-logs',
  templateUrl: './audit-logs.component.html',
  styleUrls: ['./audit-logs.component.scss'],
})
export class AuditLogsComponent implements OnInit, OnDestroy {
  url = 'unset';
  dashboardUri = 'unset';
  notif: Subscription;
  dateFrom;
  dateTo;
  themeTone: string;
  timeRange = 'default';
  public selected: { start: moment.Moment; end: moment.Moment };
  constructor(
    private gf: GrafanaService,
    private env: EnvService,
    private settings: SettingsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.notif = this.settings.notify.subscribe((res) => {
      if (res.hasOwnProperty('theme')) {
        const themeTone = res.theme == 'dark' ? 'dark' : 'light';
        this.themeTone = themeTone;
        if (this.dateFrom) {
          this.url =
            this.env.grafanaUrl +
            this.dashboardUri +
            '?org=1&kiosk&theme=' +
            themeTone +
            '&from=' +
            this.dateFrom +
            '&to=' +
            this.dateTo;
        } else {
          this.url =
            this.env.grafanaUrl +
            this.dashboardUri +
            '?org=1&kiosk&theme=' +
            themeTone;
        }
      }
    });
  }

  ngOnInit(): void {
    const options = this.settings.getOptions();
    const isDark = options.theme;
    const themeTone = isDark == 'dark' ? 'dark' : 'light';
    this.themeTone = themeTone;

    this.gf.getDashboard().subscribe((res) => {
      const gfDashboard = res.find((db: any) => {
        if (db.tags.includes('AUDIT-LOG')) {
          return true;
        }
      });
      if (gfDashboard) {
        this.dashboardUri = gfDashboard.url;
        if (this.env.grafanaUrl.includes('grafana')) {
          this.dashboardUri = this.dashboardUri.replace('/grafana', '');
        }
        this.url =
          this.env.grafanaUrl +
          this.dashboardUri +
          '?org=1&kiosk&theme=' +
          themeTone;
      }
    });
  }

  getGfDashboardDefault() {
    this.url =
      this.env.grafanaUrl +
      this.dashboardUri +
      '?org=1&kiosk&theme=' +
      this.themeTone;
  }

  ngOnDestroy() {
    this.notif.unsubscribe();
  }
  getTime(event: MatButtonToggleChange) {
    if (event.value === 'lastMonth') {
      this.getLastMonth();
    } else if (event.value === 'thisMonth') {
      this.getThisMonth();
    } else if (event.value === 'default') {
      this.getGfDashboardDefault();
    }
  }

  getLastMonth() {
    let start = moment().subtract(1, 'months').startOf('month').startOf('day');
    let end = moment().subtract(1, 'months').endOf('month').endOf('day');
    const cDate = { start: start, end: end };
    this.getData(cDate);
  }

  getThisMonth() {
    const end = moment().endOf('day');
    const start = moment().startOf('month').startOf('day');
    const cDate = { start: start, end: end };
    this.getData(cDate);
  }

  getData(cDate) {
    if (cDate.start) {
      this.dateFrom = moment(cDate.start).valueOf();
      this.dateTo = moment(cDate.end).valueOf();
      this.url =
        this.env.grafanaUrl +
        this.dashboardUri +
        '?org=1&kiosk&theme=' +
        this.themeTone +
        '&from=' +
        this.dateFrom +
        '&to=' +
        this.dateTo;
    }
  }
}
