import { DeviceCommandEnum } from './enum-types/device-command-enum';
import { DeviceEventEnum } from './enum-types/device-event-enum';
import { DeviceStateEnum } from './enum-types/device-state-enum';

export class DeviceState {
  public state: DeviceStateEnum;
  public message: string;
  public command: DeviceCommandEnum;
  public errorCode: number;
  public deviceEvent: DeviceEventEnum;

  constructor(state: DeviceStateEnum, deviceEvent: DeviceEventEnum, message: string) {
    this.state = state;
    this.deviceEvent = deviceEvent;
    this.message = message;
  }

  public getState(): DeviceStateEnum {
    return this.state;
  }

  public setState(state: DeviceStateEnum): void {
    this.state = state;
  }

  public getMessage(): string {
    return this.message;
  }

  public setMessage(message: string): void {
    this.message = message;
  }

  public getCommand(): DeviceCommandEnum {
    return this.command;
  }

  public setCommand(command: DeviceCommandEnum): void {
    this.command = command;
  }

  public getErrorCode(): number {
    return this.errorCode;
  }

  public setErrorCode(errorCode: number): void {
    this.errorCode = errorCode;
  }

  public getDeviceEvent(): DeviceEventEnum {
    return this.deviceEvent;
  }

  public setDeviceEvent(deviceEvent: DeviceEventEnum): void {
    this.deviceEvent = deviceEvent;
  }
}
