import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RoutesRoutingModule } from './routes-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { DevicesComponent } from './devices/devices.component';
import { EditDeviceComponent } from './devices/edit-device/edit-device.component';
import { DetailsDeviceComponent } from './devices/details-device/details-device.component';
import { ScriptDeviceComponent } from './devices/script-device/script-device.component';
import { CategoriesDeviceComponent } from './devices/edit-device/categories-device/categories-device.component';
import { NewDeviceComponent } from './devices/new-device/new-device.component';
import { AlarmsComponent } from './alarms/alarms.component';
import { EventsComponent } from './events/events.component';
import { ExplorerComponent } from './explorer/explorer.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { UsersComponent } from './users/users.component';
import { NewUserComponent } from './users/new-user/new-user.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { AddGfDashboardComponent } from './devices/add-gf-dashboard/add-gf-dashboard.component';
import { GrafanaComponent } from './grafana-dashboards/grafana/grafana.component';
import { HomeComponent } from './home/home.component';
import { MachineryComponent } from './master-data/machinery/machinery.component';
import { FloatingBargeComponent } from './master-data/floating-barge/floating-barge.component';
import { ProductsComponent } from './master-data/products/products.component';
import { MachineryDetailsComponent } from './master-data/machinery/machinery-details/machinery-details.component';
import { MachineryAddComponent } from './master-data/machinery/machinery-add/machinery-add.component';
import { FloatingBargeAddComponent } from './master-data/floating-barge/floating-barge-add/floating-barge-add.component';
import { MachineryAddJobPlansComponent } from './master-data/machinery/machinery-add-job-plans/machinery-add-job-plans.component';
import { FloatingBargeDetailsComponent } from './master-data/floating-barge/floating-barge-details/floating-barge-details.component';
import { FloatingBargeEditComponent } from './master-data/floating-barge/floating-barge-edit/floating-barge-edit.component';
import { MachineryEditComponent } from './master-data/machinery/machinery-edit/machinery-edit.component';
import { ProductsAddComponent } from './master-data/products/products-add/products-add.component';
import { ProductsDetailsComponent } from './master-data/products/products-details/products-details.component';
import { ProductsEditComponent } from './master-data/products/products-edit/products-edit.component';
import { DailyJobsComponent } from './master-data/daily-jobs/daily-jobs.component';
import { DailyJobsEditComponent } from './master-data/daily-jobs/daily-jobs-edit/daily-jobs-edit.component';
import { DailyJobsAddComponent } from './master-data/daily-jobs/daily-jobs-add/daily-jobs-add.component';
import { DailyJobsDetailsComponent } from './master-data/daily-jobs/daily-jobs-details/daily-jobs-details.component';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { AuditLogsComponent } from './audit-logs/audit-logs.component';
@NgModule({
  declarations: [
    AuditLogsComponent,
    DashboardComponent,
    LoginComponent,
    HomeComponent,
    DevicesComponent,
    EditDeviceComponent,
    DetailsDeviceComponent,
    ScriptDeviceComponent,
    CategoriesDeviceComponent,
    NewDeviceComponent,
    AlarmsComponent,
    EventsComponent,
    ExplorerComponent,
    UsersComponent,
    NewUserComponent,
    EditUserComponent,
    AddGfDashboardComponent,
    GrafanaComponent,
    MachineryComponent,
    FloatingBargeComponent,
    ProductsComponent,
    MachineryDetailsComponent,
    MachineryAddComponent,
    FloatingBargeAddComponent,
    MachineryAddJobPlansComponent,
    FloatingBargeDetailsComponent,
    FloatingBargeEditComponent,
    MachineryEditComponent,
    ProductsAddComponent,
    ProductsDetailsComponent,
    ProductsEditComponent,
    DailyJobsComponent,
    DailyJobsEditComponent,
    DailyJobsAddComponent,
    DailyJobsDetailsComponent,
    ChangePasswordComponent,
  ],
  imports: [
    CommonModule,
    RoutesRoutingModule,
    NgxDaterangepickerMd.forRoot(),
    SharedModule,
  ],
})
export class RoutesModule {}
