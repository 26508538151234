import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IoxAdminService } from 'src/app/services/api/iox-admin.service';
import { CruditorFormConfiguration } from 'src/app/shared/components/cruditor-form/cruditor-form.configuration';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  form = new FormGroup({});
  user = {
    username: this.data['username'],
    newPassword: '',
    confirmedNewPassword: '',
  };
  public saveInForm = false;
  public isNewuser = false;
  public config: CruditorFormConfiguration = {
    detailsTitle: 'User',
    insertMessage: 'User Successfully Added',
    updateMessage: 'User Successfully Updated',
    deleteMessage: 'User Successfully Deleted',
    fields: [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-12',
            type: 'input',
            key: 'username',
            templateOptions: {
              label: 'Username',
              required: true,
              disabled: true,
            },
          },
          {
            key: 'newPassword',
            validators: {
              fieldMatch: {
                expression: (control) => {
                  const value = control.value;
                  return (
                    value.confirmedNewPassword === value.newPassword ||
                    // avoid displaying the message error when values are empty
                    !value.confirmedNewPassword ||
                    !value.newPassword
                  );
                },
                message: 'Password Not Matching',
                errorPath: 'confirmedNewPassword',
              },
            },
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                className: 'col-sm-12',
                type: 'input',
                key: 'newPassword',
                templateOptions: {
                  label: 'New Password',
                  required: true,
                  type: 'password',
                },
              },
              {
                className: 'col-sm-12',
                type: 'input',
                key: 'confirmedNewPassword',
                templateOptions: {
                  label: 'Confirm New Password',
                  required: true,
                  type: 'password',
                },
              },
            ],
          },
        ],
      },
    ],
  };
  constructor(
    public userService: IoxAdminService,
    @Inject(MAT_DIALOG_DATA) public data,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  changePassword() {
    if (this.form.valid) {
      this.user.confirmedNewPassword =
        this.user['newPassword']['confirmedNewPassword'];
      this.user.newPassword = this.user['newPassword']['newPassword'];

      this.userService.changePassword(this.user).subscribe(
        (newEntity) => {
          this.dialog.closeAll();
          this.snackBar.open(newEntity['message'], 'Dismiss');
        },
        (error) => {
          this.snackBar.open(
            'Change Password Failed due to Error Code: ' +
              error.error.code +
              ' ' +
              error.error.message,
            'Dismiss'
          );
        }
      );
    }
  }
}
