import { EnvService } from './env.service';

export const EnvServiceFactory = () => {
  // Create env
  const env = new EnvService();
  var originalConsole = console;

  // Read environment variables from browser window
  const browserWindow = window || {};
  const browserWindowEnv = browserWindow['__env'] || {};

  // Assign environment variables from browser window to env
  // In the current implementation, properties from env.js overwrite defaults from the EnvService.
  // If needed, a deep merge can be performed here to merge properties instead of overwriting them.
  for (const key in browserWindowEnv) {
    if (browserWindowEnv.hasOwnProperty(key)) {
      env[key] = window['__env'][key];
    }
  }
  env['coreApi'] = window.localStorage.getItem('coreApi');
  env['jsApi'] = window.localStorage.getItem('jsApi');
  env['pyApi'] = window.localStorage.getItem('pyApi');
  env['server'] = window.localStorage.getItem('server');
  env['stompUrl'] = window.localStorage.getItem('stompUrl');
  env['bridgeUrl'] = window.localStorage.getItem('bridgeUrl');
  env['uploadUrl'] = window.localStorage.getItem('uploadUrl');
  env['websocketUrl'] = window.localStorage.getItem('websocketUrl');
  env['alarmsocketUrl'] = window.localStorage.getItem('alarmsocketUrl');
  env['talegurMlUrl'] = window.localStorage.getItem('talegurMlUrl');
  env['GoogleMapsApiKey'] = window.localStorage.getItem('GoogleMapsApiKey');
  env['GrafanaApiKey'] = window.localStorage.getItem('GrafanaApiKey');
  env['tenant'] = window.localStorage.getItem('tenant');
  env['aitWhitelistHosts'] = window.localStorage.getItem('aitWhitelistHosts');
  env['grafanaUrl'] = window.localStorage.getItem('grafanaUrl');
  env['app'] = window.localStorage.getItem('app_name');
  env['ioxAdminApi'] = window.localStorage.getItem('ioxAdminApi');

  if (env['debug'] == false) {
    window.console.log = function () {};
  }
  return env;
};

export const EnvServiceProvider = {
  provide: EnvService,
  useFactory: EnvServiceFactory,
  deps: [],
};
