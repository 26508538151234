import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
  selector: 'formly-datetimepicker',
  template: `
    <mat-datetimepicker
      #picker
      [disabled]="to.datetimepickerOptions.disabled"
      [openOnFocus]="to.datetimepickerOptions.openOnFocus"
      [panelClass]="to.datetimepickerOptions.panelClass"
      [startAt]="to.datetimepickerOptions.startAt"
      [startView]="to.datetimepickerOptions.startView"
      [timeInterval]="to.datetimepickerOptions.timeInterval"
      [touchUi]="to.datetimepickerOptions.touchUi"
      [type]="to.datetimepickerOptions.type"
    >
    </mat-datetimepicker>
    <input
      matInput
      [id]="id"
      [errorStateMatcher]="errorStateMatcher"
      [formControl]="$any(formControl)"
      [matDatetimepicker]="picker"
      [max]="to.datetimepickerOptions.max"
      [min]="to.datetimepickerOptions.min"
      [formlyAttributes]="field"
      [placeholder]="to.placeholder"
      [tabindex]="to.tabindex"
      [readonly]="to.readonly"
      [required]="required"
      (dateInput)="to.datetimepickerOptions.dateInput(field, $event)"
      (dateChange)="to.datetimepickerOptions.dateChange(field, $event)"
    />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyDatetimepickerComponent
  extends FieldType
  implements AfterViewInit, OnDestroy
{
  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  @ViewChild('datetimepickerToggle', { static: true })
  datetimepickerToggle!: TemplateRef<unknown>;

  override defaultOptions = {
    templateOptions: {
      datetimepickerOptions: {
        dateChange: () => {},
        dateInput: () => {},
        datetimepickerTogglePosition: 'suffix',
        openOnFocus: true,
        startView: 'month',
        timeInterval: 5,
        type: 'datetime',
      },
    },
  };

  detectChanges() {
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    this.to[this.to.datetimepickerOptions.datetimepickerTogglePosition] =
      this.datetimepickerToggle;
  }
}
