import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { AssetItem } from 'src/app/models/api/iox-admin/asset-item';
import { IoxAdminService } from 'src/app/services/api/iox-admin.service';
import { CruditorFormConfiguration } from 'src/app/shared/components/cruditor-form/cruditor-form.configuration';
import { AxUtils } from 'src/app/shared/utils/axUtils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Asset } from 'src/app/models/api/iox-admin/assets';
import { IoxTranslatePipe } from 'src/app/shared/pipes/iox-translate.pipe';

@Component({
  selector: 'app-floating-barge-edit',
  templateUrl: './floating-barge-edit.component.html',
  styleUrls: ['./floating-barge-edit.component.scss'],
})
export class FloatingBargeEditComponent implements OnInit, OnDestroy {
  form = new FormGroup({});
  public basicForm = new FormGroup({});
  public saveInForm = true;
  public isNewEntity = true;
  public asset: Asset;
  public config: CruditorFormConfiguration = {
    detailsTitle: 'Assets',
    insertMessage: this.translatePipe.transform(
      'Floating Barge Successfully Added'
    ),
    updateMessage: this.translatePipe.transform(
      'Floating Barge Successfully Updated'
    ),
    deleteMessage: this.translatePipe.transform(
      'Floating Barge Successfully Deleted'
    ),
    fields: [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-12',
            type: 'input',
            key: 'name',
            templateOptions: {
              label: this.translatePipe.transform('Name'),
              required: true,
            },
          },

          {
            className: 'col-sm-6',
            type: 'input',
            key: 'lat',
            templateOptions: {
              label: this.translatePipe.transform('Lat'),
            },
          },
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'lng',
            templateOptions: {
              label: this.translatePipe.transform('Lng'),
            },
          },
        ],
      },
    ],
  };
  id: Number;
  private destroy$ = new Subject<void>();
  constructor(
    private service: IoxAdminService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private route_path: Router,
    private translatePipe: IoxTranslatePipe
  ) {}

  ngOnInit(): void {
    this.basicForm = this.formBuilder.group({});
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.getAssetDetails();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getAssetDetails() {
    this.service.getAssetsById(this.id).subscribe(
      (response) => {
        this.asset = response;
        // this.lat = Number(this.item.lat);
        // this.lng = Number(this.item.lng);
      },
      (err: HttpErrorResponse) => AxUtils.handleServiceError(err)
    );
  }

  async save() {
    if (this.basicForm.valid) {
      try {
        const newEntity = await this.service.editAsset(this.asset).toPromise();
        this.navigateToFloating();
        this.showSnackbar(this.config.updateMessage);
      } catch (error) {
        const { code, message } = error.error;
        this.showSnackbar(
          `Add Floating Barge Failed due to Error Code: ${code} ${message}`
        );
      }
    }
  }
  private navigateToFloating() {
    this.route_path.navigate([
      `/master-data/floating-barge/details/${this.id}`,
    ]);
  }

  private showSnackbar(message: string) {
    this.snackBar.open(message, 'Dismiss');
  }
}
