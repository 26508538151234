<h3>{{ "Bulk Import Devices" | ioxTranslate }}</h3>
<mat-divider class="m-y-16"></mat-divider>
<mat-dialog-content>
  <mat-card-content>
    <div fxLayout="row" fxLayout="row wrap" class="dt-mt-row">
      <div fxFlex="100" class="dt-mt-col">
        <button
          mat-raised-button
          color="accent"
          class="m-b-8"
          (click)="file.click()"
        >
          {{ "Choose File" | ioxTranslate }}
        </button>
        <input
          hidden
          type="file"
          #file
          (change)="incomingfile($event)"
          placeholder="Upload file"
          accept=".xlsx, .xls, .csv"
        />
        <p class="m-l-32" *ngIf="files">{{ files.name }}</p>
        <mat-divider></mat-divider>
      </div>
    </div>
    <p class="text-center" style="color: red" *ngIf="error">
      <b> {{ "UPLOAD FILE FAILED" | ioxTranslate }}</b>
    </p>
    <p class="text-center" style="color: green" *ngIf="message">
      <b> {{ "UPLOAD FILE SUCCESSFULLY" | ioxTranslate }}</b>
    </p>
    <mat-dialog-actions>
      <button mat-raised-button mat-dialog-close>
        {{ "Close" | ioxTranslate }}
      </button>
      <button mat-raised-button color="primary" (click)="upload()">
        <mat-icon>file_upload</mat-icon>
        {{ "Upload" | ioxTranslate }}
      </button>
    </mat-dialog-actions>
  </mat-card-content>
</mat-dialog-content>
