import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'src/app/models/api/config';
import { EnvService } from '../core/env.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private configUrl = this.env.coreApi + '/Config';
  constructor(private env: EnvService, private http: HttpClient) {}

  getConfig() {
    return this.http.get<Config>(this.configUrl);
  }

  setAutoCreation(onOff: boolean) {
    return this.http.get<boolean>(this.configUrl + '/AutoCreation/' + onOff);
  }
  setAutoRegistration(onOff: boolean) {
    return this.http.get<boolean>(
      this.configUrl + '/AutoRegistration/' + onOff
    );
  }
}
