import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sensorClazz' })
export class SensorClazzPipe implements PipeTransform {
    transform(clazz: string): string {
        if (clazz !== 'Sensor') {
            clazz = clazz.replace('Sensor', '');
        }
        return clazz;
    }
}