export class AssetItem {
  id: number;
  name: string;
  lat: string;
  lng: string;
  model: string;
  asset_type_id: number;
  assets_id: number;
  createdAt: Date;
  asset_type: {
    name: string;
    id: number;
  };
  assets: {
    name: string;
    id: number;
  };
  sensors: Array<any>;
}
