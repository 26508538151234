/**
 * Enum for device State
 *
 * @author AMDIS Media and IT Services
 *
 * @date 12.2.2018
 */
export enum DeviceStateEnum {
    unregistered = 'unregistered',
    registered = 'registered',
    configured = 'configured',
    active = 'active',
    inactive = 'inactive',
    deregistered = 'deregistered'
}
