import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-input',
  templateUrl: './confirmation-input.component.html',
  styleUrls: ['./confirmation-input.component.scss'],
})
export class ConfirmationInputComponent implements OnInit {
  title: string;
  message: string;
  remarks: string = '';
  constructor(
    public dialogRef: MatDialogRef<ConfirmationInputComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  onConfirmClick(): void {
    if (!this.remarks) {
      // Don't close the dialog if remarks are empty
      return;
    }

    // Close the dialog with the desired result
    this.dialogRef.close({ confirmed: true, remarks: this.remarks });
  }
}
