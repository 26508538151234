import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import moment from 'moment';
import { Subject, forkJoin } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { AssetItem } from 'src/app/models/api/iox-admin/asset-item';
import { JobPlan } from 'src/app/models/api/iox-admin/job-plan';
import { IoxAdminService } from 'src/app/services/api/iox-admin.service';
import { AxUtils } from 'src/app/shared/utils/axUtils';
import { Observable } from 'rxjs';
import { CruditorFormConfiguration } from 'src/app/shared/components/cruditor-form/cruditor-form.configuration';
import { IoxTranslatePipe } from 'src/app/shared/pipes/iox-translate.pipe';
import { WorkStoppage } from 'src/app/models/api/iox-admin/work-stoppage';
@Component({
  selector: 'app-machinery-add-job-plans',
  templateUrl: './machinery-add-job-plans.component.html',
  styleUrls: ['./machinery-add-job-plans.component.scss'],
})
export class MachineryAddJobPlansComponent implements OnInit, OnDestroy {
  private dtFormat = 'YYYY-MM-DD HH:mm:ss';
  public selected: { start: moment.Moment; end: moment.Moment };
  dateFrom;
  dateTo;
  stopPlanDetails: WorkStoppage = new WorkStoppage();
  form = new FormGroup({});
  public items: AssetItem[] = [];
  filteredOptions: Observable<AssetItem[]>;
  public basicForm = new FormGroup({});
  public stopForm = new FormGroup({});
  public newPlan = new JobPlan();
  selectedPlan: string;
  plans: string[] = [
    'Operating',
    'Maintenance',
    'Work Stoppage',
    'Not Available',
  ];
  public config: CruditorFormConfiguration = {
    detailsTitle: 'WorkStoppage Plan',
    insertMessage: this.translatePipe.transform('Plan Successfully Added'),
    updateMessage: this.translatePipe.transform('Plan Successfully Updated'),
    deleteMessage: this.translatePipe.transform('Plan Successfully Deleted'),
    fields: [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'meter_before',
            templateOptions: {
              label: this.translatePipe.transform('Meter Before'),
              type: 'number',
            },
          },
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'meter_after',
            templateOptions: {
              label: this.translatePipe.transform('Meter After'),
              type: 'number',
            },
          },
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'use_fuel',
            templateOptions: {
              label: this.translatePipe.transform('Fuel Used'),
              type: 'number',
            },
          },
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'reason_stop',
            templateOptions: {
              label: this.translatePipe.transform('Stoppage Reason'),
            },
          },
        ],
      },
    ],
  };
  private destroy$ = new Subject<void>();
  constructor(
    private service: IoxAdminService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private route: Router,
    private translatePipe: IoxTranslatePipe
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      machineStatus: ['', Validators.required],
      dateTimeframe: ['', Validators.required],
      machineName: new FormControl('', Validators.required),
    });
    this.basicForm = this.formBuilder.group({});
    this.stopForm = this.formBuilder.group({});
    forkJoin({
      items: this.service.getAssetItems(),
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.items = response.items;
        },
        (err: HttpErrorResponse) => AxUtils.handleServiceError(err)
      );
    this.filteredOptions = this.form.get('machineName').valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.items.slice();
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private _filter(name: string): AssetItem[] {
    const filterValue = name.toLowerCase();
    return this.items.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  displayFn(item: AssetItem): string {
    return item && item.name ? item.name : '';
  }

  handleSelectMachine(event: string) {
    this.newPlan.asset_items_id = Number(event);
  }

  async insert() {
    if (this.form.valid) {
      try {
        this.newPlan.asset_items_id = this.form.get('machineName').value.id;
        let serviceCall;
        switch (this.form.get('machineStatus').value) {
          case 'Operating':
            serviceCall = this.service.insertOperTime(this.newPlan);
            break;
          case 'Maintenance':
            serviceCall = this.service.insertMaPlan(this.newPlan);
            break;
          case 'Work Stoppage':
            this.stopPlanDetails = { ...this.newPlan, ...this.stopPlanDetails };
            serviceCall = this.service.insertWorkStoppage(this.stopPlanDetails);
            break;
          default:
            serviceCall = this.service.insertNotAvailableTime(this.newPlan);
            break;
        }

        await serviceCall.toPromise();
        this.navigateToMachinery();
        this.showSnackbar('Add Job Plan Successfully.');
      } catch (error) {
        this.handleServiceError(error);
      }
    }
  }

  private handleServiceError(error: any) {
    const { code, message } = error.error;
    this.showSnackbar(
      `Add Machine Failed due to Error Code: ${code} ${message}`
    );
  }

  setDate(cDate) {
    if (cDate.start) {
      this.newPlan.from_time = new Date(
        moment(cDate.start).format(this.dtFormat)
      );
      this.newPlan.to_time = new Date(moment(cDate.end).format(this.dtFormat));
    }
  }

  private navigateToMachinery() {
    this.route.navigate(['/master-data/machinery']);
  }

  private showSnackbar(message: string) {
    this.snackBar.open(message, 'Dismiss');
  }
}
