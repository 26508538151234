import { Injectable } from '@angular/core';
import { TenantWebDetails } from 'src/app/models/api/tenant-web-details';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  get(key: string) {
    return localStorage.getItem(key);
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  saveTenantWebDetails(tenantDetails: TenantWebDetails) {
    localStorage.setItem('tenantStatus', tenantDetails.app_name);
    localStorage.setItem('tenant', tenantDetails.tenant);
    localStorage.setItem('server_ip', tenantDetails.server_ip);
    localStorage.setItem('primary_color', tenantDetails.primary_color);
    localStorage.setItem('accent_color', tenantDetails.accent_color);
    localStorage.setItem('logo_url', tenantDetails.logo_url);
    localStorage.setItem('GoogleMapsApiKey', tenantDetails.google_api_key);
    localStorage.setItem('GrafanaApiKey', tenantDetails.gf_api_key);
    localStorage.setItem('dark_logo_url', tenantDetails.dark_logo_url);
    localStorage.setItem('favicon_url', tenantDetails.favicon_url);
    localStorage.setItem(
      'login_background_url',
      tenantDetails.login_background_url
    );
    localStorage.setItem('app_name', tenantDetails.app_name);
  }
}
