import { Pipe, PipeTransform } from '@angular/core';
import { IoxLanguageService } from 'src/app/services/api/iox-language.service';

@Pipe({
  name: 'ioxTranslate',
})
export class IoxTranslatePipe implements PipeTransform {
  constructor(private translator: IoxLanguageService) {}

  transform(value: string): string {
    return this.translator.getTranslation(value);
  }
}
