import { Component } from '@angular/core';
import { User } from 'src/app/models/api/user';
import { SettingsService } from 'src/app/services/themes/settings.service';

@Component({
  selector: 'app-user-panel',
  template: `
    <div
      class="dt-mt-user-panel"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <div class="dt-mt-user-panel-icons">
        <a routerLink="/profile/overview" mat-icon-button>
          <mat-icon>account_circle</mat-icon>
        </a>
        <a routerLink="/profile/settings" mat-icon-button>
          <mat-icon>settings</mat-icon>
        </a>
        <a routerLink="/auth/login" mat-icon-button>
          <mat-icon>exit_to_app</mat-icon>
        </a>
      </div>
    </div>
  `,
  styleUrls: ['./user-panel.component.scss'],
})
export class UserPanelComponent {
  user: User;

  constructor(settings: SettingsService) {
    // this.user = settings.user;
    this.user = null;
  }
}
