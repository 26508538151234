<h4 *ngIf="item">
  <strong
    >{{ "Edit Daily Job" | ioxTranslate }}: {{ item.ship_name }}
    {{ item.job_start | date : "dd/MM/yyyy HH:mm" }}</strong
  >
</h4>
<div class="dt-mt-row" fxLayout="row wrap">
  <mat-card
    class="mat-elevation-z8"
    [fxFlex.gt-sm]="100"
    [fxFlex.gt-xs]="100"
    fxFlex="100"
  >
    <formly-form
      *ngIf="item"
      [form]="basicForm"
      [fields]="config.fields"
      [model]="item"
    >
    </formly-form>
    <mat-dialog-actions>
      <button mat-button (click)="navigateToDetails()">
        {{ "Cancel" | ioxTranslate }}
      </button>
      <button mat-raised-button color="primary" type="submit" (click)="save()">
        {{ "Save Changes" | ioxTranslate }}
      </button>
    </mat-dialog-actions>
  </mat-card>
</div>
