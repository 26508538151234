<mat-expansion-panel #basicPanel [expanded]="true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <strong>
        {{ "Basic Configurations" | ioxTranslate }}</strong
      ></mat-panel-title
    >
  </mat-expansion-panel-header>
  <app-cruditor-form
    *ngIf="sensor"
    [dataService]="sensorService"
    [config]="config"
    [data]="sensor"
    [isNewEntity]="isNewDevice"
    [saveInForm]="saveInForm"
  >
  </app-cruditor-form>
  <!-- <app-cruditor-form *ngIf="sensor" [dataService]="sensorService" [config]="config" [data]="sensor"
    [isNewEntity]="isNewDevice" [saveInForm]="saveInForm">
  </app-cruditor-form> -->
</mat-expansion-panel>

<mat-expansion-panel #categoryPanel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <strong>{{ "Categories Configurations" | ioxTranslate }}</strong>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <app-categories-device
    *ngIf="sensor"
    [sensor]="sensor"
  ></app-categories-device>
  <!--   
  <mat-divider class="m-y-16" inset></mat-divider>
  <app-location-device *ngIf="sensor" [sensor]="sensor"></app-location-device> -->
</mat-expansion-panel>
<mat-expansion-panel #locationPanel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <strong> {{ "Location Configurations" | ioxTranslate }} </strong>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <app-google-map
    *ngIf="sensor"
    [dataService]="sensorService"
    [data]="sensor"
    [isNewEntity]="isNewDevice"
  >
  </app-google-map>
  <!--   
  <mat-divider class="m-y-16" inset></mat-divider>
  <app-location-device *ngIf="sensor" [sensor]="sensor"></app-location-device> -->
</mat-expansion-panel>
