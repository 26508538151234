<div class="w-full h-full" xLayout="row" fxLayoutAlign="center center">
  <div class="main-div" fxLayout="row wrap">
    <mat-card
      class="dt-mt-col transparent"
      [fxFlex.gt-sm]="50"
      [fxFlex.gt-xs]="50"
      fxFlex="100"
    >
      <img
        (click)="changeTenant($event)"
        src="{{ imageLogo }}"
        alt="logo"
        width="{{ width }}"
        height="{{ height }}"
      />
      <p *ngIf="showClicks" class="text-right">
        <strong style="color: white">{{ clicksCount }}</strong>
      </p>
    </mat-card>
    <mat-card
      class="box dt-mt-col"
      style="max-width: 380px"
      [fxFlex.gt-sm]="50"
      [fxFlex.gt-xs]="50"
      fxFlex="100"
    >
      <form
        *ngIf="!tenantStatus"
        class="form-field-full"
        [formGroup]="tenantForm"
      >
        <mat-card-header>
          <mat-card-title class="text-left m-b-24">
            Welcome to IOTONIX!
            <!-- <img src="./assets/img/brand/logo-trans.svg" class="dt-mt-branding-logo-expanded" alt="logo" /> -->
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-form-field appearance="outline">
            <mat-label>APP NAME</mat-label>
            <input matInput placeholder="" formControlName="tenant" />
            <mat-error *ngIf="tenant.invalid">
              <strong>*Required</strong>
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="errorMsgTenant">
            <strong>
              {{ errorMsgTenant }}
            </strong>
          </mat-error>
          <div
            class="m-y-16"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          ></div>
          <button
            class="w-full"
            mat-raised-button
            color="primary"
            [disabled]="tenantForm.invalid"
            (click)="handleTenant()"
          >
            OK
          </button>
        </mat-card-content>
      </form>
      <form
        *ngIf="tenantStatus"
        class="form-field-full"
        [formGroup]="loginForm"
      >
        <mat-card-header>
          <mat-card-title class="text-left m-b-24">
            Welcome Back!
            <!-- <img src="./assets/img/brand/logo-trans.svg" class="dt-mt-branding-logo-expanded" alt="logo" /> -->
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-form-field appearance="outline">
            <mat-label>USERNAME</mat-label>
            <input matInput placeholder="" formControlName="username" />
            <mat-error *ngIf="username.invalid">
              <strong>*Required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>PASSWORD</mat-label>
            <input
              matInput
              placeholder=""
              type="{{ hidePassword ? 'password' : 'text' }}"
              formControlName="password"
              (keyup.enter)="handleLogin($event)"
            />
            <button
              mat-icon-button
              matSuffix
              (click)="togglePasswordVisibility()"
              type="button"
              [attr.aria-label]="
                hidePassword ? 'Show password' : 'Hide password'
              "
            >
              <mat-icon>
                {{ hidePassword ? "visibility_off" : "visibility" }}
              </mat-icon>
            </button>
            <mat-error *ngIf="password.invalid && password.touched">
              <strong>*Required</strong>
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="errorMsg">
            <strong>{{ errorMsg }}</strong>
          </mat-error>
          <mat-form-field appearance="outline">
            <mat-label>Language</mat-label>
            <mat-select
              (selectionChange)="changeLanguage($event.value)"
              [(value)]="selectedLanguage"
            >
              <mat-option
                *ngFor="let language of languages"
                [value]="language.locale"
                >{{ language.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <div class="m-y-16" fxLayout="row" fxLayoutAlign="space-between center">
                        <mat-checkbox color="primary">Remember Me</mat-checkbox>
                    </div> -->
          <button
            class="w-full"
            mat-raised-button
            color="primary"
            [disabled]="loginForm.invalid"
            (click)="handleLogin()"
          >
            LOGIN
          </button>

          <p *ngIf="env.enableDebug" class="text-right">
            <strong>Service:</strong> {{ server }}
          </p>
          <p class="text-right"><strong>Version:</strong> {{ version }}</p>
        </mat-card-content>
      </form>
    </mat-card>
  </div>
</div>
