<breadcrumb></breadcrumb>
<div class="dt-mt-row" fxLayout="row wrap">
  <div
    class="mat-elevation-z5"
    fxFlex.gt-sm="100"
    fxFlex.gt-xs="100"
    fxFlex="100"
  >
    <mat-card>
      <mat-card-content>
        <form
          class="form-field-full"
          style="margin-left: 10px"
          *ngIf="allDataReceived"
        >
          <div class="dt-mt-row" fxLayout="row wrap">
            <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>{{ "Sensor Name" | ioxTranslate }}</mat-label>
                <input
                  type="text"
                  matInput
                  [formControl]="myControl"
                  [matAutocomplete]="auto"
                  #inputTrigger="matAutocompleteTrigger"
                  (click)="handleInputClick()"
                />
                <mat-autocomplete
                  #auto="matAutocomplete"
                  [displayWith]="displayFn"
                  (optionSelected)="handleSelectSensor($event.option.value.id)"
                >
                  <mat-option
                    *ngFor="let option of filteredOptions | async"
                    [value]="option"
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div fxFlex.gt-sm="75" fxFlex.gt-xs="75" fxFlex="100">
              <mat-form-field appearance="fill" *ngIf="showDataTable">
                <mat-label>{{ "Data Timeframe" | ioxTranslate }}</mat-label>
                <input
                  type="text"
                  autocomplete="off"
                  ngxDaterangepickerMd
                  matInput
                  [locale]="{ applyLabel: 'ok', format: 'DD-MM-YYYY' }"
                  startKey="start"
                  endKey="end"
                  placeholder="Select a timeframe"
                  [(ngModel)]="selected"
                  [timePicker]="true"
                  [timePickerSeconds]="false"
                  [timePickerIncrement]="15"
                  [timePicker24Hour]="true"
                  name="daterange"
                  (change)="getData($event)"
                  [material]="true"
                />
              </mat-form-field>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-content>
        <div class="dt-mt-row" fxLayout="row wrap" *ngIf="showDataTable">
          <div fxFlex.gt-sm="80" fxFlex.gt-xs="80" fxFlex="100">
            <h2 class="m-l-24">
              {{ "Data points for" | ioxTranslate }} {{ sensorName }}
              {{ "with ID" | ioxTranslate }}: {{ sensorId }}
            </h2>
          </div>
          <div fxFlex.gt-sm="20" fxFlex.gt-xs="20" fxFlex="100">
            <div class="dt-mt-row">
              <button
                (click)="exportToExcel()"
                mat-raised-button
                color="primary"
              >
                Export to Excel
              </button>
              <button (click)="exportToJson()" mat-raised-button color="accent">
                Export to JSON
              </button>
            </div>
          </div>
        </div>
        <div class="scroll">
          <table mat-table scroll [dataSource]="dataSource" matSort>
            <ng-container
              matColumnDef="{{ col }}"
              *ngFor="let col of columnsToDisplay"
            >
              <th mat-header-cell *matHeaderCellDef>
                <ng-container *ngIf="col !== 'action'">
                  <input
                    matInput
                    (keyup)="applyFilter($event.target.value, col)"
                    placeholder="Filter Here"
                  />
                </ng-container>
                <div mat-sort-header>{{ col | uppercase | ioxTranslate }}</div>
              </th>
              <span
                *ngIf="
                  col === 'time' ||
                  col === 'time_deleted' ||
                  col === 'measuredAt'
                "
              >
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroup]="element"
                >
                  {{ element.value[col] | date : "dd/MM/yy HH:mm" }}
                </td>
              </span>
              <span *ngIf="col == 'iox_ds'">
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroup]="element"
                >
                  <p *ngIf="editForm.get('editRows').value[i].iox_ds == 0">
                    <strong>DELETED</strong>
                  </p>
                  <p *ngIf="editForm.get('editRows').value[i].iox_ds == 2">
                    <strong>IGNORED</strong>
                  </p>
                  <p
                    *ngIf="
                      editForm.get('editRows').value[i].iox_ds != 0 &&
                      editForm.get('editRows').value[i].iox_ds != 2
                    "
                  >
                    <strong> {{ element.value.iox_ds }} </strong>
                  </p>
                </td>
              </span>
              <span *ngIf="col == 'action'">
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroup]="element"
                >
                  <button
                    mat-icon-button
                    aria-label="View Json"
                    matTooltip="View Json"
                    (click)="viewOriginal(element.value)"
                  >
                    <mat-icon class="text-teal-500">search</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    matTooltip="Save Changes"
                    class="material-icons app-toolbar-menu save-button color_green"
                    (click)="SaveVO(editForm, i)"
                    *ngIf="!editForm.get('editRows').value[i].isEditable"
                  >
                    <mat-icon>check_circle</mat-icon>
                  </button>

                  <button
                    mat-icon-button
                    class="material-icons app-toolbar-menu cancel-button"
                    matTooltip="Cancel Changes"
                    (click)="CancelSVO(editForm, i)"
                    *ngIf="!editForm.get('editRows').value[i].isEditable"
                  >
                    <mat-icon>cancel</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    class="material-icons app-toolbar-menu save-button color_orange"
                    *ngIf="editForm.get('editRows').value[i].isEditable"
                    matTooltip="Edit"
                    (click)="EditSVO(editForm, i)"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button
                    *ngIf="editForm.get('editRows').value[i].iox_ds != 0"
                    mat-icon-button
                    aria-label="Delete"
                    matTooltip="Ignore Log"
                    (click)="confirmDeleteDialog(i)"
                  >
                    <mat-icon color="warn">delete</mat-icon>
                  </button>
                  <button
                    *ngIf="editForm.get('editRows').value[i].iox_ds == 0"
                    mat-icon-button
                    aria-label="Undo Ignore Log"
                    matTooltip="Undo Ignore Log"
                    (click)="undoDelete(i)"
                  >
                    <mat-icon color="accent">undo</mat-icon>
                  </button>
                </td>
              </span>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroup]="element"
              >
                <mat-form-field
                  style="width: 70px"
                  [appearance]="
                    editForm.get('editRows').value[i].isEditable
                      ? 'none'
                      : 'legacy'
                  "
                >
                  <input
                    matInput
                    type="text"
                    [formControlName]="col"
                    [readonly]="editForm.get('editRows').value[i].isEditable"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr
              mat-row
              *matRowDef="let request; columns: columnsToDisplay"
            ></tr>
          </table>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-paginator
      [pageSizeOptions]="[20, 50, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
