<div class="dt-mt-page-header p-t-8 p-b-8 m-b-8">
  <h1 class="dt-mt-page-title">{{ "Add New Device" | ioxTranslate }}</h1>
</div>
<!-- <h2 mat-dialog-title><strong>Add New Device</strong></h2> -->
<mat-dialog-content>
  <mat-horizontal-stepper [linear]="isLinear" #stepper>
    <mat-step [stepControl]="basicForm">
      <ng-template matStepLabel>{{
        "Basic Details" | ioxTranslate
      }}</ng-template>
      <form [formGroup]="basicForm" (ngSubmit)="onSubmit(sensor)">
        <formly-form
          *ngIf="sensor"
          [form]="basicForm"
          [fields]="config.fields"
          [model]="sensor"
        >
        </formly-form>
        <mat-dialog-actions>
          <button mat-button mat-dialog-close>
            {{ "Cancel" | ioxTranslate }}
          </button>
          <button
            mat-raised-button
            color="primary"
            type="submit"
            matStepperNext
          >
            {{ "Next" | ioxTranslate }}
          </button>
        </mat-dialog-actions>
      </form>
    </mat-step>
    <mat-step [stepControl]="categoryForm">
      <ng-template matStepLabel>{{ "Categories" | ioxTranslate }}</ng-template>
      <mat-card-subtitle>
        <button
          mat-button
          *ngIf="!newCategory"
          color="primary"
          (click)="addNewCategory()"
        >
          <mat-icon>add</mat-icon> {{ "Add New Category" | ioxTranslate }}
        </button>
      </mat-card-subtitle>
      <div
        *ngIf="newCategory"
        fxLayout="row"
        fxLayout="row wrap"
        class="dt-mt-row"
      >
        <div fxFlex="100" class="dt-mt-col b-b-4">
          <mat-form-field>
            <mat-label>{{ "Category Name" | ioxTranslate }}</mat-label>
            <input matInput required [(ngModel)]="categoryName" />
          </mat-form-field>
          <mat-form-field class="m-r-8">
            <mat-label>{{ "Category Value" | ioxTranslate }}</mat-label>
            <input matInput [(ngModel)]="categoryValue" />
          </mat-form-field>
          <button
            mat-button
            (click)="handleUnsert()"
            color="primary"
            aria-label="Add"
          >
            <mat-icon>add</mat-icon>{{ "Add" | ioxTranslate }}
          </button>
          <button mat-button (click)="close()" aria-label="Close" color="warn">
            <mat-icon>close</mat-icon>{{ "Close" | ioxTranslate }}
          </button>
        </div>
      </div>
      <mat-card-content>
        <mat-table #table *ngIf="rows.length > 0" [dataSource]="rows">
          <ng-container matColumnDef="categoryName">
            <mat-header-cell *matHeaderCellDef>
              {{ "categoryName" | ioxTranslate }}</mat-header-cell
            >
            <mat-cell *matCellDef="let category">
              {{ category.categoryName }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="categoryValue">
            <mat-header-cell *matHeaderCellDef>
              {{ "Value" | ioxTranslate }}</mat-header-cell
            >
            <mat-cell *matCellDef="let category">
              {{ category.categoryValue }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>
              {{ "Actions" | ioxTranslate }}</mat-header-cell
            >
            <mat-cell *matCellDef="let category; index as i">
              <span>
                <button mat-icon-button aria-label="Delete" (click)="delete(i)">
                  <mat-icon color="warn">delete</mat-icon>
                </button>
              </span>
            </mat-cell>
          </ng-container>
          <mat-header-row
            *matHeaderRowDef="['categoryName', 'categoryValue', 'actions']"
          ></mat-header-row>
          <mat-row
            *matRowDef="
              let category;
              columns: ['categoryName', 'categoryValue', 'actions'];
              let i = index
            "
          >
          </mat-row>
        </mat-table>
      </mat-card-content>
      <mat-dialog-actions>
        <button mat-button mat-dialog-close>
          {{ "Cancel" | ioxTranslate }}
        </button>
        <button mat-button matStepperPrevious>
          {{ "Back" | ioxTranslate }}
        </button>
        <button
          mat-raised-button
          color="primary"
          matStepperNext
          (click)="saveCategories()"
        >
          {{ "Next" | ioxTranslate }}
        </button>
      </mat-dialog-actions>
    </mat-step>
    <mat-step [stepControl]="locationForm">
      <ng-template matStepLabel>{{ "Location" | ioxTranslate }}</ng-template>
      <mat-form-field class="full">
        <mat-icon matPrefix>search</mat-icon>
        <input
          matInput
          #search
          type="search"
          (keydown.enter)="$event.preventDefault()"
          placeholder="{{ 'Enter address' | ioxTranslate }}"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="off"
          type="text"
        />
      </mat-form-field>
      <agm-map
        [latitude]="location.latitude"
        [longitude]="location.longitude"
        [zoom]="zoom"
        [scrollwheel]="scrollwheel"
      >
        <agm-marker
          [latitude]="location.latitude"
          [longitude]="location.longitude"
          [markerDraggable]="true"
          (dragEnd)="markerDragEnd($event)"
        ></agm-marker>
      </agm-map>

      <div fxLayout="row" fxLayout="row wrap" class="dt-mt-row m-t-8">
        <mat-form-field fxFlex="50" class="dt-mt-col m-r-8">
          <mat-label>{{ "Name/Other Details" | ioxTranslate }}</mat-label>
          <input matInput [(ngModel)]="location.details" />
        </mat-form-field>
        <mat-form-field fxFlex="20" class="dt-mt-col m-r-8">
          <mat-label>{{ "Latitude" | ioxTranslate }}</mat-label>
          <input matInput [(ngModel)]="location.latitude" />
        </mat-form-field>
        <mat-form-field fxFlex="20" class="dt-mt-col m-r-8">
          <mat-label>{{ "Longitude" | ioxTranslate }}</mat-label>
          <input matInput [(ngModel)]="location.longitude" />
        </mat-form-field>
        <mat-form-field class="dt-mt-col m-r-8" fxFlex="100">
          <mat-label>{{ "Address" | ioxTranslate }}</mat-label>
          <input matInput [(ngModel)]="location.address" />
        </mat-form-field>
      </div>
      <mat-dialog-actions>
        <button mat-button mat-dialog-close>
          {{ "Cancel" | ioxTranslate }}
        </button>
        <button mat-button matStepperPrevious>
          {{ "Back" | ioxTranslate }}
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="insertRecord()"
          mat-dialog-close
        >
          {{ "Confirm Add sensor" | ioxTranslate }}
        </button>
      </mat-dialog-actions>
    </mat-step>
  </mat-horizontal-stepper>
</mat-dialog-content>
