import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  AfterViewInit,
} from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Sensor } from 'src/app/models/api/sensor';
import { SensorService } from 'src/app/services/api/sensor.service';
import { InfluxService } from 'src/app/services/api/influx.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import * as moment from 'moment';
import { MatSort } from '@angular/material/sort';
import { Observable, Subscription } from 'rxjs';
import { SettingsService } from 'src/app/services/themes/settings.service';
import { LocalStorageService } from 'src/app/services/core/authentication/storage.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { GenericDetailViewerComponent } from 'src/app/shared/components/generic-detail-viewer/generic-detail-viewer.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationInputComponent } from 'src/app/shared/components/confirmation-input/confirmation-input.component';
@Component({
  selector: 'app-explorer',
  templateUrl: './explorer.component.html',
  styleUrls: ['./explorer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExplorerComponent implements OnInit, OnDestroy, AfterViewInit {
  private dtFormat = 'YYYY-MM-DD HH:mm:ss';
  private measurement = '';
  public sensors: Sensor[];
  public allDataReceived = false;
  private subscribe: Subscription;
  public showDataTable = false;
  public sensorName: string = null;
  public sensorId = 0;
  public dataSource: any;
  public columnsToDisplay = [];
  dateFrom;
  dateTo;
  // @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatSort) sort: MatSort;
  public selected: { start: moment.Moment; end: moment.Moment };
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  sensorstoFilter: Sensor[] = [];
  filteredOptions: Observable<Sensor[]>;
  myControl = new FormControl();
  editForm: FormGroup;
  sensorValues = [];
  formsChild = {};
  fieldsToRemove = [
    'iox_month',
    'iox_day',
    'iox_week',
    'iox_weekday',
    'iox_year',
    'mvalue',
  ];
  @ViewChild(MatAutocompleteTrigger)
  autocompleteTrigger: MatAutocompleteTrigger;
  constructor(
    private sensorService: SensorService,
    private InfluxService: InfluxService,
    private store: LocalStorageService,
    private setting: SettingsService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private dialog: MatDialog
  ) {
    // this.setting.notify.subscribe((newValue) => {
    //   // this.getSensors();
    //   // this.showDataTable = false;
    // });
  }

  ngOnInit(): void {
    this.getSensors();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.sensors.slice();
      })
    );
    this.editForm = this._formBuilder.group({
      editRows: this._formBuilder.array([]),
    });
  }
  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      // Get the sorted data and set it to the dataSource
      const data = this.dataSource.data.slice();
      if (!this.sort.active || this.sort.direction === '') {
        this.dataSource.data = data;
        return;
      }
      this.dataSource.data = data.sort((a, b) => {
        const isAsc = this.sort.direction === 'asc';
        return (
          this.compareFormGroups(a, b, this.sort.active) * (isAsc ? 1 : -1)
        );
      });
    });
  }
  ngOnDestroy(): void {
    if (this.subscribe) {
      this.subscribe.unsubscribe();
    }
  }

  displayFn(sensor: Sensor): string {
    return sensor && sensor.name ? sensor.name : '';
  }

  private _filter(name: string): Sensor[] {
    const filterValue = name.toLowerCase();
    return this.sensors.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  isNumber(n) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
  }

  getSensorsNotNull(sensors: Sensor[]) {
    this.sensorstoFilter = [];
    for (let sensor of sensors) {
      if (sensor.tenantId == null || sensor.tenantId == undefined) {
        sensor.tenantId = '';
      }
      this.sensorstoFilter.push(sensor);
    }
  }

  getSensors() {
    this.subscribe = this.sensorService.getSensors().subscribe((response) => {
      this.sensors = response['sensors'];
      if (this.store.get('tenant') && this.store.get('tenant') != 'all') {
        this.getSensorsNotNull(this.sensors);
        this.sensors = this.sensorstoFilter.filter((sensor) =>
          sensor.tenantId
            .toLowerCase()
            .startsWith(this.store.get('tenant').toLowerCase())
        );
      }
      this.allDataReceived = true;
    });
  }

  handleSelectSensor(event: string) {
    const sensorId = event;
    const sensor = this.sensors.find((s) => s.id == +sensorId);
    this.measurement = sensor.clazz + 'Value';
    this.sensorName = sensor.name;
    this.sensorId = sensor.id;
    this.selected = null;
    if ('_measurement' in sensor.categories) {
      this.measurement = sensor.categories['_measurement'];
    }

    this.showDataTable = false;
    this.getLatestData();
  } // end of handleSelectSensor

  createGroup(data: any = null) {
    if (!data) {
      data = {};
      this.columnsToDisplay.forEach((x) => {
        data[x] = null;
      });
    }

    const formGroup = new FormGroup({});
    Object.keys(data).forEach((key: string) => {
      formGroup.addControl(key, new FormControl(data[key]));
    });
    formGroup.addControl('action', new FormControl('existingRecord'));
    formGroup.addControl('isEditable', new FormControl(true));
    formGroup.addControl('isNewRow', new FormControl(false));
    if (!formGroup.controls['time_deleted']) {
      formGroup.addControl('time_deleted', new FormControl());
      formGroup.addControl('deleted_by', new FormControl());
    }

    return formGroup;
  }

  viewOriginal(row) {
    this.dialog.open(GenericDetailViewerComponent, {
      width: '700px',
      data: {
        jsonData: JSON.stringify(row, null, 2),
      },
    });
  }

  applyFilter(filterValue: string, columnName: string) {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const columnValue = data['value'][columnName];
      const filterSign = filter.trim().charAt(0);
      const filterNumber = Number(filter.trim().slice(1));
      const columnNumber = Number(columnValue);
      switch (filterSign) {
        case '>':
          return columnNumber > filterNumber;
        case '<':
          return columnNumber < filterNumber;
        default:
          return columnValue.toLowerCase().includes(filter.toLowerCase());
      }
    };
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  prepareTableFromResult(sensorValues: any[]) {
    this.sensorValues = sensorValues;
    const finalData = this.prepareFinalData(sensorValues);
    this.editForm = this.fb.group({
      editRows: this.fb.array(finalData.map((data) => this.createGroup(data))),
    }); // end of form group cretation
    this.dataSource = new MatTableDataSource(
      (this.editForm.get('editRows') as FormArray).controls
    );
    this.showDataTable = true;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  } //end of prepareTableFrom Result

  // Define a comparison function that compares two FormGroups by a specific field
  compareFormGroups(a: FormGroup, b: FormGroup, field: string): number {
    if (a.get(field).value < b.get(field).value) {
      return -1;
    }
    if (a.get(field).value > b.get(field).value) {
      return 1;
    }
    return 0;
  }
  prepareFinalData(sensorValues: any) {
    const finalData = [];
    this.columnsToDisplay = [];
    if (sensorValues != 'null') {
      // fix bug
      sensorValues = sensorValues.map((obj) => {
        for (const key in obj) {
          if (obj.hasOwnProperty(key) && obj[key] === null) {
            obj[key] = '';
          }
        }
        return obj;
      });
      sensorValues.forEach((sv) => {
        const currentSv = {};
        for (var key in sv) {
          if (sv.hasOwnProperty(key)) {
            if (this.isNumber(sv[key]) && key != 'sensorId') {
              sv[key] = parseFloat(sv[key]).toFixed(2);
            }
            if (sv[key] != null && key != 'sensorId') {
              currentSv[key] = sv[key];
              if (!this.columnsToDisplay.includes(key)) {
                this.columnsToDisplay.push(key);
              }
            }
          } // end if is valid property
        } // end for key

        finalData.push(currentSv);
      });
    } // end foreach
    if (!this.columnsToDisplay.includes('action')) {
      this.columnsToDisplay.push('action');
    }
    // remove iox_fields and mvalue
    this.columnsToDisplay = this.columnsToDisplay.filter(
      (item) => !this.fieldsToRemove.includes(item)
    );
    return finalData;
  } // prepare final Data

  EditSVO(VOFormElement, i) {
    VOFormElement.get('editRows').at(i).get('isEditable').patchValue(false);
  }

  SaveVO(VOFormElement, i) {
    VOFormElement.get('editRows').at(i).get('isEditable').patchValue(true);
    this.editData(this.dataSource.data[i].value);
  }

  CancelSVO(VOFormElement, i) {
    VOFormElement.get('editRows').at(i).get('isEditable').patchValue(true);
  }

  getData(cDate) {
    if (cDate.start) {
      this.dateFrom = moment(cDate.start).format(this.dtFormat);
      this.dateTo = moment(cDate.end).format(this.dtFormat);
      this.showDataTable = false;
      this.getDataFromTo();
    }
  }
  getDataFromTo() {
    this.subscribe = this.InfluxService.getData(
      this.measurement,
      this.sensorId,
      this.dateFrom,
      this.dateTo
    ).subscribe((data) => {
      this.prepareTableFromResult(data);
    });
  }

  getUpdatedData() {
    if (this.dateFrom) {
      this.getDataFromTo();
    } else {
      this.getLatestData();
    }
  }

  getLatestData() {
    this.subscribe = this.InfluxService.getLatestSensorValue(
      String(this.sensorId),
      this.measurement
    ).subscribe((result) => {
      this.prepareTableFromResult(result);
    });
  }

  handleInputClick(): void {
    this.myControl.reset();
    this.autocompleteTrigger.openPanel();
  }

  confirmDeleteDialog(i) {
    const row = this.dataSource.data[i].value;
    const dialogRef = this.dialog.open(ConfirmationInputComponent, {
      data: {
        name: '',
        title: 'Ignore Log',
        message: 'Confirm ignore log',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.confirmed) {
        this.InfluxService.delete(
          row.time,
          this.store.get('username'),
          this.measurement,
          this.sensorId,
          result.remarks
        ).subscribe((res) => {
          this.getUpdatedData();
        });
      }
    });
  }

  undoDelete(i) {
    const row = this.dataSource.data[i].value;
    const dialogRef = this.dialog.open(ConfirmationInputComponent, {
      data: {
        name: '',
        title: 'Undo Ignore Log',
        message: 'Confirm undo ignore log',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.confirmed) {
        this.InfluxService.patch(
          row.time,
          this.measurement,
          this.sensorId,
          this.store.get('username'),
          result.remarks
        ).subscribe((res) => {
          this.getUpdatedData();
        });
      }
    });
  }

  editData(row) {
    //fix bug edit
    for (const key in row) {
      if (row.hasOwnProperty(key) && row[key] === '') {
        delete row[key];
      }
    }
    const dialogRef = this.dialog.open(ConfirmationInputComponent, {
      data: {
        name: '',
        title: 'Edit Data',
        message: 'Confirm edit data',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.confirmed) {
        this.InfluxService.editData(
          row.time,
          this.measurement,
          this.sensorId,
          row,
          this.store.get('username'),
          result.remarks
        ).subscribe(
          (res) => {
            this.getUpdatedData();
            this.snackBar.open('Save Changes Successfully', 'Dismiss');
          },
          (error) =>
            this.snackBar.open("Can't Save Changes Invalid Data", 'Dismiss')
        );
      }
    });
  }

  exportToExcel(): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      this.sensorValues
    );
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const data: Blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const fileName: string = 'SensorsData.xlsx';
    FileSaver.saveAs(data, fileName);
  }

  exportToJson(): void {
    const json = JSON.stringify(this.sensorValues, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'SensorsData.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
}
