<h4>
  <strong *ngIf="item"
    >{{ "Edit Machinery" | ioxTranslate }} {{ item.name }}</strong
  >
</h4>
<div class="dt-mt-row" fxLayout="row wrap">
  <mat-card
    class="mat-elevation-z8"
    [fxFlex.gt-sm]="100"
    [fxFlex.gt-xs]="100"
    fxFlex="100"
  >
    <formly-form
      *ngIf="item"
      [form]="basicForm"
      [fields]="config.fields"
      [model]="item"
    >
    </formly-form>
    <mat-chip-list
      *ngIf="item && item.sensors"
      matPrefix
      #chipList
      class="m-t-24 m-l-16"
    >
      <mat-chip
        *ngFor="let sensor of item.sensors"
        class="bg-cyan-500 text-white"
        (removed)="removeAddedSensor(sensor.asset_items_id, sensor.sensor_id)"
      >
        {{ sensor.sensor_id }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
    <div class="dt-mt-row m-l-4" fxLayout="row wrap">
      <mat-form-field>
        <mat-label>{{ "Add Sensor" | ioxTranslate }}</mat-label>
        <input
          required
          type="number"
          matInput
          [matAutocomplete]="autoAddSensor"
          (keyup)="findSensor()"
          [(ngModel)]="newSensor"
        />
        <mat-autocomplete
          #autoAddSensor="matAutocomplete"
          (optionSelected)="addSensor($event.option.value)"
        >
          <mat-option *ngFor="let option of filteredSensors" [value]="option">
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-chip-list matPrefix #chipList class="m-t-24 m-l-16">
        <mat-chip
          *ngFor="let sensor of sensors"
          [color]="color"
          (removed)="remove(sensor)"
        >
          {{ sensor }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
    <mat-dialog-actions>
      <button mat-button (click)="navigateToMachinery()">
        {{ "Cancel" | ioxTranslate }}
      </button>
      <button mat-raised-button color="primary" type="submit" (click)="save()">
        {{ "Save Changes" | ioxTranslate }}
      </button>
    </mat-dialog-actions>
  </mat-card>
</div>
