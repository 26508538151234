import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SensorValue } from 'src/app/models/api/sensor-value';
import { EnvService } from '../core/env.service';

@Injectable({
  providedIn: 'root',
})
export class LastUpdateService {
  private lastUpdateUrl = this.env.jsApi + '/sensorValues/lastUpdate';
  constructor(private env: EnvService, private http: HttpClient) {}

  getLastUpdate() {
    return this.http.get<SensorValue[]>(this.lastUpdateUrl);
  }
}
