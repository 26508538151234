<breadcrumb></breadcrumb>
<div class="dt-mt-row" fxLayout="row wrap">
  <!-- Sensor's Monitor Section -->

  <div class="row">
    <div
      [fxFlex.gt-sm]="dashboardItem.size"
      [fxFlex.gt-xs]="dashboardItem.size"
      fxFlex="100"
      class="dt-mt-col"
      *ngFor="let dashboardItem of dashboardItems"
    >
      <mat-card *ngIf="dashboardItem.sensors.length > 0">
        <mat-card-subtitle>
          <strong> {{ dashboardItem.name | ioxTranslate }}</strong>
        </mat-card-subtitle>
        <mat-card-content>
          <mat-chip-list>
            <div *ngFor="let sensor of dashboardItem.sensors">
              <mat-chip
                class="cursor"
                class="bg-{{ sensor.lagUnit }}-500"
                selected="true"
                matTooltip="{{
                  sensor.lag !== 9999
                    ? 'Last Updated ' + sensor.lag + ' Minutes Before'
                    : 'Data Never Received'
                }}"
                (click)="getLastValue(sensor.id)"
              >
                {{ sensor.name }}
              </mat-chip>
            </div>
          </mat-chip-list>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div [fxFlex.gt-sm]="25" [fxFlex.gt-xs]="50" [fxFlex]="100" class="dt-mt-col">
    <a (click)="goToDevices('registered')" class="cursor">
      <mat-card
        [ngClass]="['text-white', 'bg-green-800']"
        class="mat-elevation-z7 wide"
      >
        <mat-card-content>
          <h2 class="center">{{ sensorCount | number }}</h2>
        </mat-card-content>
        <mat-card-title class="f-s-12 f-w-100 center"
          >Devices Configured</mat-card-title
        >
      </mat-card>
    </a>
  </div>
  <!-- End Sensor's Monitor Section -->
  <!-- <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="dt-mt-col">
    <mat-card class="mat-elevation-z7">
      <mat-card-content>
        <mat-form-field class="full">
          <mat-label>{{ "Data Refresh Rate" | ioxTranslate }}</mat-label>
          <mat-select
            [(ngModel)]="refreshRate"
            #rr
            (selectionChange)="refreshRateChange()"
          >
            <mat-option *ngFor="let rate of refreshRates" [value]="rate">
              {{ rate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-icon
          matTooltip="Green - Latest Data was received within Last 1 Hour
    Orange - Latest Data was received More Than 1 Hour
    Red - Latest Data was received More Than 24 Hours"
          matTooltipPosition="right"
          class="info-icon"
        >
          info
        </mat-icon>
      </mat-card-content>
    </mat-card>
  </div> -->
</div>

<!-- In Case Set Sampling Rate: Green - Last Data Received Before Sampling Rate Time * 3
Orange: Last Data Received More Than Sampling Rate Time * 3
Red: Last Data Received More Than Sampling Rate Time * 10 -->
