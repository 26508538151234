<breadcrumb></breadcrumb>
<mat-card>
  <mat-tab-group mat-stretch-tabs dynamicHeight #tabGroup>
    <mat-tab *ngFor="let tab of tabs">
      <mat-card-content>
        <ng-template mat-tab-label>{{ tab.label | ioxTranslate }}</ng-template>
        <div *ngIf="tab.label == 'Dashboards'" class="m-t-16">
          <mat-card-subtitle>
            {{ "Create New Device Dashboard" | ioxTranslate }}
          </mat-card-subtitle>
          <mat-card-content>
            <formly-form
              *ngIf="dashboard"
              [form]="basicForm"
              [fields]="config.fields"
              [model]="dashboard"
            >
            </formly-form>
          </mat-card-content>
          <mat-card-actions>
            <button mat-button (click)="goToDevices()">
              {{ "Cancel" | ioxTranslate }}
            </button>
            <button
              mat-raised-button
              color="primary"
              (click)="insertDashboard()"
            >
              {{ "Create Dashboard" | ioxTranslate }}
            </button>
          </mat-card-actions>
        </div>
        <div *ngIf="tab.label == 'Panels'" class="m-t-16">
          <mat-card-subtitle>
            <mat-radio-group
              aria-label="Select an option"
              [(ngModel)]="selectedRadio"
              (change)="radioChange($event)"
              color="primary"
            >
              <mat-radio-button value="add"
                >{{ "Add Panels in Device Dashboard" | ioxTranslate }}
              </mat-radio-button>
              <mat-radio-button value="delete">{{
                "Delete Panels in Device Dashboard" | ioxTranslate
              }}</mat-radio-button>
            </mat-radio-group>
          </mat-card-subtitle>
          <mat-card-content>
            <formly-form
              *ngIf="selectedRadio === 'add' && dashboard"
              [form]="panelForm"
              [fields]="configPanel.fields"
              [model]="panel"
            >
            </formly-form>

            <formly-form
              *ngIf="selectedRadio === 'delete' && dashboard"
              [form]="panelDeleteForm"
              [fields]="configDeletePanel.fields"
              [model]="panelDelete"
            >
            </formly-form>
          </mat-card-content>

          <mat-card-actions>
            <button mat-button (click)="goToDevices()">
              {{ "Cancel" | ioxTranslate }}
            </button>
            <button
              *ngIf="selectedRadio === 'add'"
              mat-raised-button
              color="primary"
              (click)="insertPanel()"
            >
              {{ "Add Panel" | ioxTranslate }}
            </button>
            <button
              *ngIf="selectedRadio === 'delete'"
              mat-raised-button
              color="primary"
              (click)="deletePanel()"
            >
              {{ "Delete Panel" | ioxTranslate }}
            </button>
          </mat-card-actions>
        </div>
      </mat-card-content>
    </mat-tab>
  </mat-tab-group>
</mat-card>
