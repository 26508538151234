import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LocalStorageService } from 'src/app/services/core/authentication/storage.service';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuthLayoutComponent implements OnInit {
  bgImage;
  tenantStatus = false;
  constructor(private store: LocalStorageService) {}

  ngOnInit() {
    this.bgImage = this.store.get('login_background_url');
    if (this.store.get('tenantStatus')) {
      this.tenantStatus = true;
    }
    // this.bgImage = '';
  }
}
