<mat-card>
  <mat-card-header>
    <i class="material-icons">storage</i>
    <mat-card-title>{{ config.title }}</mat-card-title>
    <mat-card-subtitle>{{ config.subTitle }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <!-- Table loading spinner -->
    <div class="table-loading-shade" *ngIf="isLoading">
      <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex="50">
      <div class="table-header">
        <!-- Add button -->
        <button mat-raised-button (click)="new()" style="margin-bottom: 15px" color="primary">
          <mat-icon>add</mat-icon> Add new
        </button>
      </div>
      <!-- The Table Pane-->
      <table mat-table scroll matSort matSortActive="id" matSortDisableClear matSortDirection="desc"
        [dataSource]="dataSource">
        <div></div>
        <ng-container matColumnDef="{{ col }}" *ngFor="let col of columnsToDisplay">
          <span *ngIf="col != 'actions'">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ col }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element[col] }}
            </td>
          </span>
          <span *ngIf="col == 'actions'">
            <th mat-header-cell *matHeaderCellDef>Actions</th>

            <td mat-cell *matCellDef="let row">
              <button mat-button (click)="delete(row)">
                <mat-icon aria-label="Edit">delete</mat-icon> Delete
              </button>
            </td>
          </span>
        </ng-container>
        <!-- Table actions -->

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay" [ngClass]="{ selected: selection.isSelected(row) }"
          (click)="handleSelect(row)"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
    </div>
    <div class="right-side" fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex="50">
      <mat-card-header>
        <mat-card-title>{{ config.detailsTitle }}</mat-card-title>
      </mat-card-header>
      <div class="form-wrapper">
        <form [formGroup]="form" (ngSubmit)="onSubmit(activeEntity)">
          <formly-form [form]="form" [fields]="config.fields" [model]="activeEntity"></formly-form>
          <button mat-raised-button color="primary" type="submit">
            <span *ngIf="isNewEntity">Create</span>
            <span *ngIf="!isNewEntity">Update</span>
          </button>
        </form>
      </div>
    </div>
  </mat-card-content>
</mat-card>