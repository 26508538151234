<h5 mat-dialog-title><strong>Change Password</strong></h5>
<mat-divider></mat-divider>
<mat-dialog-content class="m-t-8">
  <formly-form
    *ngIf="user"
    [form]="form"
    [fields]="config.fields"
    [model]="user"
  >
  </formly-form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="true" aria-label="Close">
    {{ "Cancel" | ioxTranslate }}
  </button>
  <button
    mat-raised-button
    (click)="changePassword()"
    color="primary"
    aria-label="Change Password"
  >
    {{ "Confirm Change Password" | ioxTranslate }}
  </button>
</mat-dialog-actions>
