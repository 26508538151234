<breadcrumb></breadcrumb>
<h4>
  <strong>{{ "Add New Product" | ioxTranslate }}</strong>
</h4>
<div class="dt-mt-row" fxLayout="row wrap">
  <mat-card
    class="mat-elevation-z8"
    [fxFlex.gt-sm]="100"
    [fxFlex.gt-xs]="100"
    fxFlex="100"
  >
    <formly-form
      *ngIf="item"
      [form]="basicForm"
      [fields]="config.fields"
      [model]="item"
    >
    </formly-form>
    <mat-dialog-actions>
      <button mat-button routerLink="/master-data/products">
        {{ "Cancel" | ioxTranslate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        (click)="insert()"
      >
        {{ "Add Product" | ioxTranslate }}
      </button>
    </mat-dialog-actions>
  </mat-card>
</div>
