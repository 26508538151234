import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Alarm } from 'src/app/models/api/alarm';
import { EnvService } from '../core/env.service';

@Injectable({
  providedIn: 'root',
})
export class AlarmService {
  private alarmUrl = this.env.jsApi + '/alarm';

  constructor(private http: HttpClient, private env: EnvService) {}

  getMessages() {
    return this.http.get<Alarm[]>(this.alarmUrl);
  }
}
