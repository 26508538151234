import { Constants } from './constants';
// import * as moment from 'moment';
import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
// import { PowerSensorValue } from '../../models/api/;
// import { WaterSensorValue } from '../models/water-sensor-value';

// @dynamic
export class AxTime {
  /**
   * Locatime day to UTC range
   * Converts the localTime presentation of a Day (e.g. 2017-12-06) to a from-to range
   * e.g (2017-12-05 17:00:00, 2017-12-06 16:59:59)
   * @param date local day
   * @returns [from, to] range
   */
  static ltDaytoUtcRange(date: string): string[] {
    return [this.lt2UtcFrom(date), this.lt2UtcTo(date)];
  }

  /**
   * Converts a local time to UTC
   * @param dt local date/time as a string
   * @returns timestring in UTC
   */
  static lt2Utc(dt: string): string {
    return moment(dt).utc().format(Constants.DATE_TIME_FMT_ISO_MOMENT);
  }

  /**
   * Converts a UTC time to local
   * @param dt local date/time as a string
   * @returns timestring in local
   */
  static Utc2lt(dt: string): string {
    return moment(dt).local().format(Constants.DATE_TIME_FMT_ISO_MOMENT);
  }

  /**
   * Converts a local time to the start of the day in UTC.
   * Note there can be a day change depending on the local UTC offset
   * @param from local time as a string
   * returns timestring in UTC
   */
  static lt2UtcFrom(from: string): string {
    return moment(from)
      .startOf('day')
      .utc()
      .format(Constants.DATE_TIME_FMT_ISO_MOMENT);
  }

  /**
   * Converts a local time to the end of the day in UTC.
   * Note there can be a day change depending on the local UTC offset
   * @param to
   * returns timestring in UTC
   */
  static lt2UtcTo(to: string): string {
    return moment(to)
      .endOf('day')
      .utc()
      .format(Constants.DATE_TIME_FMT_ISO_MOMENT);
  }

  /**
   * UTC to LocalTime Object.
   * converts a named field (aka key) of an JSON object
   * from UTC time (as it is stored in DB) to local time (of the Browser)
   * @param fieldName: string the field to be converted e.g. measuredAt
   * @param data: the object itself
   */
  static Utc2ltO(fieldName: string, data: {}): {} {
    if (data.hasOwnProperty(fieldName)) {
      const cDT = moment.utc(data[fieldName]);
      if (cDT.isValid) {
        const lDT = cDT.local().format('YYYY-MM-DD HH:mm:ss');
        data[fieldName] = lDT;
      }
    }
    return data;
  }

  /**
   * UTC to LocalTime Array.
   * converts a named field (aka key) of JSON objects in an array
   * from UTC time (as it is stored in DB) to local time (of the Browser)
   * @param fieldName: string the field to be converted e.g. measuredAt
   * @param data: the array of objects itself
   */
  static Utc2ltA(fieldName: string, data: any[]): any[] {
    for (const element of data) {
      if (element.hasOwnProperty(fieldName)) {
        const cDT = moment.utc(element[fieldName]);
        if (cDT.isValid) {
          const lDT = cDT.local().format('YYYY-MM-DD HH:mm:ss');
          element[fieldName] = lDT;
        }
      }
    }
    return data;
  }
  /**
   * timeAnalysis
   * converts savedAt and measuredAt fieldss
   * from UTC time (as it is stored in DB) to local time (of the Browser)
   * adds a new field timeDifference - to indicate the difference in seconds
   * @param data: the array of objects itself
   */
  static timeAnalysis(data: any[]): any[] {
    for (const element of data) {
      if (element.hasOwnProperty('measuredAt')) {
        const measuredAt = moment.utc(element.measuredAt);
        if (measuredAt.isValid) {
          element.measuredAt = measuredAt.local().format('YYYY-MM-DD HH:mm:ss');
        }
        const savedAt = moment.utc(element.savedAt);
        if (savedAt.isValid) {
          element.savedAt = savedAt.local().format('YYYY-MM-DD HH:mm:ss');
          element.timeDifference = savedAt.diff(measuredAt, 'seconds');
        }
      }
    }
    return data;
  }

  static setLocalTimes(balance: any[]): any {
    balance[0].measuredAt = moment.utc(balance[0].measuredAt).local().toDate();
    for (let i = 1; i < balance[1].sensorValues.length; i++) {
      balance[1].sensorValues[i].measuredAt = moment
        .utc(balance[1].sensorValues[i].measuredAt)
        .local()
        .toDate();
    }
  }
}
export class AxUtils {
  static makeRandom(lengthOfCode: number) {
    const possible =
      "qwedrtyuioplkjhgfdsazxcvbnmABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890,./;'[]=-)(*&^%$#@!~`";
    let text = '';
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  static isJson(testString: string) {
    try {
      JSON.parse(testString);
    } catch (e) {
      return false;
    }
    return true;
  }

  static handleServiceError(err: HttpErrorResponse) {
    switch (err.status) {
      case 401:
        console.error('not logged in');
        // this.router.navigate(['login']); // ToDo implement logiun module
        break;
      case 403:
        console.error('not authorized --> TODO: Not log out');
        // this.router.navigate(['/error/403']); // ToDo implement logiun module
        break;
      default:
        console.log(err);
        break;
    }
  }
}
