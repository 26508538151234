<button class="mat-icon-button close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>

<!-- <h3>Sensor Details</h3> -->
<mat-dialog-content>
  <div class="dt-mt-row" fxLayout="row wrap">
    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
      <mat-tab-group
        [selectedIndex]="dataDialog.sensorDetails ? 1 : 0"
        mat-stretch-tabs
        #tabGroup
        (selectedIndexChange)="getActiveTab($event)"
      >
        <mat-tab *ngIf="dataDialog.sensorDetails">
          <mat-card-content>
            <ng-template mat-tab-label>{{
              "Sensor Details" | ioxTranslate
            }}</ng-template>
            <table mat-table [dataSource]="dataSourceSensor" matSort>
              <ng-container matColumnDef="field">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  <span>{{ "FIELD" | ioxTranslate }}</span>
                </th>
                <td mat-cell *matCellDef="let data">
                  <b>{{ data.field | titlecase | ioxTranslate }}</b>
                </td>
              </ng-container>

              <ng-container matColumnDef="fValue">
                <th
                  class="header name-column"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <span>{{ "VALUE" | ioxTranslate }}</span>
                </th>
                <td mat-cell *matCellDef="let data">
                  {{ data.fValue | ioxTranslate }}
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="columnsToDisplay; sticky: true"
              ></tr>
              <tr
                mat-row
                *matRowDef="let request; columns: columnsToDisplay"
              ></tr>
            </table>
          </mat-card-content>
        </mat-tab>
        <mat-tab *ngIf="dataDialog.sensorDetails || dataDialog.data">
          <mat-card-content>
            <ng-template *ngIf="dataDialog.sensorDetails" mat-tab-label>
              {{ "Last Sensor Data" | ioxTranslate }}</ng-template
            >
            <ng-template *ngIf="!dataDialog.sensorDetails" mat-tab-label>{{
              "Table" | ioxTranslate
            }}</ng-template>
            <table mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="field">
                <th
                  [style.width.%]="26"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <span>{{ "FIELD" | ioxTranslate }}</span>
                </th>
                <td mat-cell *matCellDef="let data">
                  <b>{{ data.field | titlecase | ioxTranslate }}</b>
                </td>
              </ng-container>

              <ng-container matColumnDef="fValue">
                <th
                  class="header name-column"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <span>{{ "VALUE" | ioxTranslate }}</span>
                </th>
                <td mat-cell *matCellDef="let data">
                  {{ data.fValue | ioxTranslate }}
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="columnsToDisplay; sticky: true"
              ></tr>
              <tr
                mat-row
                *matRowDef="let request; columns: columnsToDisplay"
              ></tr>
            </table>
            <!-- add sensor value table -->
            <table
              class="m-t-8"
              *ngIf="dataDialog.dataSensorValue"
              mat-table
              [dataSource]="dataSourceSensorValue"
            >
              <ng-container matColumnDef="field">
                <th *matHeaderCellDef [style.width.%]="26"></th>
                <td mat-cell *matCellDef="let data">
                  <b>{{ data.field | titlecase | ioxTranslate }}</b>
                </td>
              </ng-container>
              <ng-container matColumnDef="fValue">
                <th *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let data">
                  {{ data.fValue | ioxTranslate }}
                </td>
              </ng-container>
              <tr
                mat-header-row
                class="header-sub"
                *matHeaderRowDef="columnsToDisplay; sticky: true"
              ></tr>
              <tr
                mat-row
                *matRowDef="let request; columns: columnsToDisplay"
              ></tr>
            </table>
            <!-- end add sensor value table -->
          </mat-card-content>
        </mat-tab>
        <mat-tab>
          <mat-card-content>
            <ng-template *ngIf="dataDialog.sensorDetails" mat-tab-label>
              {{ "Last Sensor Data(Json)" | ioxTranslate }}</ng-template
            >
            <ng-template *ngIf="!dataDialog.sensorDetails" mat-tab-label>
              {{ "Json" | ioxTranslate }}</ng-template
            >
            <!-- <ng-template mat-tab-label>Json</ng-template> -->
            <div class="json-class" *ngIf="showJson">
              <ngx-codemirror
                [(ngModel)]="dataDialog.jsonData"
                #jsonData1
                [autoFocus]="true"
                [options]="options"
              >
                {{ dataDialog.jsonData }}</ngx-codemirror
              >
            </div>
          </mat-card-content>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</mat-dialog-content>
