<breadcrumb></breadcrumb>
<div class="dt-mt-row" fxLayout="row wrap">
  <div
    [fxFlex.gt-sm]="25"
    [fxFlex.gt-xs]="25"
    fxFlex="100"
    class="dt-mt-col"
    *ngFor="let site of sites"
  >
    <mat-card class="mat-elevation-z7">
      <mat-card-subtitle>
        <strong> {{ site.name | ioxTranslate }}</strong>
      </mat-card-subtitle>

      <mat-card-content>
        <div
          [fxFlex.gt-sm]="50"
          [fxFlex.gt-xs]="50"
          fxFlex="100"
          class="dt-mt-col"
        >
          <div class="location">
            <mat-icon>location_on</mat-icon>
            <span>{{ site.location | ioxTranslate }}</span>
          </div>
          <br />
          <mat-chip-list>
            <mat-chip
              class="cursor bg-green-500"
              selected="true"
              matTooltip=""
              (click)="buttonClick(site.title)"
            >
              {{ site.name }}
            </mat-chip>
          </mat-chip-list>
        </div>
        <img
          [fxFlex.gt-sm]="50"
          [fxFlex.gt-xs]="50"
          fxFlex="100"
          class="dt-mt-col"
          src="{{ site.img_url }}"
          alt="Button Image"
          width="100"
          height="100"
        />
      </mat-card-content>
    </mat-card>
  </div>
</div>
