import { Role } from './user-roles';

export class User {
    public id: number;
    public firstName: string;
    public lastName: string;
    public userName: string;
    public emailAddress: string;
    public apiKey: string;
    public password: string;
    public userRoles: Role[];
    public newPassword: string;
    public confirmedPassword: string;

    constructor() {
        this.id = null;
        this.firstName = '';
        this.lastName = '';
        this.userName = '';
        this.emailAddress = '';
        this.apiKey = '';
        this.password = '';
        this.userRoles = [{ id: null, role: 'Admin' }];
        this.newPassword = '';
        this.confirmedPassword = '';
    }

}
