import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IoxLingo, IoxTranslationItem } from 'src/app/models/api/ioxlingo';
import { LocalStorageService } from '../core/authentication/storage.service';
import { EnvService } from '../core/env.service';

@Injectable({
  providedIn: 'root',
})
export class IoxLanguageService {
  // private url = 'http://localhost:5000/' + 'api/v1/';
  private url = this.env.languageApi;
  private translations: IoxTranslationItem[] = [];
  private haveData = false;
  private allLanguages: IoxLingo[];
  private allTranslations: IoxTranslationItem[];
  constructor(
    private http: HttpClient,
    private env: EnvService,
    private store: LocalStorageService
  ) {}

  fetchTranslations(ioxApp: string, locale: string) {
    this.haveData = false;
    const lingoUrl = `${this.url}app-translations/by_name/${ioxApp}/${locale}`;
    return this.http.get<IoxTranslationItem[]>(lingoUrl);
  }

  getLanguages() {
    return this.http.get<IoxLingo[]>(`${this.url}/languages`);
  }

  // getTranslations(url: string) {
  //   return this.http.get<IoxTranslationItem[]>(url);
  // }

  set(translations): IoxTranslationItem[] {
    this.setTranslations(translations);
    return translations;
  }

  // fetchLanguages(): IoxLingo[] {
  //   const lingoUrl = `${this.url}/languages`;
  //   // TODO: change to a propper API call to the new language service
  //   // return this.http.get<Language[]>(lingoUrl);
  //   return this.allLanguages;
  // }

  setTranslations(translations: IoxTranslationItem[]) {
    this.translations = [...translations];
    this.haveData = true;
    this.store.set('translations', JSON.stringify(this.translations));
  }

  ready() {
    return this.haveData;
  }

  getTranslationByKey(key: string) {
    if (this.translations && this.translations.length > 0) {
      const found = this.translations.find((i18nItem) => {
        i18nItem.label === key;
      });
      return found && found.translation != ''
        ? found.translation
        : 'No Translation AVailable';
    } else {
      return 'No Translations Available';
    }
  }

  getTranslation(label: string) {
    if (!this.translations || this.translations.length == 0) {
      this.translations = JSON.parse(this.store.get('translations'));
    }

    const found = this.translations.find(
      (i18nItem) => i18nItem.label === label && i18nItem.translation !== ''
    );
    return found ? found.translation : label;
  }
}
