import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Sensor } from 'src/app/models/api/sensor';
import { SensorService } from 'src/app/services/api/sensor.service';
import { CruditorFormConfiguration } from 'src/app/shared/components/cruditor-form/cruditor-form.configuration';
import { IoxTranslatePipe } from 'src/app/shared/pipes/iox-translate.pipe';
@Component({
  selector: 'app-edit-device',
  templateUrl: './edit-device.component.html',
  styleUrls: ['./edit-device.component.scss'],
})
export class EditDeviceComponent implements OnInit {
  @Input() sensor: Sensor;
  public isNewDevice = false;
  public saveInForm = true;
  public config: CruditorFormConfiguration = {
    detailsTitle: 'Sensor Details',
    insertMessage: this.translatePipe.transform('Sensor Successfully Added'),
    updateMessage: this.translatePipe.transform('Sensor Successfully Updated'),
    deleteMessage: this.translatePipe.transform('Sensor Successfully Deleted'),
    fields: [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-4',
            type: 'input',
            key: 'id',
            templateOptions: {
              label: this.translatePipe.transform('Sensor Id'),
              disabled: true,
            },
          },
          {
            className: 'col-sm-4',
            type: 'input',
            key: 'name',
            templateOptions: {
              label: this.translatePipe.transform('Sensor Name'),
              required: true,
            },
            // expressionProperties: {
            //   'templateOptions.disabled': '!model.firstName',
            // },
          },
          {
            className: 'col-sm-4',
            type: 'select',
            key: 'deviceState.state',
            templateOptions: {
              label: this.translatePipe.transform('State'),
              required: true,
              options: [
                { id: 'registered', name: 'Registered' },
                { id: 'unregistered', name: 'Unregistered' },
              ],
              labelProp: 'name',
              valueProp: 'id',
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'tenantId',
            templateOptions: {
              label: this.translatePipe.transform('Tenant Id'),
            },
          },
          {
            className: 'col-sm-6',
            type: 'select',
            key: 'clazz',
            templateOptions: {
              label: this.translatePipe.transform('Type'),
              options: [
                { id: 'Sensor', name: 'Sensor' },
                { id: 'PowerSensor', name: 'PowerSensor' },
                { id: 'WaterSensor', name: 'WaterSensor' },
                { id: 'AirQualitySensor', name: 'AirQualitySensor' },
                {
                  id: 'ChillerTemperatureSensor',
                  name: 'ChillerTemperatureSensor',
                },
              ],
              labelProp: 'name',
              valueProp: 'id',
              required: true,
              // description: 'This is a custom field type.',
            },
            // wrappers: ['form-field'],
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-3',
            type: 'input',
            key: 'oemId',
            templateOptions: {
              type: 'number',
              label: this.translatePipe.transform('Oem Id'),
              // max: 99999,
              // min: 0,
              // pattern: '\\d{5}',
            },
          },
          {
            className: 'col-sm-3',
            type: 'input',
            key: 'valueUnit',
            templateOptions: {
              label: this.translatePipe.transform('Value Unit'),
            },
          },
          {
            className: 'col-sm-3',
            type: 'input',
            key: 'maxValue',
            templateOptions: {
              type: 'number',
              label: this.translatePipe.transform('Max Value'),
            },
          },
          {
            className: 'col-sm-3',
            type: 'input',
            key: 'minValue',
            templateOptions: {
              type: 'number',
              label: this.translatePipe.transform('Min Value'),
            },
          },
          {
            className: 'col-sm-4',
            type: 'input',
            key: 'resolution',
            templateOptions: {
              type: 'number',
              label: this.translatePipe.transform('Resolution'),
            },
          },
          {
            className: 'col-sm-4',
            type: 'input',
            key: 'samplingRate',
            templateOptions: {
              type: 'number',
              label: this.translatePipe.transform('Sampling Rate (Minutes)'),
            },
          },
          {
            className: 'col-sm-4',
            type: 'input',
            key: 'timeZone',
            templateOptions: {
              label: this.translatePipe.transform('Time Zone'),
            },
          },
          {
            className: 'col-sm-12',
            type: 'input',
            key: 'secret',
            templateOptions: {
              label: this.translatePipe.transform('Secret'),
              disabled: true,
            },
          },
        ],
      },
    ],
  };
  constructor(
    public sensorService: SensorService,
    private translatePipe: IoxTranslatePipe
  ) {}

  ngOnInit() {}
}
