import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpEventType,
  HttpResponse,
} from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { EnvService } from '../core/env.service';

interface UploadStatus {
  [key: string]: { progress: Observable<number> };
}
@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private http: HttpClient, private env: EnvService) {}

  public upload(formData) {
    const url = this.env.uploadUrl;
    return this.http.post<any>(url, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  // public upload(files: Set<File>): UploadStatus {
  //   const url = this.env.uploadUrl;
  //   // const url = 'http://203.155.13.161:28112/upload'; // FIXME: Use the lin ebelow once the service will be available
  //   const status: UploadStatus = {};

  //   files.forEach((file) => {
  //     const formData: FormData = new FormData();
  //     formData.append('file', file, file.name);
  //     const req = new HttpRequest('POST', url, formData, {
  //       reportProgress: true,
  //     });

  //     const progress = new Subject<number>();

  //     this.http.request(req).subscribe((event) => {
  //       if (event.type === HttpEventType.UploadProgress) {
  //         const percentDone = Math.round((100 * event.loaded) / event.total);
  //         progress.next(percentDone);
  //       } else if (event instanceof HttpResponse) {
  //         progress.complete();
  //       }
  //     });

  //     status[file.name] = {
  //       progress: progress.asObservable(),
  //     };
  //   }); // end forEach

  //   return status;
  // }
}
