import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import * as CodeMirror from 'codemirror';

@Component({
  selector: 'app-details-device',
  templateUrl: './details-device.component.html',
  styleUrls: ['./details-device.component.scss'],
})
export class DetailsDeviceComponent
  implements OnInit, OnChanges, AfterViewInit {
  @Input() dataSource: any;
  @Input() columnsToDisplay: [];
  @Input() jsonData: string;
  @Input() view: string;
  @ViewChild('jsonData1') private codeEditor;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public options = {
    // lineNumbers: true,
    lineWrapping: true,
    theme: 'material',
    mode: 'javascript',
    readOnly: true,
    autoRefresh: true,

    // scrollbarStyle: 'null',
  };
  constructor(
    private domSanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.dataSource) {
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.dataSource.sort = this.sort;
    }
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
}
