import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { BarGuagePanel } from 'src/app/models/api/grafana-dashboards/bar-guage-panel';
import { GfDashboard } from 'src/app/models/api/grafana-dashboards/gf-dashboard';
import { GfNewPanel } from 'src/app/models/api/grafana-dashboards/gf-new-panel';
import { LinePanel } from 'src/app/models/api/grafana-dashboards/line-panel';
import { StatPanel } from 'src/app/models/api/grafana-dashboards/stat-panel';
import { Sensor } from 'src/app/models/api/sensor';
import { GrafanaService } from 'src/app/services/api/grafana.service';
import { SensorValueService } from 'src/app/services/api/sensor-value.service';
import { EnvService } from 'src/app/services/core/env.service';
import { CruditorFormConfiguration } from 'src/app/shared/components/cruditor-form/cruditor-form.configuration';
import { IoxTranslatePipe } from 'src/app/shared/pipes/iox-translate.pipe';
@Component({
  selector: 'app-add-gf-dashboard',
  templateUrl: './add-gf-dashboard.component.html',
  styleUrls: ['./add-gf-dashboard.component.scss'],
})
export class AddGfDashboardComponent implements OnInit {
  sensors: Array<Sensor>;
  tabs = [
    {
      label: 'Dashboards',
      content: ``,
    },
    {
      label: 'Panels',
      content: ``,
    },
  ];
  public basicForm = new FormGroup({});
  public panelForm = new FormGroup({});
  public panelDeleteForm = new FormGroup({});
  public dashboard = new GfDashboard();
  dashboardsList = [];
  panel = new GfNewPanel();
  panelDelete = new GfNewPanel();
  measurement: string;
  params: Array<object>;
  selectedRadio = 'add';
  panelsListDelete: any;
  public config: CruditorFormConfiguration = {
    detailsTitle: 'Dashboard Details',
    insertMessage: this.translatePipe.transform('Dashboard Added Successfully'),
    updateMessage: this.translatePipe.transform(
      'Dashboard Successfully Updated'
    ),
    deleteMessage: this.translatePipe.transform(
      'Dashboard Successfully Deleted'
    ),
    fields: [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-12',
            type: 'input',
            key: 'dashboard.title',
            templateOptions: {
              label: this.translatePipe.transform('Dashboard Title'),
              required: true,
            },
          },
        ],
      },
    ],
  };
  public configPanel: CruditorFormConfiguration = {
    detailsTitle: 'Panel Details',
    insertMessage: 'Panel Added Successfully',
    updateMessage: 'Panel Successfully Updated',
    deleteMessage: 'Panel Successfully Deleted',
    fields: [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-12',
            type: 'select',
            key: 'title',
            templateOptions: {
              label: this.translatePipe.transform('Select Dashboard Title'),
              required: true,
              options: [],
              labelProp: 'title',
              valueProp: 'uid',
            },
          },
          {
            className: 'col-sm-12',
            type: 'input',
            key: 'panelTitle',
            templateOptions: {
              label: this.translatePipe.transform('Panel Title'),
              required: true,
            },
          },
          {
            className: 'col-sm-6',
            type: 'select',
            key: 'graphType',
            templateOptions: {
              label: this.translatePipe.transform('Graph Type'),
              required: true,
              options: [
                { id: 'line', name: 'Line Graph' },
                { id: 'stat', name: 'Stat' },
                { id: 'barguage', name: 'Bar Guage' },
              ],
              labelProp: 'name',
              valueProp: 'id',
            },
          },
          {
            className: 'col-sm-6',
            type: 'autocomplete',
            key: 'sensorId',
            templateOptions: {
              label: this.translatePipe.transform('Sensor Id'),
              required: true,
              filter: (term) =>
                of(
                  term
                    ? this.filter(term)
                    : this.sensors.map((x) => x.id).slice()
                ),
              change: (field, $event) => {
                this.getLastSensorValue(field.form.controls.sensorId.value);
              },
            },
          },
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'measurement',
            templateOptions: {
              label: this.translatePipe.transform('Measurement'),
              required: true,
            },
          },
          {
            className: 'col-sm-6',
            type: 'select',
            key: 'params',
            templateOptions: {
              label: this.translatePipe.transform('Params'),
              required: true,
              options: [],
              labelProp: 'name',
              valueProp: 'name',
            },
          },
        ],
      },
    ],
  };

  public configDeletePanel: CruditorFormConfiguration = {
    detailsTitle: 'Panel Details',
    insertMessage: this.translatePipe.transform('Panel Added Successfully'),
    updateMessage: this.translatePipe.transform('Panel Successfully Updated'),
    deleteMessage: this.translatePipe.transform('Panel Successfully Deleted'),
    fields: [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-12',
            type: 'select',
            key: 'title',
            templateOptions: {
              label: this.translatePipe.transform('Select Dashboard Title'),
              required: true,
              options: [],
              labelProp: 'title',
              valueProp: 'uid',
              change: (field, $event) => {
                this.getPanelsList(field.form.controls.title.value);
              },
            },
          },
          {
            className: 'col-sm-12',
            type: 'select',
            key: 'panelTitle',
            templateOptions: {
              label: this.translatePipe.transform('Select Panel Name'),
              required: true,
              options: [],
              labelProp: 'name',
              valueProp: 'index',
            },
          },
        ],
      },
    ],
  };
  constructor(
    private grafana: GrafanaService,
    private env: EnvService,
    private snackBar: MatSnackBar,
    private route: Router,
    private translatePipe: IoxTranslatePipe
  ) {}

  ngOnInit() {
    this.sensors = JSON.parse(localStorage.getItem('sensors'));
    this.getAllGfDashboards();
  }

  getAllGfDashboards() {
    this.grafana.getDashboard().subscribe((res) => {
      res.forEach((dashboard: any) => {
        if (dashboard.tags.includes(this.env.app)) {
          this.dashboardsList.push(dashboard);
        }
      });
      this.configPanel.fields[0]['fieldGroup'][0]['templateOptions'][
        'options'
      ] = this.dashboardsList;
      this.configDeletePanel.fields[0]['fieldGroup'][0]['templateOptions'][
        'options'
      ] = this.dashboardsList;
    }); // end of go through the grafana dashbaord
  }

  filter(value: string) {
    return this.sensors
      .map((x) => x.id)
      .filter((sensor) => sensor.toString().includes(value));
  }

  findSensorById(sensorId: Number): any {
    const storedSensorsJSON = localStorage.getItem('sensors');
    if (storedSensorsJSON) {
      const sensorsArray: any[] = JSON.parse(storedSensorsJSON);
      const foundSensor = sensorsArray.find((sensor) => sensor.id === sensorId);
      return foundSensor;
    } else {
      return null;
    }
  }

  getLastSensorValue(sensorId: string) {
    let sensorDetails = this.findSensorById(Number(sensorId));
    if (sensorDetails && sensorDetails['lastValue']) {
      let sensorValues = sensorDetails['lastValue']['sensorValueEntries'];
      this.panel.measurement = sensorValues.find(
        (x) => x.propName === '_measurement'
      )['propValue'];
      this.panelForm.get('measurement').setValue(this.panel.measurement);
      // getall params
      let paramsArry = sensorValues.filter(
        (x) => x.propName !== '_measurement'
      );
      this.params = [];
      if (paramsArry) {
        paramsArry.forEach((element) => {
          this.params.push({ name: element['propName'] });
        });
      }
      this.configPanel.fields[0]['fieldGroup'][5]['templateOptions'][
        'options'
      ] = this.params;
    }
  }

  radioChange(event) {
    this.selectedRadio = event.value;
  }

  insertDashboard() {
    if (this.basicForm.valid) {
      this.grafana.createDasboard(this.dashboard).subscribe(
        (newEntity) => {
          this.snackBar.open(this.config.insertMessage, 'Dismiss');
          this.dashboard = new GfDashboard();
          this.getAllGfDashboards();
        },
        (error) => {
          this.snackBar.open(
            'Add Dasboard Failed due to Error Code: ' +
              error.error.code +
              ' ' +
              error.error.message,
            'Dismiss'
          );
        }
      );
    }
  }

  insertPanel() {
    if (this.panelForm.valid) {
      switch (this.panel.graphType) {
        case 'line':
          this.prepareLineGraph();
          break;
        case 'stat':
          this.prepareStatGraph();
          break;
        case 'barguage':
          this.prepareBarGuageGraph();
          break;
        default:
          console.log('Please Choose Graph Type.');
          break;
      }
    }
  }

  getPanelsList(dashboardName: string) {
    this.grafana.getDashboardByName(dashboardName).subscribe((details) => {
      this.panelsListDelete = details['dashboard'];
      if (this.panelsListDelete['panels']) {
        let panels = this.panelsListDelete['panels'];
        // get only title list with index
        let panelsList = [];
        panels.forEach((value, index) => {
          panelsList.push({
            index: index,
            name: value['title'],
          });
        });
        this.configDeletePanel.fields[0]['fieldGroup'][1]['templateOptions'][
          'options'
        ] = panelsList;
      }
    });
  }

  prepareStatGraph() {
    this.grafana.getDashboardByName(this.panel.title).subscribe((details) => {
      let dashboardDetails = details['dashboard'];
      let graphToAdd = new StatPanel();
      graphToAdd.targets[0]['measurement'] = this.panel.measurement;
      graphToAdd.targets[0]['select'][0][0]['params'][0] = this.panel.params;
      graphToAdd.targets[0]['tags'][0]['value'] =
        this.panel.sensorId.toString();
      graphToAdd.title = this.panel.panelTitle;
      if ('panels' in dashboardDetails) {
        dashboardDetails['panels'] = this.setGridAndId(
          dashboardDetails['panels'],
          graphToAdd
        );
      } else {
        dashboardDetails['panels'] = [graphToAdd];
      }
      dashboardDetails = {
        dashboard: dashboardDetails,
      };
      this.addPanel(dashboardDetails);
    });
  }

  prepareBarGuageGraph() {
    this.grafana.getDashboardByName(this.panel.title).subscribe((details) => {
      let dashboardDetails = details['dashboard'];
      let graphToAdd = new BarGuagePanel();
      graphToAdd.targets[0]['measurement'] = this.panel.measurement;
      graphToAdd.targets[0]['select'][0][0]['params'][0] = this.panel.params;
      graphToAdd.targets[0]['tags'][0]['value'] =
        this.panel.sensorId.toString();
      graphToAdd.title = this.panel.panelTitle;
      if ('panels' in dashboardDetails) {
        dashboardDetails['panels'] = this.setGridAndId(
          dashboardDetails['panels'],
          graphToAdd
        );
      } else {
        dashboardDetails['panels'] = [graphToAdd];
      }
      dashboardDetails = {
        dashboard: dashboardDetails,
      };
      this.addPanel(dashboardDetails);
    });
  }

  prepareLineGraph() {
    this.grafana.getDashboardByName(this.panel.title).subscribe((details) => {
      let dashboardDetails = details['dashboard'];
      let graphToAdd = new LinePanel();
      graphToAdd.targets[0]['measurement'] = this.panel.measurement;
      graphToAdd.targets[0]['select'][0][0]['params'][0] = this.panel.params;
      graphToAdd.targets[0]['tags'][0]['value'] =
        this.panel.sensorId.toString();
      graphToAdd.title = this.panel.panelTitle;
      // pushhhh
      if ('panels' in dashboardDetails) {
        dashboardDetails['panels'] = this.setGridAndId(
          dashboardDetails['panels'],
          graphToAdd
        );
      } else {
        dashboardDetails['panels'] = [graphToAdd];
      }
      dashboardDetails = {
        dashboard: dashboardDetails,
      };
      this.addPanel(dashboardDetails);
    });
  }

  setGridAndId(panelsList: Array<object>, graphToAdd: LinePanel | StatPanel) {
    // get count of panels
    let lenArray = panelsList.length;
    if (lenArray % 2 == 0) {
      // is even
      graphToAdd.gridPos['y'] = (lenArray / 2) * 11;
    } else {
      graphToAdd.gridPos['x'] = 12;
      graphToAdd.gridPos['y'] =
        (11 / 4) * (2 * lenArray + Math.pow(-1, lenArray) - 1);
    }
    // get latest id
    if (lenArray > 0) {
      graphToAdd.id = panelsList[lenArray - 1]['id'] + 1;
    } else {
      graphToAdd.id = 1;
    }
    panelsList.push(graphToAdd);
    return panelsList;
  }

  addPanel(details) {
    this.grafana.addPanelInDashboard(details).subscribe(
      (newEntity) => {
        this.snackBar.open(this.configPanel.insertMessage, 'Dismiss');
        this.dashboard = new GfDashboard();
      },
      (error) => {
        this.snackBar.open(
          'Add Panel Failed due to Error Code: ' +
            error.error.code +
            ' ' +
            error.error.message,
          'Dismiss'
        );
      }
    );
  }

  deletePanel() {
    if (this.panelDeleteForm.valid) {
      if ('panels' in this.panelsListDelete) {
        this.panelsListDelete['panels'].splice(this.panelDelete.panelTitle, 1);
      }
      let dashboardDetails = {
        dashboard: this.panelsListDelete,
      };
      this.removePanel(dashboardDetails);
    }
  }

  goToDevices(state: string) {
    this.route.navigate(['/devices']);
  }

  removePanel(details) {
    this.grafana.addPanelInDashboard(details).subscribe(
      (newEntity) => {
        this.snackBar.open(this.configPanel.deleteMessage, 'Dismiss');
        this.dashboard = new GfDashboard();
      },
      (error) => {
        this.snackBar.open(
          'Delete Panel Failed due to Error Code: ' +
            error.error.code +
            ' ' +
            error.error.message,
          'Dismiss'
        );
      }
    );
  }
}
