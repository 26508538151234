export class WorkStoppage {
  meter_before: number;
  meter_after: number;
  use_fuel: number;
  reason_stop: string;

  constructor() {
    this.meter_before = null;
    this.meter_after = null;
    this.use_fuel = null;
    this.reason_stop = null;
  }
}
