import { GfDashboardDetails } from './gf-dashboard-details';

export class GfDashboard {
  dashboard: GfDashboardDetails;
  folderId: number;
  overwrite: boolean;

  constructor() {
    this.dashboard = new GfDashboardDetails();
    this.folderId = 0;
    this.overwrite = false;
  }
}
