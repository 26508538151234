<h4>
  <strong>{{ "Edit Floating Barge" | ioxTranslate }} {{ asset.name }}</strong>
</h4>
<div class="dt-mt-row" fxLayout="row wrap">
  <mat-card
    class="mat-elevation-z8"
    [fxFlex.gt-sm]="100"
    [fxFlex.gt-xs]="100"
    fxFlex="100"
  >
    <formly-form
      *ngIf="asset"
      [form]="basicForm"
      [fields]="config.fields"
      [model]="asset"
    >
    </formly-form>
    <mat-dialog-actions>
      <button mat-button routerLink="/master-data/floating-barge/">
        {{ "Cancel" | ioxTranslate }}
      </button>
      <button mat-raised-button color="primary" type="submit" (click)="save()">
        {{ "Save Changes" | ioxTranslate }}
      </button>
    </mat-dialog-actions>
  </mat-card>
</div>
