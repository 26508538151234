import { DtoClass } from './dto-class';
import { DeviceState } from './device-state';
/**
 * Class for DTO
 *
 * @author AMDIS Media and IT Services
 *
 * @date 12.2.2018
 */

export class Device extends DtoClass {
  public oemId: number;
  public tenantId: string;
  public type: string;
  public secret: string;
  public deviceState: DeviceState;
  public appStateMachine: string;
  public appState: string;
  public location: string;

  constructor() {
    super();
  }

  public getOemId(): number {
    return this.oemId;
  }

  public setOemId(oemId: number): void {
    this.oemId = oemId;
  }
  public getTenantId(): string {
    return this.tenantId;
  }

  public setTenantId(tenantId: string): void {
    this.tenantId = tenantId;
  }

  public getType(): string {
    return this.type;
  }

  public setType(type: string): void {
    this.type = type;
  }

  public getSecret(): string {
    return this.secret;
  }

  public setSecret(secret: string): void {
    this.secret = secret;
  }

  public getDeviceState(): DeviceState {
    return this.deviceState;
  }

  public setDeviceState(deviceState: DeviceState): void {
    this.deviceState = deviceState;
  }

  public getAppStateMachine(): string {
    return this.appStateMachine;
  }

  public setAppStateMachine(appStateMachine: string): void {
    this.appStateMachine = appStateMachine;
  }

  public getAppState(): string {
    return this.appState;
  }

  public setAppState(appState: string): void {
    this.appState = appState;
  }

  public getLocation(): string {
    return this.location;
  }

  public setLocation(location: string): void {
    this.location = location;
  }
}
