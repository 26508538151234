<mat-card-subtitle>
  <!-- <strong> Categories</strong> -->
  <button
    mat-button
    *ngIf="!newCategory"
    color="primary"
    (click)="addNewCategory()"
  >
    <mat-icon>add</mat-icon> {{ "Add New Category" | ioxTranslate }}
  </button>
</mat-card-subtitle>

<div *ngIf="newCategory" fxLayout="row" fxLayout="row wrap" class="dt-mt-row">
  <div fxFlex="100" class="dt-mt-col b-b-4">
    <mat-form-field>
      <mat-label>{{ "Category Name" | ioxTranslate }}</mat-label>
      <input matInput required [(ngModel)]="categoryName" />
    </mat-form-field>
    <mat-form-field class="m-r-8">
      <mat-label>{{ "Category Value" | ioxTranslate }}</mat-label>
      <input matInput [(ngModel)]="categoryValue" />
    </mat-form-field>
    <button
      mat-button
      (click)="handleUnsert()"
      color="primary"
      aria-label="Add"
    >
      <mat-icon>add</mat-icon>{{ "Add" | ioxTranslate }}
    </button>
    <button mat-button (click)="close()" aria-label="Close" color="warn">
      <mat-icon>close</mat-icon>{{ "Close" | ioxTranslate }}
    </button>
  </div>
</div>
<!-- </div> -->
<mat-card-content>
  <form [formGroup]="catForm" autocomplete="off">
    <ng-container formArrayName="catRows">
      <mat-table #table *ngIf="dataSource" [dataSource]="dataSource">
        <ng-container matColumnDef="categoryName">
          <mat-header-cell *matHeaderCellDef>
            {{ "CATEGORY NAME" | ioxTranslate }}</mat-header-cell
          >
          <mat-cell
            *matCellDef="let element; let i = index"
            [formGroup]="element"
          >
            <mat-form-field
              style="width: 130px"
              [appearance]="
                catForm.get('catRows').value[i].isEditable ? 'none' : 'legacy'
              "
            >
              <input
                matInput
                type="text"
                formControlName="categoryName"
                [readonly]="catForm.get('catRows').value[i].isEditable"
              />
            </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="categoryValue">
          <mat-header-cell *matHeaderCellDef>
            {{ "VALUE" | ioxTranslate }}</mat-header-cell
          >
          <mat-cell
            *matCellDef="let element; let i = index"
            [formGroup]="element"
          >
            <mat-form-field
              style="width: 130px"
              [appearance]="
                catForm.get('catRows').value[i].isEditable ? 'none' : 'legacy'
              "
            >
              <input
                matInput
                type="text"
                formControlName="categoryValue"
                [readonly]="catForm.get('catRows').value[i].isEditable"
              />
            </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef>
            {{ "ACTIONS" | ioxTranslate }}</mat-header-cell
          >
          <mat-cell
            *matCellDef="let element; let i = index"
            [formGroup]="element"
          >
            <button
              mat-icon-button
              class="material-icons app-toolbar-menu save-button color_green"
              matTooltip="Done"
              (click)="save(catForm, i)"
              *ngIf="!catForm.get('catRows').value[i].isEditable"
              color="primary"
            >
              <mat-icon>check_circle</mat-icon>
            </button>
            <button
              mat-icon-button
              class="material-icons app-toolbar-menu cancel-button"
              matTooltip="Cancel Changes"
              (click)="cancel(catForm, i)"
              *ngIf="!catForm.get('catRows').value[i].isEditable"
            >
              <mat-icon>cancel</mat-icon>
            </button>
            <button
              mat-icon-button
              class="material-icons app-toolbar-menu save-button color_orange"
              *ngIf="catForm.get('catRows').value[i].isEditable"
              matTooltip="Edit"
              color="primary"
              (click)="edit(catForm, i)"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button aria-label="Delete" (click)="delete(i)">
              <mat-icon color="warn">delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let category; columns: displayedColumns; let i = index"
        >
        </mat-row>
      </mat-table>
    </ng-container>
  </form>
  <mat-divider *ngIf="!isNewEntity" class="m-y-16" inset></mat-divider>
  <button
    *ngIf="isNewEntity && rows.length > 0"
    mat-button
    color="primary"
    (click)="insert()"
    style="margin-top: 1%; margin-bottom: 1%"
  >
    {{ "Save Categories" | ioxTranslate }}
  </button>
  <button
    *ngIf="!isNewEntity"
    mat-raised-button
    color="primary"
    (click)="update()"
    style="margin-top: 1%; margin-bottom: 1%"
  >
    {{ "Save Categories" | ioxTranslate }}
  </button>
  <p *ngIf="isNewEntity && rows.length > 0" class="text-red-500">
    <b>**{{ "Please Save Categories Before Next" | ioxTranslate }}**</b>
  </p>
</mat-card-content>
