<!-- <breadcrumb></breadcrumb> -->
<div class="dt-mt-row" fxLayout="row wrap">
  <div fxFlex.gt-sm="49" fxFlex.gt-xs="100" fxFlex="100" *ngIf="item">
    <div class="container">
      <h3 class="m-l-16 title">
        {{ "Floating Barge" | ioxTranslate }} {{ item.name }}
        {{ "Details" | ioxTranslate }}
      </h3>
      <div class="buttons">
        <button
          mat-raised-button
          (click)="edit()"
          class="material-icons color_orange"
        >
          <mat-icon>edit</mat-icon> {{ "Edit" | ioxTranslate }}
        </button>
        <button mat-raised-button (click)="confirmDeleteDialog()" color="warn">
          <mat-icon>delete</mat-icon> {{ "Delete" | ioxTranslate }}
        </button>
      </div>
    </div>

    <mat-divider></mat-divider>
    <table class="mat-table">
      <tr class="mat-row mat-header-row">
        <th class="mat-header-cell">
          <b>{{ "Id" | ioxTranslate }}:</b>
        </th>
        <td class="mat-cell">
          {{ item.id }}
        </td>
      </tr>
      <tr class="mat-row">
        <th class="mat-header-cell">
          <b>{{ "Floating Barge Name" | ioxTranslate }}:</b>
        </th>
        <td class="mat-cell">
          {{ item.name }}
        </td>
      </tr>
    </table>
  </div>
  <div
    fxFlex.gt-sm="49"
    fxFlex.gt-xs="100"
    fxFlex="100"
    *ngIf="item"
    class="m-l-8 m-t-8"
  >
    <agm-map
      [latitude]="lat"
      [longitude]="lng"
      [zoom]="zoom"
      [scrollwheel]="scrollwheel"
    >
      <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="false">
      </agm-marker>
    </agm-map>
  </div>
</div>
<!-- <table mat-table [dataSource]="dataSource"></table> -->
