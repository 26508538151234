import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Asset } from 'src/app/models/api/iox-admin/assets';
import { IoxAdminService } from 'src/app/services/api/iox-admin.service';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { IoxTranslatePipe } from 'src/app/shared/pipes/iox-translate.pipe';
import { AxUtils } from 'src/app/shared/utils/axUtils';

@Component({
  selector: 'app-floating-barge-details',
  templateUrl: './floating-barge-details.component.html',
  styleUrls: ['./floating-barge-details.component.scss'],
})
export class FloatingBargeDetailsComponent implements OnInit {
  id: Number;
  item: Asset;
  zoom: number = 12;
  lat: number;
  lng: number;
  public scrollwheel = false;
  machineryId: number;
  displayedColumns: string[] = [];
  dataSource: any;
  constructor(
    private route: ActivatedRoute,
    private service: IoxAdminService,
    private routePage: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private translatePipe: IoxTranslatePipe
  ) {}

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.getAssetDetails();
  }

  getAssetDetails() {
    this.service.getAssetsById(this.id).subscribe(
      (response) => {
        this.item = response;
        this.lat = Number(this.item.lat);
        this.lng = Number(this.item.lng);
      },
      (err: HttpErrorResponse) => AxUtils.handleServiceError(err)
    );
  }

  confirmDeleteDialog() {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        name: this.item.name,
        title: this.translatePipe.transform(
          'Delete Floating Barge and its all machines'
        ),
        message: this.translatePipe.transform('Confirm delete Floating Barge'),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.delete();
      }
    });
  }

  delete() {
    this.service.deleteFloating(this.id).subscribe(
      (response) => {
        this.showSnackbar(
          this.translatePipe.transform('Delete Floating Barge Successfully')
        );
        this.routePage.navigate(['/master-data/floating-barge']);
      },
      (err: HttpErrorResponse) => AxUtils.handleServiceError(err)
    );
  }

  private showSnackbar(message: string) {
    this.snackBar.open(message, 'Dismiss');
  }

  edit() {
    this.routePage.navigate([`/master-data/floating-barge/edit/${this.id}`]);
  }
}
