import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class EnvService {
  coreApi = 'unset';
  jsApi = 'unset';
  pyApi = 'unset';
  websocketUrl = 'unset';
  alarmsocketUrl = 'unset';
  lineUrl = 'unset';
  sensorRange = [-1, -1];
  enableDebug = false;
  uploadUrl = 'unset';
  tenant = 'unset';
  app = 'unset';
  health = 'unset';
  GrafanaApiKey = 'unset';
  grafanaUrl = 'unset';
  languageApi = 'unset';
  // old
  apiUrl = 'unset';
  apiAdminUrl = 'unset';
  talegurMlUrl = 'unset';
  auxApiUrl = 'unset';
  tenantWebDetailsUrl = 'unset';
  server = 'unset';
  ioxAdminApi = 'unset';

  constructor() {}

  isValidHttpUrl(cfgItem: string) {
    let url;
    try {
      url = new URL(cfgItem);
    } catch (e) {
      return false;
    }
    return true;
  }

  setServer(server: string) {
    if (!this.isValidHttpUrl(server)) {
      localStorage.setItem('server', `http://${server}`);
      localStorage.setItem('coreApi', `http://${server}:28140/api`);
      localStorage.setItem(
        'aitWhitelistHosts',
        `${server}:28140 http://${server}:28112 http://${server}:28113 http://${server}:28231 http://${server}:28102`
      );
      localStorage.setItem('jsApi', `http://${server}:8080/api/v2/js`);
      localStorage.setItem('pyApi', `http://${server}:8080/api/v2/py`);
      localStorage.setItem(
        'uploadUrl',
        `http://${server}:28214/api/v1/bulkConfig`
      );
      localStorage.setItem('websocketUrl', `ws://${server}:28113`);
      localStorage.setItem('alarmsocketUrl', `ws://${server}:28114`);
      localStorage.setItem('talegurMlUrl', `http://${server}:8080/api/v2/ml`);
      localStorage.setItem('grafanaUrl', `http://${server}:28231`);
      localStorage.setItem('ioxAdminApi', `http://${server}:28122`);
      // localStorage.setItem('ioxAdminApi', `http://localhost:5000`);
    } // end if
    else {
      const whitelist = server.split('://')[1]; // left(0) = https, right(1) is what we want
      const url = new URL(server);
      localStorage.setItem('server', `${server}`);
      localStorage.setItem('coreApi', `${server}/api`);
      localStorage.setItem(
        'aitWhitelistHosts',
        `${whitelist} ${server} talegur.ait.co.th localhost:4200`
      );
      localStorage.setItem('jsApi', `${server}/express/js`);
      localStorage.setItem('pyApi', `${server}/express/py`);
      localStorage.setItem('uploadUrl', `${server}/file_upload`);
      localStorage.setItem('websocketUrl', `wss://${url.hostname}//websocket`);
      localStorage.setItem(
        'alarmsocketUrl',
        `wss://${url.hostname}/alarmsocket`
      );
      console.log('WebSocket Set wss://${url.hostname}//websocket ');
      localStorage.setItem('talegurMlUrl', `${server}/express`);
      localStorage.setItem('grafanaUrl', `${server}/grafana`);
      localStorage.setItem('ioxAdminApi', `${server}/iox_admin_api`);
      // localStorage.setItem('ioxAdminApi', `http://localhost:5000`);
    } // end else
  }
}
