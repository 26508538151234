import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../core/env.service';

@Injectable({
  providedIn: 'root',
})
export class InfluxService {
  private url = this.env.pyApi;
  private urlIox = this.env.ioxAdminApi;
  // private urlIox = 'http://localhost:5000';
  constructor(private http: HttpClient, private env: EnvService) {}

  public getLatestSensorValue(
    sensorId: string,
    measurement: string
  ): Observable<any> {
    const latestUrl =
      this.urlIox + '/api/v1/logs/' + measurement + '/' + sensorId;
    return this.http.get<any>(latestUrl);
  }

  getData(measurement, sensorId, from, to) {
    const url =
      this.urlIox + `/api/v1/logs/${measurement}/${sensorId}/${from}/${to}`;
    return this.http.get<any[]>(url);
  }

  getUsageData(measurement, field, sensorId, from, to) {
    const url =
      this.url +
      `/sv/ReportWorkHours/${measurement}` +
      `/${sensorId}/${field}/${from}/${to}`;
    return this.http.get<any[]>(url);
  }

  delete(
    time: string,
    user: string,
    measurement: string,
    sensorId,
    remark: string
  ) {
    return this.http.delete<any[]>(
      this.urlIox +
        '/api/v1/logs/delete/' +
        measurement +
        '/' +
        sensorId +
        '/' +
        time +
        '/' +
        user +
        '/' +
        remark
    );
  }

  patch(
    time: string,
    measurement: string,
    sensorId,
    user: string,
    remark: string
  ) {
    return this.http.patch<any[]>(
      this.urlIox +
        '/api/v1/logs/undo_delete/' +
        measurement +
        '/' +
        sensorId +
        '/' +
        time +
        '/' +
        user +
        '/' +
        remark,
      null
    );
  }
  editData(
    time: string,
    measurement: string,
    sensorId,
    data: Object,
    user: string,
    remark: string
  ) {
    return this.http.patch<any[]>(
      this.urlIox +
        '/api/v1/logs/edit_data/' +
        measurement +
        '/' +
        sensorId +
        '/' +
        time +
        '/' +
        user +
        '/' +
        remark,
      data
    );
  }
}
