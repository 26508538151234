<breadcrumb></breadcrumb>
<h4 mat-dialog-title>
  <strong>{{ "Add New Floating Barge" | ioxTranslate }}</strong>
</h4>
<div class="dt-mt-row" fxLayout="row wrap">
  <mat-card
    class="mat-elevation-z8"
    [fxFlex.gt-sm]="100"
    [fxFlex.gt-xs]="100"
    fxFlex="100"
  >
    <formly-form
      *ngIf="newAsset"
      [form]="basicForm"
      [fields]="config.fields"
      [model]="newAsset"
    >
    </formly-form>
    <mat-dialog-actions>
      <button mat-button routerLink="/master-data/floating-barge">
        {{ "Cancel" | ioxTranslate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        (click)="insert()"
      >
        {{ "Add Floating Barge" | ioxTranslate }}
      </button>
    </mat-dialog-actions>
  </mat-card>
</div>
