import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AssetItem } from 'src/app/models/api/iox-admin/asset-item';
import { EnvService } from '../core/env.service';
import { Asset } from 'src/app/models/api/iox-admin/assets';
import { JobPlan } from 'src/app/models/api/iox-admin/job-plan';
import { Product } from 'src/app/models/api/iox-admin/products';
import { DailyJobs } from 'src/app/models/api/iox-admin/daily-jobs';

@Injectable({
  providedIn: 'root',
})
export class IoxAdminService {
  private urlIox = this.env.ioxAdminApi;
  // private urlIox = 'http://localhost:5000';
  constructor(private http: HttpClient, private env: EnvService) {}

  getAssetItems() {
    const url = this.urlIox + `/api/v1/asset-items`;
    return this.http.get<any[]>(url);
  }

  changePassword(user: Object) {
    return this.http.patch<Object>(
      this.urlIox + '/api/v1/change-password',
      user
    );
  }

  getCustomerSites() {
    const url = this.urlIox + `/api/v1/customer-sites`;
    return this.http.get<any[]>(url);
  }

  getCustomerSitesDetails() {
    const url = this.urlIox + `/api/v1/customer-sites/details`;
    return this.http.get<any[]>(url);
  }

  getAssets() {
    const url = this.urlIox + `/api/v1/assets`;
    return this.http.get<any[]>(url);
  }

  getProducts() {
    const url = this.urlIox + `/api/v1/products`;
    return this.http.get<Product[]>(url);
  }

  getAssetsById(id) {
    const url = this.urlIox + `/api/v1/assets/by_id/${id}`;
    return this.http.get<Asset>(url);
  }

  getProductsById(id) {
    const url = this.urlIox + `/api/v1/products/by_id/${id}`;
    return this.http.get<Product>(url);
  }

  getDailyJobsById(id) {
    const url = this.urlIox + `/api/v1/daily-jobs/by_id/${id}`;
    return this.http.get<DailyJobs>(url);
  }

  getAssetItemById(id) {
    const url = this.urlIox + `/api/v1/asset-items/by_id/${id}`;
    return this.http.get<AssetItem>(url);
  }

  getAssetTypes() {
    const url = this.urlIox + `/api/v1/asset-types`;
    return this.http.get<any[]>(url);
  }

  insertAssetItem(assetItem: AssetItem) {
    const url = this.urlIox + `/api/v1/asset-items`;
    return this.http.post(url, assetItem);
  }

  insertAsset(asset: Asset) {
    const url = this.urlIox + `/api/v1/assets`;
    return this.http.post(url, asset);
  }

  insertProduct(product: Product) {
    const url = this.urlIox + `/api/v1/products`;
    return this.http.post(url, product);
  }

  insertDailyJobs(job: DailyJobs) {
    const url = this.urlIox + `/api/v1/daily-jobs`;
    return this.http.post(url, job);
  }

  getDailyJobs() {
    const url = this.urlIox + `/api/v1/daily-jobs`;
    return this.http.get<any[]>(url);
  }

  insertMaPlan(item: JobPlan) {
    const url = this.urlIox + `/api/v1/ma-plans`;
    return this.http.post(url, item);
  }
  insertWorkStoppage(item: any) {
    const url = this.urlIox + `/api/v1/work-stoppages`;
    return this.http.post(url, item);
  }
  insertOperTime(item: JobPlan) {
    const url = this.urlIox + `/api/v1/operating-times`;
    return this.http.post(url, item);
  }
  insertNotAvailableTime(item: JobPlan) {
    const url = this.urlIox + `/api/v1/not-available-periods`;
    return this.http.post(url, item);
  }

  getJobPlansOfAssetItem(id) {
    const url = this.urlIox + `/api/v1/asset-items/jobs/by_id/${id}`;
    return this.http.get<JobPlan[]>(url);
  }

  deleteMachine(id) {
    const url = this.urlIox + `/api/v1/asset-items/by_id/${id}`;
    return this.http.delete(url);
  }

  deleteFloating(id) {
    const url = this.urlIox + `/api/v1/assets/by_id/${id}`;
    return this.http.delete(url);
  }

  deleteProduct(id) {
    const url = this.urlIox + `/api/v1/products/by_id/${id}`;
    return this.http.delete(url);
  }

  deleteDailyJobs(id) {
    const url = this.urlIox + `/api/v1/daily-jobs/by_id/${id}`;
    return this.http.delete(url);
  }
  editAsset(body) {
    const url = this.urlIox + `/api/v1/assets`;
    return this.http.patch(url, body);
  }

  editDailyJob(body) {
    const url = this.urlIox + `/api/v1/daily-jobs`;
    return this.http.patch(url, body);
  }

  editProduct(body) {
    const url = this.urlIox + `/api/v1/products`;
    return this.http.patch(url, body);
  }

  editAssetItem(body) {
    const url = this.urlIox + `/api/v1/asset-items`;
    return this.http.patch(url, body);
  }

  getAssetProductRate(a_id, p_id) {
    const url =
      this.urlIox + `/api/v1/asset-product-rate/by_id/${a_id}/${p_id}`;
    return this.http.get(url);
  }

  removeAddedSensorFromAssetItem(a_id, s_id) {
    const url = this.urlIox + `/api/v1/asset-item-sensor/by_id/${a_id}/${s_id}`;
    return this.http.delete(url);
  }

  getAssetItemSensors() {
    const url = this.urlIox + `/api/v1/asset-item-sensor`;
    return this.http.get<any[]>(url);
  }
}
