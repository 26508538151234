/**
 * Enum for device events
 *
 * @author AMDIS Media and IT Services
 *
 * @date 12.2.2018
 */
export enum DeviceEventEnum {
    // --- Events for registration
    registrationRequest = 'registrationRequest',
    registrationRejection = 'registrationRejection',
    registrationConfirmation = 'registrationConfirmation',

    deregistrationRequest = 'deregistrationRequest',
    deregistrationRejection = 'deregistrationRejection',
    deregistrationConfirmation = 'deregistrationConfirmation',

    // --- Events for configuration
    configurationRequest = 'configurationRequest',
    configurationRejection = 'configurationRejection',
    configurationConfirmation = 'configurationConfirmation',

    // --- Events for activation
    activationRequest = 'activationRequest',
    activationRejection = 'activationRejection',
    activationConfirmation = 'activationConfirmation',
    inactivationRequest = 'inactivationRequest',
    inactivationRejection = 'inactivationRejection',
    inactivationConfirmation = 'inactivationConfirmation',

    // --- Events for state reporting
    stateReportRequest = 'stateReportRequest',
    stateReportRejection = 'stateReportRejection',
    stateReportConfirmation = 'stateReportConfirmation',

    // --- Events for health
    heartBeat = 'heartBeat',

    // --- Events for custom specific state machine
    customSMEvent = 'customSMEvent'

}
