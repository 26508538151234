import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'tgGenericTime' })
export class TgGenericTimePipe implements PipeTransform {
  transform(dateorstring: number | string | Date): string {
    if (dateorstring != null) {
      if (typeof dateorstring === 'number') {
        return dateorstring.toString();
      }
      if (typeof dateorstring === 'string') {
        if (moment(dateorstring, moment.ISO_8601, true).isValid()) {
          return moment(dateorstring).format('dddd, MMMM Do, | HH:mm');
        } else {
          return dateorstring;
        }
      }

      return moment(dateorstring).format('dddd, MMMM Do, | HH:mm');
    } else {
      return '-';
    }
  }
}
