<nav aria-label="breadcrumb">
  <ol class="dt-mt-breadcrumb">
    <li
      class="dt-mt-breadcrumb-item"
      *ngFor="let navlink of nav; trackBy: trackByNavlink; first as isFirst"
    >
      <a href="#" class="link" *ngIf="isFirst">{{ navlink | ioxTranslate }}</a>
      <ng-container *ngIf="!isFirst">
        <mat-icon class="chevron">chevron_right</mat-icon>
        <span class="link">{{ navlink | translate | ioxTranslate }}</span>
      </ng-container>
    </li>
  </ol>
</nav>
