import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AssetItem } from 'src/app/models/api/iox-admin/asset-item';
import { IoxAdminService } from 'src/app/services/api/iox-admin.service';
import { CruditorFormConfiguration } from 'src/app/shared/components/cruditor-form/cruditor-form.configuration';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { IoxTranslatePipe } from 'src/app/shared/pipes/iox-translate.pipe';

@Component({
  selector: 'app-floating-barge-add',
  templateUrl: './floating-barge-add.component.html',
  styleUrls: ['./floating-barge-add.component.scss'],
})
export class FloatingBargeAddComponent implements OnInit, OnDestroy {
  form = new FormGroup({});
  asset: AssetItem;
  assetTypes = [];
  assets = [];
  public basicForm = new FormGroup({});
  public saveInForm = true;
  public isNewEntity = true;
  public newAsset = new AssetItem();
  public config: CruditorFormConfiguration = {
    detailsTitle: 'Assets',
    insertMessage: this.translatePipe.transform(
      'Floating Barge Successfully Added'
    ),
    updateMessage: this.translatePipe.transform(
      'Floating Barge Successfully Updated'
    ),
    deleteMessage: this.translatePipe.transform(
      'Floating Barge Successfully Deleted'
    ),
    fields: [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-12',
            type: 'input',
            key: 'name',
            templateOptions: {
              label: this.translatePipe.transform('Name'),
              required: true,
            },
          },

          {
            className: 'col-sm-6',
            type: 'input',
            key: 'lat',
            templateOptions: {
              label: this.translatePipe.transform('Lat'),
            },
          },
          {
            className: 'col-sm-6',
            type: 'input',
            key: 'lng',
            templateOptions: {
              label: this.translatePipe.transform('Lng'),
            },
          },
        ],
      },
    ],
  };
  private destroy$ = new Subject<void>();
  constructor(
    private service: IoxAdminService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private route: Router,
    private translatePipe: IoxTranslatePipe
  ) {}

  ngOnInit(): void {
    this.basicForm = this.formBuilder.group({});
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async insert() {
    if (this.basicForm.valid) {
      try {
        const newEntity = await this.service
          .insertAsset(this.newAsset)
          .toPromise();
        this.navigateToFloating();
        this.showSnackbar(this.config.insertMessage);
      } catch (error) {
        const { code, message } = error.error;
        this.showSnackbar(
          `Add Floating Barge Failed due to Error Code: ${code} ${message}`
        );
      }
    }
  }
  private navigateToFloating() {
    this.route.navigate(['/master-data/floating-barge']);
  }

  private showSnackbar(message: string) {
    this.snackBar.open(message, 'Dismiss');
  }
}
