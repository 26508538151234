import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
  Input,
  SimpleChange,
} from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { TalegurDataService } from '../cruditor/talegur-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from 'src/app/models/api/location';
@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent implements OnInit {
  public location = this.createLocationEmptyModel();
  zoom: number;
  @Input() dataService: TalegurDataService;
  @Input() isNewEntity: boolean;
  @Input() data: any;
  private geoCoder;
  public scrollwheel = false;

  @ViewChild('search')
  public searchElementRef: ElementRef;
  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    // this.loadmap();
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.location.latitude = place.geometry.location.lat();
          this.location.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }

  loadmap() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.location.latitude = place.geometry.location.lat();
          this.location.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }

  createLocationEmptyModel(): Location {
    const data = new Location();
    data.details = null;
    data.latitude = 0.0;
    data.longitude = 0.0;
    data.address = null;
    return data;
  }

  ngOnChanges(...args: any[]) {
    if (this.data.location != null && this.data.location.trim() != '') {
      this.location = JSON.parse(this.data.location);
      this.zoom = 12;
    } else {
      this.location.details = null;
      this.loadmap();
    }
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.location.latitude = position.coords.latitude;
        this.location.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.location.latitude, this.location.longitude);
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    this.location.latitude = $event.coords.lat;
    this.location.longitude = $event.coords.lng;
    this.getAddress(this.location.latitude, this.location.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 12;
            this.location.address = results[0].formatted_address;
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }

  update() {
    this.data.location = JSON.stringify(this.location);
    this.dataService.update(this.data).subscribe(
      (newEntity) => {
        this.snackBar.open('Updated Successfully', 'Dismiss');
      },
      (error) => {
        this.snackBar.open(
          'Failed due to Error Code: ' +
            error.error.code +
            ' ' +
            error.error.message,
          'Dismiss'
        );
      }
    );
  }
}
