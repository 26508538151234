<breadcrumb></breadcrumb>
<mat-card>
  <h4>
    <button [ngClass]="ledClass"></button> &nbsp;{{
      "Event Monitor" | ioxTranslate
    }}. {{ eventNr }} {{ lastUpdateInfo }}
  </h4>
  <mat-form-field>
    <mat-icon matPrefix mat-raised-button>search</mat-icon>
    <input
      matInput
      type="search"
      (keyup)="search()"
      [(ngModel)]="searchTextField"
      type="text"
      ngModel
      name="searchParam"
      placeholder="{{ 'Search' | ioxTranslate }}"
      aria-label="Search..."
    />
  </mat-form-field>
</mat-card>
<mat-card class="mat-elevation-z8">
  <mat-card-content>
    <div fxLayout="row wrap">
      <div
        [fxFlex.gt-sm]="100"
        [fxFlex.gt-xs]="100"
        fxFlex="100"
        class="dt-mt-container dt-mt-col"
      >
        <table #table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "S_ID" | ioxTranslate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <strong> {{ element.id }} </strong>
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "STATUS" | ioxTranslate }}
            </th>
            <td mat-cell *matCellDef="let element" class="p-t-16 p-b-16">
              {{ element.status }}
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "NAME" | ioxTranslate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <ng-container matColumnDef="measuredAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "MEASURED AT" | ioxTranslate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.measuredAt | date: "short" }}
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngClass]="{ selected: selection.isSelected(row) }"
            (click)="onSelect(row)"
          >
            <!-- [class.make-red]="row.aType=='ERROR'" -->
          </tr>
        </table>
      </div>
      <!-- <div [fxFlex.gt-sm]="100" [fxFlex.gt-xs]="100" fxFlex="100" class="dt-mt-col">
                <mat-paginator #paginator [pageSizeOptions]="[20, 30, 50]" showFirstLastButtons></mat-paginator>
            </div> -->
    </div>
  </mat-card-content>
</mat-card>
