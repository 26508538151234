import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/core/authentication/storage.service';
import { EnvService } from 'src/app/services/core/env.service';
import { JwtService } from '../../services/core/jwt.service';
import { StartupService } from '../../services/core/startup.service';
import { default as packageInfo } from '../../../../package.json';
import { TenantService } from 'src/app/services/api/tenant.service';
import { Color } from 'src/app/models/themes/color';
import { IoxLanguageService } from 'src/app/services/api/iox-language.service';
import { IoxLingo } from 'src/app/models/api/ioxlingo';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  // count the clicks
  private clickTimeout = null;
  loginForm: FormGroup;
  tenantForm: FormGroup;
  clicksCount = 1;
  showClicks = false;
  imageLogo = './assets/img/brand/logo_white_orange_IOT.svg';
  width = '350';
  height = '230';
  tenantStatus = false;
  hidePassword = true;
  languages: IoxLingo[] = [];
  public errorMsg = '';
  public errorMsgTenant = '';
  public wrongPassword = false;
  public server = '';
  public version = packageInfo.version;
  primaryColorPalette: Color[] = [];
  accentColorPalette: Color[] = [];
  selectedLanguage = this.store.get('language') || 'en-EN';
  constructor(
    private renderer: Renderer2,
    private fb: FormBuilder,
    private router: Router,
    private token: JwtService,
    private startup: StartupService,
    private store: LocalStorageService,
    public env: EnvService,
    private tenantService: TenantService,
    private languageService: IoxLanguageService
  ) {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

    this.tenantForm = this.fb.group({
      tenant: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    // this.store.remove('tenantStatus');
    this.server = this.env.coreApi;
    if (this.store.get('tenantStatus')) {
      this.tenantStatus = true;
      this.getTenantTheme();
    }
    this.getLanguages();
  }

  getLanguages() {
    this.languageService.getLanguages().subscribe((data) => {
      this.languages = data;
    });
  }

  changeLanguage(language: string) {
    this.store.set('language', language);
    this.languageService
      .fetchTranslations(this.store.get('tenantStatus'), this.selectedLanguage)
      .subscribe((data) => {
        // localStorage.setItem('changeLanguage', 'y');
        let translations = this.languageService.set(data);
      });
  }

  get username() {
    return this.loginForm.get('username');
  }

  get password() {
    return this.loginForm.get('password');
  }

  get tenant() {
    return this.tenantForm.get('tenant');
  }

  getTenantTheme() {
    this.width = '250';
    this.height = '130';
    this.imageLogo = this.store.get('dark_logo_url');
  }

  public changeTenant(itemId: string): void {
    if (this.clickTimeout) {
      this.setClickTimeout(() => {});
      this.clicksCount = this.clicksCount + 1;
      if (this.clicksCount > 3) {
        this.showClicks = true;
      }
      if (this.clicksCount == 10) {
        this.store.remove('tenantStatus');
        //TODO: Do this the clean way
        window.localStorage.clear();

        window.location.reload();
      }
    } else {
      this.clicksCount = 1;
      this.showClicks = false;
      // if timeout doesn't exist, we know it's first click
      // treat as single click until further notice
      this.setClickTimeout(itemId);
    }
  }
  // sets the click timeout and takes a callback
  // for what operations you want to complete when
  // the click timeout completes
  public setClickTimeout(callback) {
    // clear any existing timeout
    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = null;
      this.showClicks = false;
      callback();
    }, 2000);
  }
  public handleSingleClick(itemId: string) {
    //The actual action that should be performed on click
    //  this.itemStorage.get(itemId);
  }

  handleTenant() {
    this.tenantService
      .getTenantDetails(this.tenantForm.value.tenant)
      .subscribe((data) => {
        if (data.length > 0) {
          let tenantDetails = data[0];
          this.env.tenant = tenantDetails.tenant;
          this.store.saveTenantWebDetails(tenantDetails);
          // this.imageLogo = tenantDetails.dark_logo_url;
          this.tenantStatus = true;
          this.changeServerApi(tenantDetails.server_ip);
          window.location.reload();
        } else {
          this.errorMsgTenant = 'No App Found.';
        }
      });
  }

  private changeServerApi(server: string) {
    this.env.setServer(server);
    this.server = this.store.get('coreApi');
  }

  handleLogin() {
    this.renderer.addClass(document.body, 'loader-cursor');
    this.wrongPassword = false;
    this.token
      .login(this.loginForm.value.username, this.loginForm.value.password)
      .subscribe(
        (data) => {
          if (data.access_token != null) {
            this.store.remove('username');
            this.store.set('username', this.loginForm.value.username);
            this.store.remove('access_token');
            this.store.set('access_token', data.access_token);
            this.languageService
              .fetchTranslations(
                this.store.get('tenantStatus'),
                this.selectedLanguage
              )
              .subscribe((data) => {
                // localStorage.setItem('changeLanguage', 'y');
                let translations = this.languageService.set(data);
              });
            this.startup.load();
            document.body.classList.remove('loader-cursor');
            this.router.navigateByUrl('/');
          } else {
            document.body.classList.remove('loader-cursor');
            this.errorMsg =
              'Login failed. Make sure your username and password are correct or contact your system administrator for a reset'; // login failed
          }
        },
        (error) => {
          document.body.classList.remove('loader-cursor');
          this.wrongPassword = true;
        }
      );
  }

  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }
}
