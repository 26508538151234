import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
  FieldProcessor,
  Sensor,
  SensorValueEntry,
} from 'src/app/models/api/sensor';
import { SensorService } from 'src/app/services/api/sensor.service';

import * as moment from 'moment';
import { forkJoin as observableForkJoin } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfigService } from 'src/app/services/api/config.service';
import { LastUpdateService } from 'src/app/services/api/last-update.service';
import { Config } from 'src/app/models/api/config';
import { LineUser } from 'src/app/models/api/lineuser';
import { SensorValue } from 'src/app/models/api/sensor-value';
import { SensorValueService } from 'src/app/services/api/sensor-value.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericDetailViewerComponent } from 'src/app/shared/components/generic-detail-viewer/generic-detail-viewer.component';
import { EnvService } from 'src/app/services/core/env.service';
import { DashboardItems } from 'src/app/models/api/dashboard';
import { LocalStorageService } from 'src/app/services/core/authentication/storage.service';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/themes/settings.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public sensors: Sensor[];
  public dashboardItems: Array<DashboardItems> = [];
  public sensorCount = 0;
  public allDataReceived: boolean;
  public callsReturned = 0;
  public autoRegistration = false;
  public autoCreation = false;
  public unassignedSensors = 0;
  public config: Config;
  public lastSensorValues: any[];
  public techMsgCount = 0;
  public lastMessage = {};
  private interval: any;
  public refreshRates: Array<number> = [0, 15, 30, 60, 120, 300];
  public refreshRate = 300; // in seconds
  sensorstoFilter: Sensor[] = [];
  public options: any = {
    maxLines: 1000,
    printMargin: true,
    wrap: true,
    readOnly: true,
  };
  public fieldProcessors: FieldProcessor[] = [
    {
      fieldKey: 'categories',
      processor: this.processCategories,
    },
    {
      fieldKey: 'deviceState',
      processor: this.processDeviceState,
    },
    {
      fieldKey: 'location',
      processor: this.processLocation,
    },
    {
      fieldKey: 'sensorProperties',
      processor: this.processSensorProperties,
    },
    {
      fieldKey: 'sensorValueEntries',
      processor: this.processSensorValueEntries,
    },
  ];

  public lineUsers: Array<LineUser> = [];

  @ViewChild('lastMessageTemplate', { static: true })
  public lastMessageTemplate;
  panelOpenState = false;
  private sensorRange = [0, 0];
  columnsToDisplay = ['clazz', 'count'];
  constructor(
    private sensorService: SensorService,
    private dialog: MatDialog,
    private lastUpdateService: LastUpdateService,
    private configService: ConfigService,
    private env: EnvService,
    private store: LocalStorageService,
    private route: Router,
    private setting: SettingsService
  ) {
    this.sensorRange = env.sensorRange;
    this.sensorRange = [0, 1000000];

    this.setting.notify.subscribe((newValue) => {
      this.refreshSensors();
    });
  }

  ngOnInit(): void {
    this.refreshSensors();
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  checkSensorTypes() {
    let rez: Array<DashboardItems> = [
      { name: 'Other Sensors', value: undefined, size: '50', sensors: [] },
    ];
    this.sensors.forEach(function (sensor) {
      const index = rez.findIndex(
        (e) => e.value === sensor.categories['DashboardGroup']
      );
      if (index === -1) {
        rez.push({
          name: sensor.categories['DashboardGroup'] + ' Sensors',
          value: sensor.categories['DashboardGroup'],
          size: '50',
          sensors: [sensor],
        });
      } else {
        rez[index].sensors.push(sensor);
      }
    });
    this.dashboardItems = rez;
    // set size of boxes
    this.dashboardItems = this.dashboardItems.sort((a, b) =>
      a.sensors < b.sensors ? -1 : 1
    );
    let index = this.findIdIndex('Other Sensors', this.dashboardItems);
    if (this.dashboardItems[index].sensors.length === 0) {
      this.dashboardItems.splice(index, 1);
    }
    if (this.dashboardItems.length % 2 !== 0) {
      this.dashboardItems[this.dashboardItems.length - 1].size = '100';
    }
  }
  findIdIndex(id, array) {
    return array.findIndex((elem) => elem.name === id);
  }

  getSensorsNotNull(sensors: Sensor[]) {
    this.sensorstoFilter = [];
    for (let sensor of sensors) {
      if (sensor.tenantId == null || sensor.tenantId == undefined) {
        sensor.tenantId = '';
      }
      this.sensorstoFilter.push(sensor);
    }
  }

  private processLastUpdate(
    lastUpdateValues: SensorValue[],
    sensorList: Sensor[]
  ) {
    this.processSensorList(sensorList);
    sensorList.forEach((sensor, idx) => {
      const lastUpdate = lastUpdateValues.filter((updVal) => {
        return updVal.sensorId === sensor.id;
      });
      if (lastUpdate.length === 1) {
        sensor.savedAt = moment.utc(lastUpdate[0].savedAt).local().toDate();
        sensor['lastValue'] = lastUpdate[0]['lastSensorValue'];
        const lag = moment().diff(
          moment.utc(lastUpdate[0].savedAt).local(),
          'minutes'
        );
        sensor.lag = lag;
        // todo: after fixed in api have to change from resolution to samplingRate
        sensor.lagUnit = this.getMeaning(lag, sensor.resolution);
      } else {
        sensor.savedAt = new Date(0);
        sensor.lag = 9999;
        // todo: after fixed in api have to change from resolution to samplingRate
        sensor.lagUnit = this.getMeaning(99999, sensor.resolution);
      }
      const categories = sensor.categories;
      if ('Status' in categories) {
        if (categories.Status == 'ACK') {
          sensor.lagUnit = 'darkblue';
        }
      }
    });
    this.sensorService.persist(sensorList);
  }

  goToDevices(state: string) {
    this.store.set('searchValue', state);
    this.route.navigate(['/devices']);
  }

  getLastValue(id: number) {
    let found = this.sensors.filter((e) => e.id === id);
    let sen;
    if (found) {
      sen = found[0];
    } else {
      sen = null;
    }
    const sensorDetails = this.buildSensorDataTableDetails(sen);
    if (sen['lastValue'] && sen['lastValue'] !== null) {
      const data = this.buildSensorDataTable(sen['lastValue']);
      const filteredArray = data.filter((item) => {
        return !['value', 'valueUnit', 'unit'].includes(item.field);
      });

      // Initialize two arrays for the split
      const dataMain = [];
      const dataSensorValue = [];

      // Split the filtered array into two arrays
      filteredArray.forEach((item) => {
        if (['clazz', 'sensorId', 'measuredAt'].includes(item.field)) {
          dataMain.push(item);
        } else {
          dataSensorValue.push(item);
        }
      });

      // Sort the second array alphabetically
      dataSensorValue.sort((a, b) => a.field.localeCompare(b.field));
      this.dialog.open(GenericDetailViewerComponent, {
        width: '900px',
        data: {
          sensorDetails: sensorDetails,
          data: dataMain,
          dataSensorValue: dataSensorValue,
          jsonData: JSON.stringify(sen['lastValue'], null, 2),
        },
      });
    }
  }
  private buildSensorDataTableDetails(response: any): any[] {
    const data = [];
    for (var key in response) {
      if (response.hasOwnProperty(key)) {
        const dataForDispolay = this.processField(key, response[key]);
        const rec = { field: key, fValue: dataForDispolay };
        data.push(rec);
      }
    }
    return data;
  }
  processField(key: string, value: any): string {
    const processor = this.fieldProcessors.find((s) => s.fieldKey == key);
    if (processor) {
      return processor.processor(key, value);
    } else {
      return value;
    }
  } // end of processField

  private buildSensorDataTable(response: any): any[] {
    const data = [];
    for (var key in response) {
      // check if the property/key is defined in the object itself, not in parent
      if (response.hasOwnProperty(key)) {
        if (key == 'sensorValueEntries') {
          response[key].forEach((entry) => {
            const rec = {
              field: entry['propName'],
              fValue: entry['propValue'],
            };
            data.push(rec);
          });
        } else {
          const rec = { field: key, fValue: response[key] };
          data.push(rec);
        }
      } // end if is valid property
    } // end for
    return data;
  } // end method

  private getMeaning(lagValue, samplingMinutes) {
    if (lagValue < 0) {
      // If lagValue is negative, it means the timestamp is in the future.
      return 'red';
    }
    if (samplingMinutes !== 0) {
      return lagValue > samplingMinutes * 10
        ? 'red'
        : lagValue > samplingMinutes * 3
        ? 'orange'
        : 'green';
    }

    return lagValue > 60 * 24 ? 'red' : lagValue > 60 ? 'orange' : 'green';
  }
  // refreshRateChange(): void {
  //   if (this.interval) {
  //     clearInterval(this.interval);
  //   }
  //   if (this.refreshRate > 0) {
  //     // 0 means do not refresh
  //     this.interval = setInterval(
  //       () => this.refreshSensors(),
  //       this.refreshRate * 1000
  //     );
  //   }
  // }

  refreshSensors() {
    const serviceCalls = [];
    // if (this.interval) {
    //   clearInterval(this.interval);
    // }
    serviceCalls.push(this.sensorService.getSensors());
    serviceCalls.push(this.configService.getConfig());
    observableForkJoin(serviceCalls).subscribe(
      (response) => {
        this.sensors = response[0]['sensors'];
        const cfg: Config = response[1] as Config;
        if (this.store.get('tenant') && this.store.get('tenant') != 'all') {
          this.getSensorsNotNull(this.sensors);
          this.sensors = this.sensorstoFilter.filter((sensor) =>
            sensor.tenantId
              .toLowerCase()
              .startsWith(this.store.get('tenant').toLowerCase())
          );
        }
        this.config = cfg;
        this.autoCreation = this.config.autoCreation;
        this.autoRegistration = this.config.autoRegistration;
        this.allDataReceived = true;
        const sensorIds = this.processSensorList(this.sensors);
        this.sensors = this.sensors.filter((s) => {
          const categories = s.categories;
          let rc = true;
          if ('Status' in categories) {
            if (categories.Status == 'DELETED') {
              rc = false;
            }
          }
          return rc;
        });

        if (sensorIds.length > 0) {
          this.lastUpdateService.getLastUpdate().subscribe((result) => {
            this.processLastUpdate(result, this.sensors);
            this.checkSensorTypes();
            // this.interval = setInterval(
            //   () => this.refreshSensors(),
            //   this.refreshRate * 1000
            // );
          });
        }
        this.sensorCount = this.sensors.length;
        // else {
        //   this.refreshRate = 60;
        //   this.interval = setInterval(
        //     () => this.refreshSensors(),
        //     this.refreshRate * 1000
        //   );
        // }
      },
      (err: HttpErrorResponse) => console.log('Error:', err)
      // AxUtils.handleServiceError(err)
    );
  }

  private processSensorList(sensorList: Sensor[]) {
    let sensorIds = [];
    if (sensorList.length > 0) {
      this.unassignedSensors = sensorList.filter(
        (s) => s.tenantId === null || s.tenantId === ''
      ).length;
      const ids = [];
      sensorIds = sensorList.reduce((ids, sensor) => {
        if (
          sensor.id >= this.sensorRange[0] &&
          sensor.id <= this.sensorRange[1]
        ) {
          ids.push(sensor.id);
        }
        return ids;
      }, []);
      // remove the irrelevant sensors
      const finalSensors = sensorList.filter((s) => {
        let rc = sensorIds.includes(s.id);
        return rc;
      });

      sensorList = [...finalSensors];
      this.sensorCount = sensorList.length;
    }
    return sensorIds;
  }

  handleAutoRegistration(event) {
    this.autoRegistration = event.checked;
    this.configService
      .setAutoRegistration(this.autoRegistration)
      .subscribe((result) => {});
  }

  handleAutoCreation(event) {
    this.autoCreation = event.checked;
    this.configService
      .setAutoCreation(this.autoCreation)
      .subscribe((result) => {});
  }

  processSensorProperties(key: string, value: any): string {
    let retVal = '';
    const properties = value as SensorValueEntry[];
    properties.forEach((prop) => {
      retVal += `${prop.propName}=${prop.propValue}\n`;
    });
    return retVal;
  }

  processSensorValueEntries(key: string, value: any): string {
    let retVal = '';
    const properties = value as SensorValueEntry[];
    properties.forEach((prop) => {
      retVal += `${prop.propName}=${prop.propValue}\n`;
    });
    return retVal;
  }

  processCategories(key: string, value: any): string {
    let retVal = '';
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        retVal += `${key}=${value[key]}\n`;
      }
    }
    return retVal;
  }

  processDeviceState(key: string, value: any): string {
    let retVal = '';
    for (let key in value) {
      if (value.hasOwnProperty(key)) {
        retVal += `${key}=${value[key]}\n`;
      }
    }
    return retVal;
  }

  processLocation(key: string, value: any): string {
    if (value) {
      value = JSON.parse(value);
    }
    let retVal = '';
    for (let key in value) {
      if (value.hasOwnProperty(key)) {
        retVal += `${key}=${value[key]}\n`;
      }
    }
    return retVal;
  }
}
