import { HttpHeaders } from '@angular/common/http';

export class Constants {
  static readonly apis = [
    { name: 'AIT-UI', key: 'hjbfUFefuefiewfFEuif983742kwe0wej' },
    { name: 'TG-AMIN', key: 'k2wDoewUfakvnE3vjDsweu3fvbCsnv2we' },
  ];
  // static readonly VERSION = '1.0.0';
  // ------------------------------------------------------------------------------ Date/Time format strings
  static readonly DATE_FMT_US = 'dd/MMM/yyyy';
  static readonly DATE_TIME_FMT_US = `${Constants.DATE_FMT_US}THH:mm:ss`;

  static readonly DATE_FMT_ISO = 'yyyy-MM-dd';
  static readonly DATE_TIME_FMT_ISO = `${Constants.DATE_FMT_ISO}THH:mm:ss`;

  static readonly DATE_FMT_DE = 'dd.MM.yyyy';
  static readonly DATE_TIME_FMT_DE = `${Constants.DATE_FMT_DE}THH:mm:ss`;

  static readonly DATE_FMT_ISO_MOMENT = 'YYYY-MM-DD';
  static readonly DATE_TIME_FMT_ISO_MOMENT = `${Constants.DATE_FMT_ISO_MOMENT}THH:mm:ss`;

  static readonly TIME_FMT_24HOURS_MOMENT = `HH:mm`;

  static readonly TAB_MANAGER = 0;
  static readonly TAB_SKETCHER = 1;
  static readonly TAB_EDITOR = 2;

  // ------------------------------------------------------------------------------ REST Services Settings
  static httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }),
  };

  // ------------------------------------------------------------------------------ Tests with web sockets and MQTT
  static WS_URL = 'ws://localhost:16000'; // ToDo Websockets tests. Not used currently
}
