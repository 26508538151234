<div class="dt-mt-row" fxLayout="row wrap" *ngIf="item">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <div class="container">
      <h3 class="m-l-16 title">
        {{ "Daily Job" | ioxTranslate }} {{ item.ship_name }}
        {{ item.job_start | date : "dd/MM/yyyy" }}
        {{ "Details" | ioxTranslate }}
      </h3>
      <div class="buttons">
        <button
          mat-raised-button
          (click)="edit()"
          class="material-icons color_orange"
        >
          <mat-icon>edit</mat-icon> {{ "Edit" | ioxTranslate }}
        </button>
        <button mat-raised-button (click)="confirmDeleteDialog()" color="warn">
          <mat-icon>delete</mat-icon> {{ "Delete" | ioxTranslate }}
        </button>
      </div>
    </div>

    <mat-divider></mat-divider>
    <mat-card>
      <table class="mat-table">
        <tr class="mat-row mat-header-row">
          <th class="mat-header-cell">
            <b>{{ "Ship Name" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.ship_name }}
          </td>
          <th class="mat-header-cell">
            <b>{{ "Barge Name" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.asset.name }}
          </td>
          <th class="mat-header-cell">
            <b>{{ "Weight Tons" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.weight_ton }}
          </td>
          <th class="mat-header-cell">
            <b>{{ "Product Name (Thai)" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.product.name_th }}
          </td>
        </tr>
        <tr class="mat-row">
          <th class="mat-header-cell">
            <b>{{ "Job Start" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.job_start | date : "dd/MM/yyyy HH:mm" }}
          </td>
          <th class="mat-header-cell">
            <b>{{ "Job End" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.job_end | date : "dd/MM/yyyy HH:mm" }}
          </td>
          <th class="mat-header-cell">
            <b>{{ "Fuel Heavy Machine" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.fuel_heave_machine }}
          </td>

          <th class="mat-header-cell">
            <b>{{ "Rate Product Ton Hour" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.rate_product_ton_hour }}
          </td>
        </tr>

        <tr class="mat-row">
          <th class="mat-header-cell">
            <b>{{ "Rate Ltr Ton" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.rate_ltr_ton }}
          </td>
          <th class="mat-header-cell">
            <b>{{ "Fuel Used" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.fuel_used }}
          </td>
          <th class="mat-header-cell">
            <b>{{ "Fuel Actual Use" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.fuel_actual_use }}
          </td>
          <th class="mat-header-cell">
            <b>{{ "Fuel Stop Time" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.fuel_stop_time }}
          </td>
        </tr>
        <tr></tr>
        <!-- <tr class="mat-row">
        <th class="mat-header-cell" colspan="8">
          <strong>Stop Times Details</strong>
        </th>
      </tr> -->
      </table>
      <h5 class="m-l-24 title">{{ "Stop Times Details" | ioxTranslate }}</h5>
      <table class="mat-table">
        <tr class="mat-row mat-header-row">
          <th class="mat-header-cell">
            <b>{{ "Lift Up Down Hour" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.lift_up_down_hour }}
          </td>
          <th class="mat-header-cell">
            <b>{{ "Lift Up Down Minutes" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.lift_up_down_minutes }}
          </td>
          <th class="mat-header-cell">
            <b>{{ "Lift Up Down Fuel" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.lift_up_down_fuel }}
          </td>
        </tr>
        <tr class="mat-row mat-header-row">
          <th class="mat-header-cell">
            <b>{{ "Machine Fail Hour" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.machine_fail_hour }}
          </td>
          <th class="mat-header-cell">
            <b>{{ "Machine Fail Minutes" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.machine_fail_minutes }}
          </td>
          <th class="mat-header-cell">
            <b>{{ "Fuel Machine Fail" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.fuel_machine_fail }}
          </td>
        </tr>
        <tr class="mat-row mat-header-row">
          <th class="mat-header-cell">
            <b>{{ "Refuel Hour" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.refuel_hour }}
          </td>
          <th class="mat-header-cell">
            <b>{{ "Refuel Minutes" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.refuel_minutes }}
          </td>
          <th class="mat-header-cell">
            <b>{{ "Refuel Ltr" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.refuel_ltr }}
          </td>
        </tr>
        <tr class="mat-row mat-header-row">
          <th class="mat-header-cell">
            <b>{{ "Move Barge Hour" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.move_barge_hour }}
          </td>
          <th class="mat-header-cell">
            <b>{{ "Move Barge Minutes" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.move_barge_minutes }}
          </td>
          <th class="mat-header-cell">
            <b>{{ "Move Barge Fuel" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.move_barge_fuel }}
          </td>
        </tr>
        <tr class="mat-row mat-header-row">
          <th class="mat-header-cell">
            <b>{{ "Stop Rain Hour" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.stop_rain_hour }}
          </td>
          <th class="mat-header-cell">
            <b>{{ "Stop Rain Minutes" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.stop_rain_minutes }}
          </td>
          <th class="mat-header-cell">
            <b>{{ "Stop Rain Fuel" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.stop_rain_fuel }}
          </td>
        </tr>
        <tr class="mat-row mat-header-row">
          <th class="mat-header-cell">
            <b>{{ "Wait Pontoon Hour" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.wait_pontoon_hour }}
          </td>
          <th class="mat-header-cell">
            <b>{{ "Wait Pontoon Minutes" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.wait_pontoon_minutes }}
          </td>
          <th class="mat-header-cell">
            <b>{{ "Wait Pontoon Ltr" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.wait_pontoon_ltr }}
          </td>
        </tr>
        <tr class="mat-row mat-header-row">
          <th class="mat-header-cell">
            <b>{{ "Other Hour" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.other_hour }}
          </td>
          <th class="mat-header-cell">
            <b>{{ "Other Minutes" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.other_minutes }}
          </td>
          <th class="mat-header-cell">
            <b>{{ "Other Ltr" | ioxTranslate }}:</b>
          </th>
          <td class="mat-cell">
            {{ item.other_ltr }}
          </td>
        </tr>
      </table>
    </mat-card>
  </div>
</div>
