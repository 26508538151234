<breadcrumb></breadcrumb>
<h4>
  <strong>{{ "Add Daily Job (Floating Barge)" | ioxTranslate }}</strong>
</h4>
<div class="dt-mt-row" fxLayout="row wrap">
  <mat-card
    class="mat-elevation-z8"
    [fxFlex.gt-sm]="100"
    [fxFlex.gt-xs]="100"
    fxFlex="100"
  >
    <form [formGroup]="form">
      <formly-form
        [form]="form"
        [fields]="fields"
        [model]="model"
      ></formly-form>
      <!-- <button type="submit" class="btn btn-primary" [disabled]="!form.valid">
        Submit
      </button> -->
    </form>
    <mat-dialog-actions>
      <button mat-button routerLink="/master-data/daily-jobs">
        {{ "Cancel" | ioxTranslate }}
      </button>
      <button
        [disabled]="!form.valid"
        mat-raised-button
        color="primary"
        type="submit"
        (click)="onSubmit()"
      >
        {{ "Add Daily Job" | ioxTranslate }}
      </button>
    </mat-dialog-actions>
  </mat-card>
</div>
