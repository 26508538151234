import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, interval, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { SensorService } from 'src/app/services/api/sensor.service';
import { WebsocketService } from 'src/app/services/api/websocket.service';
import { SensorValueService } from 'src/app/services/api/sensor-value.service';
import { EnvService } from 'src/app/services/core/env.service';
import { Constants } from 'src/app/shared/utils/constants';
import { AxUtils } from 'src/app/shared/utils/axUtils';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Event } from 'src/app/models/api/event';
import { MatDialog } from '@angular/material/dialog';
import { GenericDetailViewerComponent } from 'src/app/shared/components/generic-detail-viewer/generic-detail-viewer.component';
@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
  public options = {};
  public sensorClazz = '';
  public sensorName = '';
  public sensorId = null;
  public initDate = moment().toDate();
  public selectedDate = null;
  public today = '';
  public haveData = false;
  public devices = [];
  public ledClass = 'led-gray';
  public minDiff = '0';
  public secDiff = '0';
  private subject: Subject<MessageEvent>;
  private timerFunc: Subscription;
  private wsRef: Subscription;
  public eventNr = 0;
  public lastUpdate = moment();
  public lastUpdateInfo = '';
  public searchTextField = '';
  public savedRows = []; // for searching

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  selection = new SelectionModel<any>(false, []);
  public displayedColumns = ['id', 'status', 'name', 'measuredAt'];
  public dataSource = new MatTableDataSource<Event>();

  @ViewChild('table') table: MatTable<any>;
  eventData: any;

  constructor(
    private service: SensorService,
    private dialog: MatDialog,
    private websocketService: WebsocketService,
    private backendUrlService: EnvService
  ) {}

  ngOnDestroy() {
    this.timerFunc.unsubscribe();
    this.wsRef.unsubscribe();
  }

  ngOnInit() {
    const record: Event = {
      status: 'OK',
      id: -1,
      name: 'BASE_LINE',
      measuredAt: moment()
        .format(Constants.DATE_TIME_FMT_ISO_MOMENT)
        .replace('T', ' '),
      eventData: JSON.stringify('waiting for data'),
    };
    this.dataSource.data.push(record);
    this.dataSource.sort = this.sort;
    this.savedRows = [...this.dataSource.data];
    this.haveData = true;
    const source = interval(10000);
    this.timerFunc = source.subscribe((val) => this.calcTimeDiff());
    this.service.getSensors().subscribe(
      (response) => {
        this.devices = response.sensors.filter((x) => x.name !== 'Unused');
        this.reconnect();
      },
      (err: HttpErrorResponse) => AxUtils.handleServiceError(err)
    );
  }

  reconnect() {
    console.warn('(re)connecting');
    this.subject = this.websocketService.connect(
      this.backendUrlService.websocketUrl
    );
    this.wsRef = this.subject.subscribe(
      (message) => this.handleMessage(message),
      (err) => console.error(err),
      () => this.reconnect()
    );
  }

  calcTimeDiff(): any {
    const now = moment();
    if (now.diff(this.lastUpdate, 'minutes') > 1) {
      this.ledClass = 'led-warning';
    }
  }

  getSensorName(id: string): string {
    let sensorName = '';
    const sensor = this.devices.filter((x) => {
      if (x.id == id) {
        sensorName = x.name;
      }
    });
    return sensorName;
  }

  search() {
    const val = this.searchTextField.toLowerCase();
    if (val.length === 0) {
      this.clearSearch();
      return;
    }
    const colsAmt = this.displayedColumns.length;
    // get the key names of each column in the dataset
    const keys = Object.keys(this.savedRows[0]);
    // assign filtered matches to the active datatable
    this.dataSource.data = this.savedRows.filter((item) => {
      // iterate through each row's column data
      for (let i = 0; i < colsAmt; i++) {
        // check for a match
        if (
          item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 ||
          !val
        ) {
          // found match, return true to add to result set
          return true;
        }
      }
    });
    // whenever the filter changes, always go back to the first page
  }

  clearSearch() {
    this.searchTextField = '';
    this.dataSource.data = [...this.savedRows];
  }

  handleMessage(event: MessageEvent) {
    const sensorValue = JSON.parse(event.data);
    sensorValue.name = sensorValue.name
      ? sensorValue.name
      : this.getSensorName(sensorValue.sensorId);
    const name = sensorValue.name;
    const measuredAt = sensorValue.measuredAt.replace('T', ' ');
    const sensorId = sensorValue.sensorId;
    const record = {
      id: sensorId,
      status: 'OK',
      name,
      measuredAt,
      eventData: JSON.stringify(sensorValue),
    };
    this.eventNr++;
    this.ledClass = 'led-green-even';
    if (this.eventNr % 2) {
      this.ledClass = 'led-green-odd';
    }
    this.savedRows = [record, ...this.savedRows];
    this.lastUpdate = moment();
    this.lastUpdateInfo = `Last Update: ${this.lastUpdate.format('HH:mm:ss')}`;
    this.search();
  }

  onSelect(event) {
    const temp = JSON.parse(event.eventData);
    const data = this.buildSensorDataTable(temp);
    this.dialog.open(GenericDetailViewerComponent, {
      width: '900px',
      data: { data: data, jsonData: JSON.stringify(temp, null, 2) },
    }); // end of dialog
  }

  private buildSensorDataTable(response: any): any[] {
    const data = [];
    for (var key in response) {
      // check if the property/key is defined in the object itself, not in parent
      if (response.hasOwnProperty(key)) {
        if (key == 'sensorValueEntries') {
          response[key].forEach((entry) => {
            const rec = {
              field: entry['propName'],
              fValue: entry['propValue'],
            };
            data.push(rec);
          });
        } else {
          const rec = { field: key, fValue: response[key] };
          data.push(rec);
        }
      } // end if is valid property
    } // end for
    return data;
  } // end method
}
