<div class="form-wrapper">
  <form [formGroup]="form" (ngSubmit)="onSubmit(activeEntity)">
    <formly-form
      *ngIf="!saveInForm"
      [form]="form"
      [fields]="config.fields"
      [model]="activeEntity"
      (modelChange)="onSubmit(activeEntity)"
    >
    </formly-form>
    <formly-form
      *ngIf="saveInForm"
      [form]="form"
      [fields]="config.fields"
      [model]="activeEntity"
    >
    </formly-form>
    <button mat-raised-button color="primary" type="submit">
      <span *ngIf="isNewEntity && saveInForm">{{ "Add" | ioxTranslate }}</span>
      <span *ngIf="!isNewEntity && saveInForm">{{
        "Update" | ioxTranslate
      }}</span>
    </button>
  </form>
</div>
