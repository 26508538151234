<mat-toolbar class="dt-mt-toolbar">
  <app-branding *ngIf="showBranding" [isDark]="isDark"></app-branding>
  <span fxFlex></span>

  <span class="example-spacer"></span>
  <app-live-clock></app-live-clock>
  <button
    mat-icon-button
    matTooltip="{{ 'Toggle Side Bar' | ioxTranslate }} "
    class="dt-mt-toolbar-button"
    fxHide.lt-sm
    (click)="toggleSideBar()"
  >
    <mat-icon>view_compact</mat-icon>
  </button>

  <!-- 👇 below button will help us change the theme at run-time. -->
  <button
    mat-icon-button
    matTooltip="{{ 'Toggle dark theme' | ioxTranslate }}"
    (click)="toggleTheme()"
  >
    <mat-icon> brightness_6 </mat-icon>
  </button>

  <button
    mat-icon-button
    class="dt-mt-toolbar-button"
    matTooltip="{{ 'Full Screen' | ioxTranslate }}"
    fxHide.lt-sm
    (click)="toggleFullscreen()"
  >
    <mat-icon>fullscreen</mat-icon>
  </button>

  <button
    class="dt-mt-toolbar-button dt-mt-avatar-button"
    mat-button
    [matMenuTriggerFor]="menu"
  >
    <mat-icon>person</mat-icon>
    <span class="dt-mt-username" fxHide.lt-sm>{{ username | titlecase }}</span>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="changePassword()">
      <mat-icon>edit</mat-icon>
      <span>{{ "Change Password" | ioxTranslate }}</span>
    </button>
  </mat-menu>

  <button
    mat-icon-button
    class="dt-mt-toolbar-button"
    matTooltip="{{ 'Logout' | ioxTranslate }}"
    fxHide.lt-sm
    (click)="logout()"
  >
    <mat-icon>logout</mat-icon>
  </button>
</mat-toolbar>
