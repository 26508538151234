import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'tgLocalTime' })
export class TgLocalTimePipe implements PipeTransform {
  transform(dateorstring: string | Date): string {
    return moment(dateorstring)
      .add(7, 'hours')
      .format('dddd, MMMM Do, | HH:mm');
  }
}
