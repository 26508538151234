import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { IoxAdminService } from 'src/app/services/api/iox-admin.service';
import { forkJoin } from 'rxjs';
import { DailyJobs } from 'src/app/models/api/iox-admin/daily-jobs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IoxTranslatePipe } from 'src/app/shared/pipes/iox-translate.pipe';

@Component({
  selector: 'app-daily-jobs-add',
  templateUrl: './daily-jobs-add.component.html',
  styleUrls: ['./daily-jobs-add.component.scss'],
})
export class DailyJobsAddComponent implements OnInit {
  validFloatValidator() {
    return {
      expression: (c) => {
        if (c.value === null || c.value === undefined) {
          return true; // Allow empty values
        }
        return !isNaN(parseFloat(c.value)) && isFinite(c.value);
      },
      message: this.translatePipe.transform('Invalid Number value'),
    };
  }
  form = new FormGroup({});
  model: DailyJobs = new DailyJobs();
  floating_barges = [];
  products = [];
  fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-md-2',
          key: 'ship_name',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Ship Name'),
            placeholder: this.translatePipe.transform('Ship Name'),
            required: true,
          },
        },
        {
          className: 'col-md-2',
          key: 'barge_name',
          type: 'select',
          templateOptions: {
            label: this.translatePipe.transform('Barge Name'),
            placeholder: this.translatePipe.transform('Barge Name'),
            options: [],
            labelProp: 'name',
            valueProp: 'id',
            required: true,
          },
        },
        {
          className: 'col-md-2',
          key: 'product_type',
          type: 'select',
          templateOptions: {
            label: this.translatePipe.transform('Product Name (Thai)'),
            placeholder: this.translatePipe.transform('Product Name (Thai)'),
            labelProp: (option) => `${option.name_th}`,
            valueProp: 'id',
            change: (field, value) => this.getProductAssetRate(field, value),
            required: true,
          },
        },
        {
          key: 'job_start',
          type: 'datetimepicker',
          templateOptions: {
            label: this.translatePipe.transform('Job Start'),
            placeholder: this.translatePipe.transform('Job Start'),
            required: true,
            // type: 'datetime-local',
          },
        },
        {
          key: 'job_end',
          type: 'datetimepicker',
          templateOptions: {
            label: this.translatePipe.transform('Job End'),
            placeholder: this.translatePipe.transform('Job End'),
            required: true,
            // type: 'datetime-local',
          },
          validators: {
            jobEndAfterStart: {
              expression: (c) => {
                const jobStart = this.form.get('job_start').value;
                const jobEnd = c.value;
                // Compare job_end and job_start here
                return (
                  jobStart && jobEnd && new Date(jobEnd) > new Date(jobStart)
                );
              },
              message: this.translatePipe.transform(
                'Job End must be after Job Start'
              ),
            },
          },
        },
        {
          className: 'col-md-2',
          key: 'weight_ton',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Weight Tons'),
            placeholder: this.translatePipe.transform('Weight Tons'),
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
        {
          className: 'col-md-2',
          key: 'rate_product_ton_hour',
          type: 'select',
          templateOptions: {
            label: this.translatePipe.transform('Rate (ton/hour)'),
            placeholder: this.translatePipe.transform('Rate (ton/hour)'),
            labelProp: (option) => `${option.name}`,
            valueProp: 'value',
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
        {
          className: 'col-md-2',
          key: 'rate_ltr_ton',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Rate (L/ton)'),
            placeholder: this.translatePipe.transform('Rate (L/ton)'),
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
        {
          className: 'col-md-2',
          key: 'fuel_used',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Fuel Used (L)'),
            placeholder: this.translatePipe.transform('Fuel Used (L)'),
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
        {
          className: 'col-md-2',
          key: 'fuel_actual_use',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Fuel Actual Use (L)'),
            placeholder: this.translatePipe.transform('Fuel Actual Use (L)'),
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },

        {
          className: 'col-md-2',
          key: 'fuel_stop_time',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Fuel Stop Time (L)'),
            placeholder: this.translatePipe.transform('Fuel Stop Time (L)'),
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
        {
          className: 'col-md-2',
          key: 'fuel_heave_machine',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Fuel Heavy Machine (L)'),
            placeholder: this.translatePipe.transform('Fuel Heavy Machine (L)'),
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
      ],
    },
    {
      key: 'เหตุผลหยุดงาน',
      template: '<h4>เหตุผลหยุดงาน</h4>',
    },
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-md-2',
          key: 'lift_up_down_hour',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Lift Up Down (hour)'),
            type: 'number',
            placeholder: '0',
            required: false,
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
        {
          className: 'col-md-2',
          key: 'lift_up_down_minutes',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Lift Up Down (minutes)'),
            type: 'number',
            placeholder: '0',
            required: false,
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
        {
          className: 'col-md-3',
          key: 'lift_up_down_fuel',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Lift Up Down Fuel (L)'),
            type: 'number',
            placeholder: '0',
            required: false,
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },

        // Add other fields with their respective units here
      ],
    },
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-md-2',
          key: 'machine_fail_hour',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Machine Fail (hour)'),
            type: 'number',
            placeholder: '0',
            required: false,
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
        {
          className: 'col-md-2',
          key: 'machine_fail_minutes',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Machine Fail (minutes)'),
            type: 'number',
            placeholder: '0',
            required: false,
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
        {
          className: 'col-md-3',
          key: 'fuel_machine_fail',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Fuel Machine Fail (L)'),
            type: 'number',
            placeholder: '0',
            required: false,
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-md-2',
          key: 'refuel_hour',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Refuel (hour)'),
            type: 'number',
            placeholder: '0',
            required: false,
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
        {
          className: 'col-md-2',
          key: 'refuel_minutes',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Refuel (minutes)'),
            type: 'number',
            placeholder: '0',
            required: false,
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
        {
          className: 'col-md-3',
          key: 'refuel_ltr',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Refuel (L)'),
            type: 'number',
            placeholder: '0',
            required: false,
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-md-2',
          key: 'move_barge_hour',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Move Barge (hour)'),
            type: 'number',
            placeholder: '0',
            required: false,
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
        {
          className: 'col-md-2',
          key: 'move_barge_minutes',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Move Barge (minutes)'),
            type: 'number',
            placeholder: '0',
            required: false,
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
        {
          className: 'col-md-3',
          key: 'move_barge_fuel',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Move Barge Fuel(L)'),
            type: 'number',
            placeholder: '0',
            required: false,
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-md-2',
          key: 'stop_rain_hour',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Stop Rain (hour)'),
            type: 'number',
            placeholder: '0',
            required: false,
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
        {
          className: 'col-md-2',
          key: 'stop_rain_minutes',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Stop Rain (minutes)'),
            type: 'number',
            placeholder: '0',
            required: false,
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
        {
          className: 'col-md-3',
          key: 'stop_rain_fuel',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Stop Rain (L)'),
            type: 'number',
            placeholder: '0',
            required: false,
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
        // Add other fields with their respective units here
      ],
    },
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-md-2',
          key: 'wait_pontoon_hour',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Wait Pontoon (hour)'),
            type: 'number',
            placeholder: '0',
            required: false,
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
        {
          className: 'col-md-2',
          key: 'wait_pontoon_minutes',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Wait Pontoon (minutes)'),
            type: 'number',
            placeholder: '0',
            required: false,
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
        {
          className: 'col-md-3',
          key: 'wait_pontoon_ltr',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Wait Pontoon (L)'),
            type: 'number',
            placeholder: '0',
            required: false,
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-md-2',
          key: 'other_hour',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Other (hour)'),
            type: 'number',
            placeholder: '0',
            required: false,
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
        {
          className: 'col-md-2',
          key: 'other_minutes',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Other (minutes)'),
            type: 'number',
            placeholder: '0',
            required: false,
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
        {
          className: 'col-md-3',
          key: 'other_ltr',
          type: 'input',
          templateOptions: {
            label: this.translatePipe.transform('Other (L)'),
            type: 'number',
            placeholder: '0',
            required: false,
          },
          validators: {
            validFloat: this.validFloatValidator(),
          },
        },
      ],
    },
  ];
  constructor(
    private adminService: IoxAdminService,
    private snackBar: MatSnackBar,
    private route: Router,
    private translatePipe: IoxTranslatePipe
  ) {}

  ngOnInit(): void {
    this.fetchOptions();
  }
  fetchOptions() {
    forkJoin({
      assetOptions: this.adminService.getAssets(),
      productOptions: this.adminService.getProducts(),
    }).subscribe(({ assetOptions, productOptions }) => {
      const fieldGroup = this.fields.find((field) =>
        field.hasOwnProperty('fieldGroup')
      );
      if (fieldGroup) {
        const bargeNameField = fieldGroup.fieldGroup.find(
          (field) => field.key === 'barge_name'
        );
        const productTypeField = fieldGroup.fieldGroup.find(
          (field) => field.key === 'product_type'
        );

        if (bargeNameField) {
          bargeNameField.templateOptions.options = assetOptions;
        }

        if (productTypeField) {
          productTypeField.templateOptions.options = productOptions;
        }
      }
    });
  }

  getProductAssetRate(field, value) {
    if (this.model.barge_name) {
      this.adminService
        .getAssetProductRate(this.model.barge_name, this.model.product_type)
        .subscribe((res) => {
          if (res) {
            this.form.patchValue({ rate_ltr_ton: res['value'] });
          }
        });
    }

    this.adminService.getProductsById(value.value).subscribe((res) => {
      if (res) {
        const excludedKeys: string[] = [
          'description',
          'createdAt',
          'name_th',
          'name',
          'id',
        ];

        const listOfObjects = Object.entries(res)
          .filter(([key]) => !excludedKeys.includes(key))
          .map(([key, value]) => ({ name: key, value }));

        const fieldGroup = this.fields.find((field) =>
          field.hasOwnProperty('fieldGroup')
        );
        if (fieldGroup) {
          const rate_product_ton_hour = fieldGroup.fieldGroup.find(
            (field) => field.key === 'rate_product_ton_hour'
          );
          if (rate_product_ton_hour) {
            rate_product_ton_hour.templateOptions.options = listOfObjects;
          }
        }
      }
    });
  }

  onSubmit() {
    if (this.form.valid) {
      if (new Date(this.model['job_end']) > new Date(this.model['job_start'])) {
        this.adminService.insertDailyJobs(this.model).subscribe(
          (response) => {
            this.navigateToBarges();
            this.showSnackbar(
              this.translatePipe.transform('Add Daily Jobs Successfully')
            );
          },
          (err) => {
            this.showSnackbar(
              `Add Daily Job Failed due to Error Code: ${err.message}`
            );
          }
        );
      } else {
        this.showSnackbar(`Invalid Job Start-End Date Time`);
      }
    }
  }

  private navigateToBarges() {
    this.route.navigate(['/master-data/daily-jobs']);
  }

  private showSnackbar(message: string) {
    this.snackBar.open(message, 'Dismiss');
  }
}
