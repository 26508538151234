/**
 * Interface for all DTO classes
 */
export class DtoClass {
  public id: number;
  public name: string;
  public clazz: string;
  public savedAt: Date;

  constructor() {}

  public get_id(): number {
    return this.id;
  }

  public set_id(value: number) {
    this.id = value;
  }

  public get_clazz(): string {
    return this.clazz;
  }

  public set_clazz(clazz: string) {
    this.clazz = clazz;
  }

  public get_name(): string {
    return this.name;
  }

  public set_name(name: string) {
    this.name = name;
  }
}
