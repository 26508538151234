import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { HeaderComponent } from './header/header.component';
import { TopmenuComponent } from './topmenu/topmenu.component';
import { MaterialModule } from '../material/material.module';
import { BrandingComponent } from './header/widgets/branding/branding.component';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TopmenuPanelComponent } from './topmenu/topmenu-panel.component';
import { HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
export function TranslateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { SharedModule } from '../shared/shared.module';
import { LoaderComponent } from './loader/loader.component';
import { SidebarNoticeComponent } from './sidebar-notice/sidebar-notice.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UserPanelComponent } from './sidebar/user-panel.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { AccordionDirective } from './sidemenu/accordion.directive';
import { AccordionItemDirective } from './sidemenu/accordionItem.directive';
import { AccordionAnchorDirective } from './sidemenu/accordionanchor.directive';
import { LiveClockComponent } from './header/widgets/live-clock/live-clock.component';

@NgModule({
  declarations: [
    AdminLayoutComponent,
    HeaderComponent,
    TopmenuComponent,
    BrandingComponent,
    TopmenuPanelComponent,
    AuthLayoutComponent,
    LoaderComponent,
    SidebarNoticeComponent,
    SidebarComponent,
    UserPanelComponent,
    SidemenuComponent,
    AccordionDirective,
    AccordionAnchorDirective,
    AccordionItemDirective,
    LiveClockComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
})
export class ThemeModule {}
