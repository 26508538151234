import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IoxAdminService } from 'src/app/services/api/iox-admin.service';
import { CruditorFormConfiguration } from 'src/app/shared/components/cruditor-form/cruditor-form.configuration';
import { AxUtils } from 'src/app/shared/utils/axUtils';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DailyJobs } from 'src/app/models/api/iox-admin/daily-jobs';
import { takeUntil } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { IoxTranslatePipe } from 'src/app/shared/pipes/iox-translate.pipe';
@Component({
  selector: 'app-daily-jobs-edit',
  templateUrl: './daily-jobs-edit.component.html',
  styleUrls: ['./daily-jobs-edit.component.scss'],
})
export class DailyJobsEditComponent implements OnInit, OnDestroy {
  validFloatValidator() {
    return {
      expression: (c) => {
        if (c.value === null || c.value === undefined) {
          return true; // Allow empty values
        }
        return !isNaN(parseFloat(c.value)) && isFinite(c.value);
      },
      message: this.translatePipe.transform('Invalid Number value'),
    };
  }
  form = new FormGroup({});
  products = [];
  barges = [];
  public basicForm = new FormGroup({});
  public saveInForm = true;
  public isNewEntity = true;
  public item: DailyJobs;
  public config: CruditorFormConfiguration = {
    detailsTitle: 'Daily Job',
    insertMessage: 'Daily Job Successfully Added',
    updateMessage: 'Daily Job Successfully Updated',
    deleteMessage: 'Daily Job Successfully Deleted',
    fields: [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-md-2',
            key: 'ship_name',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Ship Name'),
              placeholder: this.translatePipe.transform('Ship Name'),
              required: true,
            },
          },
          {
            className: 'col-md-2',
            key: 'barge_name',
            type: 'select',
            templateOptions: {
              label: this.translatePipe.transform('Barge Name'),
              placeholder: this.translatePipe.transform('Barge Name'),
              labelProp: 'name',
              valueProp: 'id',
              required: true,
            },
            expressionProperties: {
              'templateOptions.options': () => this.barges,
            },
          },
          {
            className: 'col-md-2',
            key: 'product_type',
            type: 'select',
            templateOptions: {
              label: this.translatePipe.transform('Product Name (Thai)'),
              placeholder: this.translatePipe.transform('Product Name (Thai)'),
              labelProp: (option) => `${option.name_th}`,
              valueProp: 'id',
              change: (field, value) => this.getProductAssetRate(field, value),
              required: true,
            },
            expressionProperties: {
              'templateOptions.options': () => this.products,
            },
          },
          {
            key: 'job_start',
            type: 'datetimepicker',
            templateOptions: {
              label: this.translatePipe.transform('Job Start'),
              placeholder: this.translatePipe.transform('Job Start'),
              // type: 'datetime-local',
              required: true,
            },
          },
          {
            key: 'job_end',
            type: 'datetimepicker',
            templateOptions: {
              label: this.translatePipe.transform('Job End'),
              placeholder: this.translatePipe.transform('Job End'),
              // type: 'datetime-local',
              required: true,
            },
            validators: {
              jobEndAfterStart: {
                expression: (c) => {
                  const jobStart = this.item['job_start'];
                  const jobEnd = c.value;
                  // Compare job_end and job_start here
                  return (
                    jobStart && jobEnd && new Date(jobEnd) > new Date(jobStart)
                  );
                },
                message: this.translatePipe.transform(
                  'Job End must be after Job Start'
                ),
              },
            },
          },
          {
            className: 'col-md-2',
            key: 'weight_ton',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Weight Tons'),
              placeholder: this.translatePipe.transform('Weight Tons'),
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-md-2',
            key: 'rate_product_ton_hour',
            type: 'select',
            templateOptions: {
              label: this.translatePipe.transform('Rate (ton/hour)'),
              placeholder: this.translatePipe.transform('Rate (ton/hour)'),
              labelProp: (option) => `${option.name}`,
              valueProp: 'value',
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-md-2',
            key: 'rate_ltr_ton',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Rate (L/ton)'),
              placeholder: this.translatePipe.transform('กรอก Rate (ลิตร/ตัน)'),
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-md-2',
            key: 'fuel_used',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Fuel Used (L)'),
              placeholder: this.translatePipe.transform('Fuel Used (L)'),
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-md-2',
            key: 'fuel_actual_use',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Fuel Actual Use (L)'),
              placeholder: this.translatePipe.transform('Fuel Actual Use (L)'),
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-md-2',
            key: 'fuel_stop_time',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Fuel Stop Time (L)'),
              placeholder: this.translatePipe.transform('Fuel Stop Time (L)'),
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-md-2',
            key: 'fuel_heave_machine',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Fuel Heavy Machine (L)'),
              placeholder: this.translatePipe.transform(
                'Fuel Heavy Machine (L)'
              ),
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
        ],
      },
      {
        key: 'เหตุผลหยุดงาน',
        template: '<h4>เหตุผลหยุดงาน</h4>',
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-md-2',
            key: 'lift_up_down_hour',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Lift Up Down (hour)'),
              type: 'number',
              placeholder: '0',
              required: false,
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-md-2',
            key: 'lift_up_down_minutes',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Lift Up Down (minutes)'),
              type: 'number',
              placeholder: '0',
              required: false,
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-md-3',
            key: 'lift_up_down_fuel',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Lift Up Down Fuel (L)'),
              type: 'number',
              placeholder: '0',
              required: false,
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          // Add other fields with their respective units here
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-md-2',
            key: 'machine_fail_hour',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Machine Fail (hour)'),
              type: 'number',
              placeholder: '0',
              required: false,
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-md-2',
            key: 'machine_fail_minutes',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Machine Fail (minutes)'),
              type: 'number',
              placeholder: '0',
              required: false,
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-md-3',
            key: 'fuel_machine_fail',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Fuel Machine Fail (L)'),
              type: 'number',
              placeholder: '0',
              required: false,
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-md-2',
            key: 'refuel_hour',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Refuel (hour)'),
              type: 'number',
              placeholder: '0',
              required: false,
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-md-2',
            key: 'refuel_minutes',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Refuel (minutes)'),
              type: 'number',
              placeholder: '0',
              required: false,
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-md-3',
            key: 'refuel_ltr',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Refuel (L)'),
              type: 'number',
              placeholder: '0',
              required: false,
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          // Add other fields with their respective units here
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-md-2',
            key: 'move_barge_hour',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Move Barge (hour)'),
              type: 'number',
              placeholder: '0',
              required: false,
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-md-2',
            key: 'move_barge_minutes',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Move Barge (minutes)'),
              type: 'number',
              placeholder: '0',
              required: false,
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-md-3',
            key: 'move_barge_fuel',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Move Barge Fuel(L)'),
              type: 'number',
              placeholder: '0',
              required: false,
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-md-2',
            key: 'stop_rain_hour',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Stop Rain (hour)'),
              type: 'number',
              placeholder: '0',
              required: false,
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-md-2',
            key: 'stop_rain_minutes',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Stop Rain (minutes)'),
              type: 'number',
              placeholder: '0',
              required: false,
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-md-3',
            key: 'stop_rain_fuel',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Stop Rain (L)'),
              type: 'number',
              placeholder: '0',
              required: false,
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          // Add other fields with their respective units here
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-md-2',
            key: 'wait_pontoon_hour',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Wait Pontoon (hour)'),
              type: 'number',
              placeholder: '0',
              required: false,
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-md-2',
            key: 'wait_pontoon_minutes',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Wait Pontoon (minutes)'),
              type: 'number',
              placeholder: '0',
              required: false,
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-md-3',
            key: 'wait_pontoon_ltr',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Wait Pontoon (L)'),
              type: 'number',
              placeholder: '0',
              required: false,
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-md-2',
            key: 'other_hour',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Other (hour)'),
              type: 'number',
              placeholder: '0',
              required: false,
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-md-2',
            key: 'other_minutes',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Other (minutes)'),
              type: 'number',
              placeholder: '0',
              required: false,
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
          {
            className: 'col-md-3',
            key: 'other_ltr',
            type: 'input',
            templateOptions: {
              label: this.translatePipe.transform('Other (L)'),
              type: 'number',
              placeholder: '0',
              required: false,
            },
            validators: {
              validFloat: this.validFloatValidator(),
            },
          },
        ],
      },
    ],
  };
  id: Number;
  private destroy$ = new Subject<void>();
  constructor(
    private service: IoxAdminService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private route_path: Router,
    private translatePipe: IoxTranslatePipe
  ) {}

  ngOnInit(): void {
    this.basicForm = this.formBuilder.group({});
    forkJoin({
      products: this.service.getProducts(),
      floatingBarges: this.service.getAssets(),
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.products = response.products;
          this.barges = response.floatingBarges;
        },
        (err: HttpErrorResponse) => AxUtils.handleServiceError(err)
      );
    this.id = Number(this.route.snapshot.paramMap.get('id'));

    this.getItemDetails();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getItemDetails() {
    this.service.getDailyJobsById(this.id).subscribe(
      (response) => {
        this.item = response;
        this.getRateTonHour();
      },
      (err: HttpErrorResponse) => AxUtils.handleServiceError(err)
    );
  }

  getProductAssetRate(field, value) {
    if (this.item.barge_name) {
      this.service
        .getAssetProductRate(this.item.barge_name, this.item.product_type)
        .subscribe((res) => {
          if (res) {
            this.form.patchValue({ rate_ltr_ton: res['value'] });
          }
        });
    }

    this.service.getProductsById(value.value).subscribe((res) => {
      if (res) {
        const excludedKeys: string[] = [
          'description',
          'createdAt',
          'name_th',
          'name',
          'id',
        ];

        const listOfObjects = Object.entries(res)
          .filter(([key]) => !excludedKeys.includes(key))
          .map(([key, value]) => ({ name: key, value }));

        const fieldGroup = this.config.fields.find((field) =>
          field.hasOwnProperty('fieldGroup')
        );
        if (fieldGroup) {
          const rate_product_ton_hour = fieldGroup.fieldGroup.find(
            (field) => field.key === 'rate_product_ton_hour'
          );
          if (rate_product_ton_hour) {
            rate_product_ton_hour.templateOptions.options = listOfObjects;
          }
        }
      }
    });
  }

  getRateTonHour() {
    this.service.getProductsById(this.item.product_type).subscribe((res) => {
      if (res) {
        const excludedKeys: string[] = [
          'description',
          'createdAt',
          'name_th',
          'name',
          'id',
        ];

        const listOfObjects = Object.entries(res)
          .filter(([key]) => !excludedKeys.includes(key))
          .map(([key, value]) => ({ name: key, value }));

        const fieldGroup = this.config.fields.find((field) =>
          field.hasOwnProperty('fieldGroup')
        );
        if (fieldGroup) {
          const rate_product_ton_hour = fieldGroup.fieldGroup.find(
            (field) => field.key === 'rate_product_ton_hour'
          );
          if (rate_product_ton_hour) {
            rate_product_ton_hour.templateOptions.options = listOfObjects;
          }
        }
      }
    });
  }

  async save() {
    if (this.basicForm.valid) {
      if (new Date(this.item['job_end']) > new Date(this.item['job_start'])) {
        try {
          delete this.item.asset;
          delete this.item.product;
          const newEntity = await this.service
            .editDailyJob(this.item)
            .toPromise();
          this.navigateToDetails();
          this.showSnackbar(this.config.updateMessage);
        } catch (error) {
          const { code, message } = error.error;
          this.showSnackbar(
            `Add Daily Job Failed due to Error Code: ${code} ${message}`
          );
        }
      } else {
        this.showSnackbar(`Invalid Job Start-End Date Time`);
      }
    }
  }

  navigateToDetails() {
    this.route_path.navigate([`/master-data/daily-jobs/details/${this.id}`]);
  }

  private showSnackbar(message: string) {
    this.snackBar.open(message, 'Dismiss');
  }
}
