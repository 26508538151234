<div class="json-class">
  <ngx-codemirror
    *ngIf="view == 'json' && jsonData"
    [(ngModel)]="jsonData"
    #jsonData1
    [autoFocus]="true"
    [options]="options"
  >
    {{ jsonData }}</ngx-codemirror
  >
</div>
<table *ngIf="view == 'table'" mat-table matSort [dataSource]="dataSource">
  <ng-container matColumnDef="field">
    <th
      class="header type-column"
      mat-header-cell
      mat-sort-header
      *matHeaderCellDef
    >
      <span
        ><b>{{ "FIELD" | ioxTranslate }}</b></span
      >
    </th>
    <td mat-cell *matCellDef="let data">
      <b>{{ data.field | titlecase | ioxTranslate }}</b>
    </td>
  </ng-container>
  <ng-container matColumnDef="fValue">
    <th
      class="header name-column"
      mat-header-cell
      mat-sort-header
      *matHeaderCellDef
    >
      <span
        ><b>{{ "VALUE" | ioxTranslate }}</b></span
      >
    </th>
    <td mat-cell *matCellDef="let data">{{ data.fValue }}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
  <tr mat-row *matRowDef="let request; columns: columnsToDisplay"></tr>
</table>
