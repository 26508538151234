import { Component, OnInit, AfterViewInit } from '@angular/core';
import { StartupService } from './services/core/startup.service';
import { PreLoaderService } from './services/themes/preloader.service';
import { LocalStorageService } from './services/core/authentication/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  tenantStatus = false;
  constructor(
    private preloader: PreLoaderService,
    private startup: StartupService,
    private store: LocalStorageService
  ) {}

  ngOnInit() {
    if (this.store.get('tenantStatus')) {
      this.favIcon.href = this.store.get('favicon_url');
    }
    this.startup.load();
  }

  ngAfterViewInit() {
    this.preloader.hide();
  }
}
